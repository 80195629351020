import { SET_ASSEMBLY_STATUS_FILTER_VALUE, SET_LOADING } from 'constants/types';
import api from '../../api';
import { encryptData } from 'utils/decryptEncryptAES';
import { setParticipantAssemblyData } from './participant';

export const getCompanyBrandingData = (data) => (dispatch) => {
  const sendingData = { ...{ data: encryptData(data) }, isParticipantSide: true };
  return api.commonApi
    .getBrandingData(sendingData)
    .then((res) => {
      dispatch(setParticipantAssemblyData(res));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export function setLoading(data) {
  return {
    type: SET_LOADING,
    payload: data
  };
}

export function setAssemblyStatusFilterValue(data) {
  return {
    type: SET_ASSEMBLY_STATUS_FILTER_VALUE,
    payload: data
  };
}
