import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';
import { COLORS } from 'constants/common';

const leftSideBarWidth = {
  full: 400,
  medium: 296,
  small: 80
};

export const GeneralLayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

export const LeftSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => leftSideBarWidth[props.sideBarType]}px;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${(props) => (props.isParticipantView
  ? COLORS.darkGreen
  : COLORS.darkBlue)};
  padding: ${(props) =>
  props.sideBarType === 'small'
    ? '64px 25px 10px 25px'
    : '64px 10px 64px 64px'};
`;

export const MainContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${COLORS.lightGray};
  @media (max-width: 1280px) {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  & .MuiLink-root {
    color: #ffffff;
  }
`;

export const HeadBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SupportBlock = styled.div`
  display: flex;
  flex-direction: column;
  & .MuiTypography-body2 {
    color: #ffffff;
    font-weight: normal;
    margin-top: 10px;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 90px;
  & .MuiTypography-h3 {
    margin-bottom: 30px;
  }
`;

export const LocalizationSelectWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 48px;
`;
export const ChooseLanguageRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  & .MuiButton-text {
    padding: 0 8px;
    font-size: 12px;
  }
  & .MuiTypography-body2,
  .MuiButton-text {
    color: #ffffff;
    font-weight: normal;
  }
  & hr {
    background: #ffffff;
    margin-left: 8px;
    height: 20px;
  }
`;
export const Flag = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => `url(${props.file}) 50% 50% no-repeat`};
  background-size: cover;
  margin-right: 8px;
`;

export const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    width: 216px;
    height: auto;
    margin-left: -24px;
  }
`;
export const StyledMenu = styled(MenuList)`
  &.MuiList-padding {
    padding: 0;
  }
`;
export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    justify-content: space-between;
    padding: 20px 24px;
  }
  &.MuiListItem-root:hover {
    background-color: ${COLORS.lightBlue};
  }
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: ${COLORS.lightGreen};
  }
`;
export const MenuItemValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;
