import {
  SET_ASSEMBLY_PREVIEW_DATA,
  SET_ASSEMBLY_PREVIEW_AGENDA_ITEMS_DATA,
  SET_ASSEMBLY_PREVIEW_BALLOTS_DATA,
  CLEAR_STORAGE
} from 'constants/types';

const initialState = {
  assemblyData: null,
  agendaItemsData: [],
  ballots: {}
};

const assemblyPreviewPageReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_ASSEMBLY_PREVIEW_DATA:
      return {
        ...state,
        assemblyData: payload
      };
    case SET_ASSEMBLY_PREVIEW_AGENDA_ITEMS_DATA:
      return {
        ...state,
        agendaItemsData: [...payload]
      };
    case SET_ASSEMBLY_PREVIEW_BALLOTS_DATA:
      return {
        ...state,
        ballots: { ...state.ballots, ...payload }
      };
    case CLEAR_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default assemblyPreviewPageReducer;
