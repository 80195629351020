import Typography from '@material-ui/core/Typography';
import React from 'react';
import { getDateString } from 'utils/date';
import { AgendaTitleWrapper } from '../styledComponents';

export const AgendaTitle = ({ participantAssemblyData }) => (
  <AgendaTitleWrapper>
    {participantAssemblyData && (
      <Typography variant="body2">
        {getDateString(participantAssemblyData)?.dateStringLocalized}
      </Typography>
    )}
    <Typography variant="h2">{participantAssemblyData?.agendaHeadline}</Typography>
  </AgendaTitleWrapper>
);
