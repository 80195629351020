import api from '../../api';
import { SET_MY_ACCOUNT_DATA, SET_COMPANY_LOGO_URL } from 'constants/types';
import { blobToBase64 } from 'utils/files';
import { downloadSomeFile } from './files';

export const getAccountData = () => (dispatch) => {
  return api.myAccount
    .geMyAccountData()
    .then((res) => {
      if (res.account.logoId) {
        dispatch(downloadSomeFile({ fileId: res.account.logoId })).then((response) => {
          blobToBase64(response).then((imageUrlResponse) => {
            dispatch(setCompanyLogoUrl(imageUrlResponse));
          });
        });
      } else {
        dispatch(setCompanyLogoUrl(null));
      }
      dispatch(setMyAccountData(res.account));
      return res;
    })
    .catch((error) => console.error('REQUEST ERROR', error));
};

export const updateAccountData = (data) => () => {
  return api.myAccount
    .updateMyAccount(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return error;
    });
};

export const onChangeAccountPassword = (data) => () => {
  return api.myAccount
    .changeAccountPassword(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return error;
    });
};

export function setMyAccountData(data) {
  return {
    type: SET_MY_ACCOUNT_DATA,
    payload: data
  };
}

export function setCompanyLogoUrl(data) {
  return {
    type: SET_COMPANY_LOGO_URL,
    payload: data
  };
}
