import Typography from '@material-ui/core/Typography';
import React from 'react';
import { CustomButton } from 'components/Buttons/CustomButton';
import { MyAccountDataItemRow } from 'MyAccountView/styledComponents';
import { FieldItem } from '../styledComponents';
import { MyAccountInputComponent } from './MyAccountInputComponent';

export const MyAccountFieldItem = ({
  changeEditingFields,
  dataName,
  editingFields,
  enableEmptyData,
  fieldsData,
  initialValue,
  onUpdateAccountData,
  placeholder,
  requestError,
  setRequestError,
  t,
  title,
  upperCase
}) => (
  <FieldItem className="details-item">
    <Typography variant="body2" className="input-label">
      {title}
    </Typography>
    {editingFields.includes(dataName) ? (
      <MyAccountInputComponent
        enableEmptyData={enableEmptyData}
        onSaveChanges={onUpdateAccountData}
        name={dataName}
        setRequestError={setRequestError}
        requestError={requestError}
        value={fieldsData[dataName] ? fieldsData[dataName] : initialValue}
        placeholder={placeholder}
        setEditStatus={() => changeEditingFields(dataName)}
        t={t}
        upperCase={upperCase}
      />
    ) : (
      <MyAccountDataItemRow emptyItem={!((fieldsData && fieldsData[dataName]) || initialValue)}>
        <Typography variant="body1">
          {fieldsData && fieldsData[dataName]
            ? fieldsData[dataName]
            : initialValue || t('commonTexts.emptyMyAccountItemValue')}
        </Typography>
        <CustomButton
          fullWidth
          colorType="secondary"
          name={t('buttonsNames.edit')}
          onClick={() => changeEditingFields(dataName)}
          variant="text"
        />
      </MyAccountDataItemRow>
    )}
  </FieldItem>
);
