import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from 'components/Buttons/CustomButton';
import { FormInputLabel } from 'components/FormInputsLabel/FormInputsLabel';
import { PasswordVisibilityIcon } from 'components/PasswordVisibilityIcon/PasswordVisibilityIcon';
import { sha256 } from 'js-sha256';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { loginErrorSelector } from 'Redux/selectors/auth';
import { createStructuredSelector } from 'reselect';
import { passwordStrongValidation } from 'utils/common';
import { onChangeAccountPassword } from 'Redux/actions/myAccount';
import { StrongPasswordGuidelines } from 'components/StrongPassworgGuidelinesComponent/StrongPasswordGuidelines';
import { clientSidePasswordValidation } from '../../../utils/common';
import { InputFieldWrapper, ChangePasswordFormWrapper } from './styledComponents';

const ChangePasswordForm = ({ actionChangeAccountPassword, closePasswordDialog, t }) => {
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [showPasswordFields, setShowPasswordFields] = useState({
    newPassword: false,
    confirmPassword: false
  });
  const [error, setError] = useState(null);
  const [requestError, setRequestError] = useState(false);

  const handleClickShowPassword = (inputType) => {
    setShowPasswordFields((prevState) => ({
      ...showPasswordFields,
      [inputType]: !prevState[inputType]
    }));
  };
  const onPasswordChange = (e) => {
    const { id, value } = e.target;
    if (error) {
      setError(null);
    }
    if (requestError) {
      setRequestError(null);
    }
    setPasswords({
      ...passwords,
      [id]: value.split(' ').join('')
    });
  };

  const setNewPassword = (e) => {
    const { newPassword, confirmPassword } = passwords;
    e.preventDefault();
    if (
      passwordStrongValidation(newPassword)
      && confirmPassword === newPassword
      && newPassword.length < 200
    ) {
      const hash = sha256.create();
      hash.update(newPassword);
      const data = {
        newPassword: hash.hex()
      };
      actionChangeAccountPassword(data).then((response) => {
        if (response?.success) {
          closePasswordDialog();
        } else {
          setRequestError(
            t([`requestsErrors.${response.data.errorCode}`, 'requestsErrors.ANOTHER_ERROR'])
          );
        }
      });
    }
    const passwordValidationError = clientSidePasswordValidation(newPassword, confirmPassword);
    setError(passwordValidationError);
  };

  return (
    <ChangePasswordFormWrapper>
      <Typography paragraph variant="h3" color="textPrimary">
        {t('titles.changeAccountPassword')}
      </Typography>
      <form noValidate autoComplete="off" onSubmit={setNewPassword}>
        <InputFieldWrapper>
          <FormInputLabel id="new-password-label" name={t('labels.newPassword')} />
          <TextField
            variant="outlined"
            required
            name="new-password"
            type={showPasswordFields?.newPassword ? 'text' : 'password'}
            id="newPassword"
            tabIndex="1"
            autoComplete="off"
            placeholder={t('commonConstants.NEW_PASSWORD_PLACEHOLDER')}
            onChange={onPasswordChange}
            value={passwords.newPassword}
            error={error?.newPassword || requestError}
            helperText={error?.newPassword ? error?.newPassword : ''}
            InputProps={{
              endAdornment: (
                <PasswordVisibilityIcon
                  tabIndex="-1"
                  handleClickShowPassword={() => handleClickShowPassword('newPassword')}
                  isShowPassword={showPasswordFields?.newPassword}
                />
              )
            }}
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <FormInputLabel id="confirm-password-label" name={t('labels.confirmPassword')} />
          <TextField
            variant="outlined"
            required
            tabIndex="2"
            name="confirm-password"
            type={showPasswordFields?.confirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            autoComplete="off"
            placeholder={t('commonConstants.CONFIRM_PASSWORD_PLACEHOLDER')}
            onChange={onPasswordChange}
            value={passwords.confirmPassword}
            error={error?.confirmPassword || requestError}
            helperText={error?.confirmPassword ? error?.confirmPassword : ''}
            InputProps={{
              endAdornment: (
                <PasswordVisibilityIcon
                  tabIndex="-1"
                  handleClickShowPassword={() => handleClickShowPassword('confirmPassword')}
                  isShowPassword={showPasswordFields?.confirmPassword}
                />
              )
            }}
          />
        </InputFieldWrapper>
        {requestError && (
          <FormHelperText component="span" error className="error-hint">
            {requestError}
          </FormHelperText>
        )}
        <CustomButton
          fullWidth
          className="submitButton"
          colorType="secondary"
          name={t('buttonsNames.confirm')}
          onClick={setNewPassword}
          type="submit"
          variant="contained"
        />
        <Divider />
        <StrongPasswordGuidelines />
      </form>
    </ChangePasswordFormWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  requestError: loginErrorSelector()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionChangeAccountPassword: onChangeAccountPassword
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ChangePasswordForm);
