import api from '../api';

export const checkTFA = (data) =>
  api.post('/api/Account/RequestTwoFactorData', data).then((res) => res.data);
export const login = (data) => api.post('/api/Account/Login', data).then((res) => res.data);
export const loginParticipant = (data) =>
  api.post('/api/Account/TemporaryLoginForParticipants', data).then((res) => res.data);
export const smsVerification = (data) =>
  api.post('/api/Account/SendSmsCode', data).then((res) => res.data);
export const logout = (data) => api.post('/api/Account/Logout', data).then((res) => res.data);

export const resetPasswordSendResetLink = (data) =>
  api.post('/api/Account/ResetPassword/SendMail', data).then((res) => res.data);

export const updatePassword = (data) =>
  api.post('/api/Account/ResetPassword/Perform', data).then((res) => res.data);
export const checkIsLinkAvailable = (data) =>
  api.post('/api/Account/ResetPassword/CheckResetCode', data).then((res) => res.data);
