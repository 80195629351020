import { createSelector } from 'reselect';

const participantState = (state) => state.participantStore;

const isParticipantVotedSelector = () =>
  createSelector(participantState, (state) => state?.isVoted);

const participantAssemblyDataSelector = () =>
  createSelector(participantState, (state) => state?.participantAssemblyData);

const participantAssemblyAgendaItemsIdsSelector = () =>
  createSelector(participantState, (state) => state?.participantAssemblyData?.agendaItemsIds || []);

const participantAgendaItemsSelector = () =>
  createSelector(participantState, (state) => state?.agendaItemsData);

const participantBallotsSelector = () =>
  createSelector(participantState, (state) => state?.ballots);

const anonymousVotingHasBeenRequestedSelector = () =>
  createSelector(participantState, (state) => state?.anonymousVotingHasBeenRequested);

const participantVoteDataSelector = () =>
  createSelector(participantState, (state) => state?.participantVoteData);

const assemblyIdsSelector = () => createSelector(participantState, (state) => state?.assemblyIds);

const votingDataSelector = () => createSelector(participantState, (state) => state?.votingData);

const isLoadingSelector = () => createSelector(participantState, (state) => state?.isLoading);

const participantViewBrandingDataSelector = () =>
  createSelector(participantState, (state) => state?.participantViewBrandingData);

const adminMessageSelector = () => createSelector(participantState, (state) => state?.adminMessage);

const endVotingDataSelector = () =>
  createSelector(participantState, (state) => state?.endVotingData);

const activeAgendaItemIdSelector = () =>
  createSelector(participantState, (state) => state?.activeAgendaItemId);

const dataOfVoteGoingOnSelector = () =>
  createSelector(participantState, (state) => state?.dataOfVoteGoingOn);

export {
  activeAgendaItemIdSelector,
  adminMessageSelector,
  anonymousVotingHasBeenRequestedSelector,
  assemblyIdsSelector,
  dataOfVoteGoingOnSelector,
  endVotingDataSelector,
  isLoadingSelector,
  isParticipantVotedSelector,
  participantAgendaItemsSelector,
  participantAssemblyAgendaItemsIdsSelector,
  participantAssemblyDataSelector,
  participantBallotsSelector,
  participantViewBrandingDataSelector,
  participantVoteDataSelector,
  votingDataSelector
};
