import './index.scss';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import { PageSpinner } from './components/Spinner/Spinner';
import * as serviceWorker from './serviceWorker';
import { mainTheme } from './theme';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as ReactDOM from 'react-dom';
import App from 'App';
import 'core-js/es/array';
import 'core-js/es/symbol';
import 'raf/polyfill';
import './i18n';
require('dotenv').config();

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={mainTheme}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<PageSpinner />}>
          <App />
        </Suspense>
      </PersistGate>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
