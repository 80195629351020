import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import styled from 'styled-components';
import { COLORS } from 'constants/common';

export const StyledModal = styled(Dialog)`
  & .MuiDialogTitle-root .MuiTypography-h4,
  .MuiDialogContent-root .MuiTypography-h3 {
    padding-left: ${(props) => (props.isEmailFormModal ? '16px' : 0)};
  }
  & .MuiDialog-paper {
    display: flex;
    position: relative;
    flex-direction: column;
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    height: auto;
    max-height: ${(props) => (props.height ? `${props.height}px` : 'none')};
    margin: auto;
  }
  & .new-batch-buttons-group {
    margin-top: 20px;
  }
`;

export const StyledMuiDialogContent = styled(MuiDialogContent)`
  &.MuiDialogContent-root {
    display: flex;
    width: auto;
    height: auto;
    padding: 48px;
    box-sizing: border-box;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  & form {
    width: 100%;
  }
  & .MuiTypography-h3 {
    color: #000000;
    white-space: pre-wrap;
  }
`;

export const StyledMuiDialogTitle = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    padding: 28px 48px 28px 54px;
    box-sizing: border-box;
    margin-bottom: 0;
    background-color: ${COLORS.darkBlue};
    & .MuiTypography-h4 {
      color: #ffffff;
      font-weight: normal;
      line-height: 24px;
    }
    & .MuiButton-label {
      color: #ffffff;
    }
    & .MuiButton-fullWidth {
      width: max-content;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;
