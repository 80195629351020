import api from '../../api';
import { encryptData } from 'utils/decryptEncryptAES';
import {
  CLEAR_FILE_UPLOAD_ERROR,
  CLEAR_UPLOADED_FILES,
  SET_FILE_UPLOAD_ERROR,
  SET_UPLOADED_FILES
} from '../../constants/types';
import { setLoading } from './common';

export const uploadFilesToServer = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.files
    .uploadFiles(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch({
        type: SET_FILE_UPLOAD_ERROR,
        payload: error.file?.errorCode
      });
      dispatch(setLoading(false));
      return { isError: true };
    });
};

export const uploadLogoToServer = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.files
    .uploadLogoImg(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch({
        type: SET_FILE_UPLOAD_ERROR,
        payload: error.file?.errorCode
      });
      dispatch(setLoading(false));
      return { isError: true };
    });
};

export const downloadInvitationFile = (data) => () => {
  return api.files
    .getInvitationFile({ data: encryptData(data) })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};
export const downloadAgendaItemFile = (data, isParticipantView) => () => {
  const sendingData = {
    ...{ data: encryptData(data) },
    isParticipantSide: isParticipantView
  };
  return api.files
    .getAgendaItemFile(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const downloadSomeFile = (data) => () => {
  return api.files
    .getSomeFile({ data: encryptData(data) })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export function clearFileUploadError() {
  return { type: CLEAR_FILE_UPLOAD_ERROR };
}

export function setUploadedFiles(data) {
  return {
    type: SET_UPLOADED_FILES,
    payload: data
  };
}

export function clearUploadedFiles(data) {
  return {
    type: CLEAR_UPLOADED_FILES,
    payload: data
  };
}
