import { findDuplicatesInArray } from 'AdminView/utils';
import { STORES_NAMES } from 'constants/common';
import { VOTE_STATUSES } from 'constants/voting';
import { getAgendaBallotWithResults, getAssemblyAgendaItemData, onUpdateAssembly } from 'Redux/actions/assemblies';
import { setPreviewPageBallotsData } from 'Redux/actions/assemblyPreviewPage';
import { setLoading } from 'Redux/actions/common';
import { setFollowUpBallotsData } from 'Redux/actions/followUpAndDoneAssembly';
import { setLiveAssemblyBallotsData } from 'Redux/actions/liveAssembly';
import {
  addNewAssembly,
  setAssemblySetupProgress,
  setAssemblyUnsavedChanges,
  setNewAssemblyData,
  setNextAssemblySetupStep
} from 'Redux/actions/newAssembly';
import {
  getParticipantAgendaItemData,
  setActiveAgendaItemId,
  setBallotDataOfVoteGoingOn,
  setParticipantAgendaItemsData,
  setParticipantViewBallotsData,
  setVotingData
} from 'Redux/actions/participant';
import { setOpenSharesPopup, setPlannedAssemblyBallotsData, updateSharesData } from 'Redux/actions/plannedAssembly';
import { store } from 'store';
import { AGENDA_ITEM_STATUSES } from '../constants/assemblies';
import { sortSharesArray } from './common';
import history from './history';

export const saveAssemblyData = async (
  assemblySetupProgressData,
  data,
  nextAssemblySetupStep,
  assemblyUnsavedChanges,
  assemblyId
) => {
  const { dispatch } = store;

  if (nextAssemblySetupStep) {
    dispatch(setNextAssemblySetupStep(false));
  }
  if (assemblyUnsavedChanges) {
    dispatch(setAssemblyUnsavedChanges(false));
  }

  if (assemblyId) {
    await dispatch(
      onUpdateAssembly({
        Assembly: {
          ...data,
          id: assemblyId
        }
      })
    );
  } else {
    await dispatch(addNewAssembly({ Assembly: { ...data } }));
  }
  if (assemblySetupProgressData) {
    dispatch(setAssemblySetupProgress(assemblySetupProgressData));
  }
  dispatch(setNewAssemblyData(data));
};

export const checkIsAllSharesSetup = () => {

  const { dispatch } = store;
  const state = store.getState();

  const { invitedEmailsList, newBatchesList, shares, ballots } = state.plannedAssemblyStore;

  const checkHasSharesVoting = Object.values(ballots)
    .flat()
    .some((item) => item.voteBasedOnShares);

  if (!checkHasSharesVoting) {
    return true;
  }

  if (!shares.length && invitedEmailsList.length && !newBatchesList.length) {
    const newList = invitedEmailsList.map((item) => ({
      email: item,
      shares: null
    }));
    dispatch(updateSharesData(sortSharesArray(newList)));
    dispatch(setOpenSharesPopup(true));
    return false;
  }
  if (!shares.length && invitedEmailsList.length && newBatchesList.length) {
    const newList = [
      ...invitedEmailsList,
      ...newBatchesList.map((item) => item.recipients.map((item) => item.email)).flat()
    ].map((item) => ({
      email: item,
      shares: null
    }));
    dispatch(updateSharesData(sortSharesArray(newList)));
    dispatch(setOpenSharesPopup(true));
    return false;
  }

  const participantsInList = shares.map((item) => item.email);
  const newBatchesParticipantsList = newBatchesList
    .map((item) => item.recipients.map((item) => item.email))
    .flat();
  const invitedParticipantsNotInSharesList = invitedEmailsList.filter(
    (item) => !participantsInList.includes(item)
  );
  const newBatchesParticipantsNotOnSharesList = newBatchesParticipantsList.filter(
    (newBatchEmail) => !participantsInList.includes(newBatchEmail)
  );
  if (
    shares.length
    && (newBatchesParticipantsNotOnSharesList.length || invitedParticipantsNotInSharesList.length)
  ) {
    const newBatchesParticipantsSharesList = newBatchesParticipantsNotOnSharesList.map((item) => ({
      email: item,
      shares: null
    }));
    const invitedParticipantsSharesList = invitedParticipantsNotInSharesList.map((item) => ({
      email: item,
      shares: null
    }));
    const updatedSharesList = sortSharesArray([
      ...shares,
      ...newBatchesParticipantsSharesList,
      ...invitedParticipantsSharesList
    ]);
    dispatch(updateSharesData(updatedSharesList));
    dispatch(setOpenSharesPopup(true));
    return false;
  }
  const hasParticipantsWithoutShares = shares.some((item) => item.shares === null);
  if (hasParticipantsWithoutShares) {
    dispatch(setOpenSharesPopup(true));
    return false;
  }
  if (!hasParticipantsWithoutShares) {
    return true;
  }
};

export const checkAgendaSavingRestrictions = (
  agendaItemSwitchersState,
  options,
  errorFieldsNames,
  castUpValue
) => {
  const duplicatedOptions = findDuplicatesInArray(options);
  const voteOptionsCount = agendaItemSwitchersState.abstain
    ? options.length - 1
    : options.length;
  switch (true) {
    case errorFieldsNames:
      return null;
    case !!duplicatedOptions.join(',').length:
      return {
        errorKey: 'theSameVotingOptions',
        errorFieldNames: duplicatedOptions
      };
    case !castUpValue.length:
      return {
        errorKey: 'noEmptyFiledMaxVotesCount',
        errorFieldNames: ['maxVotesCount']
      };
    case agendaItemSwitchersState.multiply && castUpValue < 2:
      return {
        errorKey: 'maxVotesCount',
        errorFieldNames: ['maxVotesCount']
      };
    case castUpValue > voteOptionsCount:
      return {
        errorKey: 'maxVotesCountMoreThanOptionsCount',
        errorFieldNames: ['maxVotesCount']
      };
    default:
      return null;
  }
};

export const updateMultiplyAgendaItemsData = (itemsToUpdate, nextAgendaItemId) => {
  const { dispatch } = store;
  const state = store.getState();

  const { agendaItemsData } = state.participantStore;

  const updatedAgendaItems = agendaItemsData.map(agendaItem => {
    return itemsToUpdate.includes(agendaItem.id)
      ? dispatch(getParticipantAgendaItemData({ id: agendaItem.id }))
        .then(async itemResponse => {
          await getAndUpdateBallotsData(
            itemResponse.id,
            itemResponse.votesIds,
            STORES_NAMES.participantAssembly,
            true
          );
          return itemResponse;
        })
      : agendaItem;
  });

  Promise.all(updatedAgendaItems).then(response => {
    dispatch(setParticipantAgendaItemsData(response));
    dispatch(setActiveAgendaItemId(nextAgendaItemId));
  });

};

export const updateAgendaItemData = async (agendaItemId, storeName, isParticipantSide) => {
  const { dispatch } = store;
  const state = store.getState();
  const { agendaItemsData } = state[storeName];
  return await dispatch(
    isParticipantSide
      ? getParticipantAgendaItemData({ id: agendaItemId })
      : getAssemblyAgendaItemData({ id: agendaItemId })
  )
    .then(async (itemResponse) => {
      const itemIndex = agendaItemsData?.findIndex(
        (agendaItem) => agendaItem?.id === itemResponse?.id
      );
      const newAssemblyItemsDataToUpdate = [...agendaItemsData];
      newAssemblyItemsDataToUpdate[itemIndex] = { ...itemResponse };

      return newAssemblyItemsDataToUpdate;
    });
};

export const getItemsData = async (
  itemsIds,
  storeName,
  withBallots,
  withoutAttachmentsIds,
  participantSide
) => {
  const { dispatch } = store;
  const arrayIDs = itemsIds;
  dispatch(setLoading(true));
  const agendaItemsData = await Promise.all(arrayIDs.map((item) =>
    dispatch(getAssemblyAgendaItemData({ id: item }, withoutAttachmentsIds, participantSide))
      .then((itemResponse) => {
        if (itemResponse?.votesIds?.length && withBallots) {
          const isActiveAgendaItemBallots = itemResponse.discussionStatus === AGENDA_ITEM_STATUSES.Current;
          getAndUpdateBallotsData(
            itemResponse?.id,
            itemResponse?.votesIds,
            storeName,
            participantSide,
            isActiveAgendaItemBallots
          );
        }
        return itemResponse;
      })));
  dispatch(setLoading(false));
  return agendaItemsData;
};

const updateBallotsStoreData = (storeName, data) => {
  const { dispatch } = store;
  switch (true) {
    case STORES_NAMES.plannedAssembly === storeName:
      dispatch(setPlannedAssemblyBallotsData(data));
      break;
    case STORES_NAMES.participantAssembly === storeName:
      dispatch(setParticipantViewBallotsData(data));
      break;
    case STORES_NAMES.liveAssembly === storeName:
      dispatch(setLiveAssemblyBallotsData(data));
      break;
    case STORES_NAMES.followUpAndDone === storeName:
      dispatch(setFollowUpBallotsData(data));
      break;
    case STORES_NAMES.previewAssembly === storeName:
      dispatch(setPreviewPageBallotsData(data));
      break;
    default:
      return null;
  }
};

export const updateSingleBallotData = async (agendaItemId, ballotId, storeName, participantSide) => {
  const { dispatch } = store;
  const state = store.getState();
  const { ballots } = state[storeName];
  const ballotData = await dispatch(getAgendaBallotWithResults({ Id: ballotId }, participantSide))
    .then((ballotResponse) => {
      return {
        ...ballotResponse?.information,
        results: ballotResponse?.results
      };
    });
  const itemIndex = ballots[agendaItemId]?.findIndex(
    (item) => item?.id === ballotData?.id
  );
  const updatedAgendaItemBallots = [...ballots[agendaItemId]];
  updatedAgendaItemBallots[itemIndex] = { ...ballotData };

  const data = {
    [agendaItemId]: updatedAgendaItemBallots
  };
  updateBallotsStoreData(storeName, data);
};

export const getAndUpdateBallotsData = async (
  agendaItemId,
  ballotsIds,
  storeName,
  participantSide,
  isActiveAgendaItemBallots
) => {
  const { dispatch } = store;
  dispatch(setLoading(true));
  const ballotsData = ballotsIds?.length
    ? await Promise.all(ballotsIds.map((ballotId) =>
        dispatch(getAgendaBallotWithResults({ Id: ballotId }, participantSide))
          .then((ballotResponse) => {
            if (participantSide && isActiveAgendaItemBallots) {
              checkIsVoteOnWhileFirstDataLoad(ballotResponse);
            }
            return {
              ...ballotResponse?.information,
              results: ballotResponse?.results
            };
          })
      )
    )
    : [];
  const data = {
    [agendaItemId]: ballotsData
  };
  dispatch(setLoading(false));
  updateBallotsStoreData(storeName, data);
};

const checkIsVoteOnWhileFirstDataLoad = (ballotData) => {
  const { dispatch } = store;
  const isBallotVoteGoingOn = [
    VOTE_STATUSES.announced,
    VOTE_STATUSES.inProcess
  ].includes(ballotData.information.status);
  if (isBallotVoteGoingOn) {
    dispatch(setBallotDataOfVoteGoingOn(ballotData.information));
  }
};

export const moveToAnonymousVotingRequestView = (assemblyId, agendaItemId, voteId) => {
  const urlData = {
    assemblyId,
    agendaItemId,
    voteId
  };
  Promise.all([store.dispatch(setVotingData(urlData))]).then(() =>
    history.push(`/assembly/request-anon-ballot/${voteId}`)
  );
};

export const goToBallot = (assemblyId, agendaItemId, voteId) => {
  const urlData = {
    assemblyId,
    agendaItemId,
    voteId
  };

  Promise.all([store.dispatch(setVotingData(urlData))]).then(() =>
    history.push(`/assembly/voting/${voteId}`)
  );
};
