import api from 'api';
import {
  ADD_NEW_BALLOT,
  SET_LIVE_ASSEMBLY_AGENDA_ITEMS_DATA,
  SET_ANNOUNCED_VOTING_AGENDA_ITEM_ID,
  SET_LIVE_ASSEMBLY_DATA,
  SET_LIVE_ASSEMBLY_START_DATE,
  SET_LIVE_ASSEMBLY_VOTING_PARTICIPANTS_COUNTING,
  SET_IS_VOTE_GOING_ON,
  SET_ACTIVE_BALLOT_ID,
  SET_LIVE_ASSEMBLY_BALLOTS_DATA,
  CLEAR_LIVE_ASSEMBLY_DATA
} from 'constants/types';

export const onStartAssemblySession = (data) => () => {
  return api.commonAssemblies
    .startAssemblySession(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onFinishAssemblySession = (data) => () => {
  return api.commonAssemblies
    .finishAssemblySession(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onSkipAgendaItem = (data) => () => {
  return api.voting
    .skipAgendaItem(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const startBallotVoting = (data) => () => {
  return api.voting
    .startVote(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const restartBallotVoting = (data) => () => {
  return api.voting
    .restartVote(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const finishAgendaVoting = (data) => () => {
  return api.voting
    .finishBallotVoting(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const setSessionActiveAgendaItem = (data) => () => {
  return api.commonAssemblies
    .setActiveAgendaItem(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onReopenAgendaItem = (data) => () => {
  return api.voting
    .reopenAgendaItem(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const changeAgendaItemStatus = (data) => () => {
  return api.commonAssemblies
    .changeAgendaStatus(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const setVotingStatusReadyForResults = (data) => () => {
  return api.voting
    .announceBallotResults(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onAnnounceVoting = (data) => (dispatch) => {
  dispatch(setAnnouncedVotingAgendaItemId(data.agendaItemId));
  return api.voting
    .announceBallotVoting(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onSetCurrentVote = (data) => () => {
  return api.voting
    .setCurrentVote(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onUnsetCurrentVote = (data) => () => {
  return api.voting
    .unsetCurrentVote(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onSendMessageToParticipants = (data) => () => {
  return api.commonApi
    .sendMessageToParticipants(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export function setAnnouncedVotingAgendaItemId(data) {
  return {
    type: SET_ANNOUNCED_VOTING_AGENDA_ITEM_ID,
    payload: data
  };
}

export function setStartAssemblyDate(data) {
  return {
    type: SET_LIVE_ASSEMBLY_START_DATE,
    payload: data
  };
}

export function setLiveAssemblyData(data) {
  return {
    type: SET_LIVE_ASSEMBLY_DATA,
    payload: data
  };
}

export function addNewBallot(data) {
  return {
    type: ADD_NEW_BALLOT,
    payload: data
  };
}

export function setLiveAssemblyAgendaItemsData(data) {
  return {
    type: SET_LIVE_ASSEMBLY_AGENDA_ITEMS_DATA,
    payload: data
  };
}

export function setLiveAssemblyParticipantsVotingCounting(data) {
  return {
    type: SET_LIVE_ASSEMBLY_VOTING_PARTICIPANTS_COUNTING,
    payload: data
  };
}

export function setIsVoteGoingOn(data) {
  return {
    type: SET_IS_VOTE_GOING_ON,
    payload: data
  };
}

export function setLiveAssemblyBallotsData(data) {
  return {
    type: SET_LIVE_ASSEMBLY_BALLOTS_DATA,
    payload: data
  };
}

export function setActiveBallotId(data) {
  return {
    type: SET_ACTIVE_BALLOT_ID,
    payload: data
  };
}

export function clearLiveAssemblyData(data) {
  return {
    type: CLEAR_LIVE_ASSEMBLY_DATA,
    payload: data
  };
}
