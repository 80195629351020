import {
  SET_ASSEMBLY_EDITING_DATA,
  CLEAR_PLANNED_ASSEMBLY_STORAGE,
  CLEAR_STORAGE,
  SET_AGENDA_ITEMS_DATA,
  SET_INVITED_EMAILS_LIST,
  SET_NEW_BATCHES_LIST,
  UPDATE_SHARES_DATA,
  SET_OPEN_ADD_SHARES_POPUP,
  SET_BATCH_ID_WAITING_TO_SEND,
  SET_LAST_UPDATED_VOTE_DATA,
  SET_ALERT_ERROR,
  SET_PLANNED_ASSEMBLY_BALLOTS_DATA
} from 'constants/types';

const initialState = {
  agendaItemsData: [],
  alertError: null,
  ballots: {},
  batchIdWaitingToSend: null,
  editingAssemblyData: null,
  invitedEmailsList: [],
  lastUpdatedVoteData: null,
  newBatchesList: [],
  openSharesPopup: false,
  shares: []
};

const plannedAssemblyReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_NEW_BATCHES_LIST:
      return {
        ...state,
        newBatchesList: [...payload]
      };
    case SET_AGENDA_ITEMS_DATA:
      return {
        ...state,
        agendaItemsData: [...payload]
      };
    case SET_ASSEMBLY_EDITING_DATA:
      return {
        ...state,
        editingAssemblyData: payload?.assembly
      };
    case SET_INVITED_EMAILS_LIST:
      return {
        ...state,
        invitedEmailsList: payload
      };
    case UPDATE_SHARES_DATA:
      return {
        ...state,
        shares: [...payload]
      };
    case SET_OPEN_ADD_SHARES_POPUP:
      return {
        ...state,
        openSharesPopup: payload
      };
    case SET_BATCH_ID_WAITING_TO_SEND:
      return {
        ...state,
        batchIdWaitingToSend: payload
      };
    case SET_LAST_UPDATED_VOTE_DATA:
      return {
        ...state,
        lastUpdatedVoteData: payload
      };
    case SET_PLANNED_ASSEMBLY_BALLOTS_DATA:
      return {
        ...state,
        ballots: { ...state.ballots, ...payload }
      };
    case SET_ALERT_ERROR:
      return {
        ...state,
        alertError: payload
      };
    case CLEAR_STORAGE:
    case CLEAR_PLANNED_ASSEMBLY_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default plannedAssemblyReducer;
