import Typography from '@material-ui/core/Typography';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CustomButton } from 'components/Buttons/CustomButton';
import { CustomDialog, CustomDialogActions, DialogActionsHint } from './styledComponents';

export const DialogPopUp = ({
  isOpen,
  title,
  contentText,
  handleSubmit,
  submitButtonName,
  cancelButtonName,
  handleCancel,
  dialogHint
}) => (
  <CustomDialog
    open={isOpen}
    onClose={handleCancel}
    aria-labelledby="form-dialog-title"
    maxWidth="xs"
  >
    {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
    <DialogContent>
      <DialogContentText dangerouslySetInnerHTML={{ __html: contentText }} />
    </DialogContent>
    <CustomDialogActions disableSpacing isOneButtonRow={!cancelButtonName}>
      {!cancelButtonName ? (
        <CustomButton
          className="submitButton"
          colorType="secondary"
          name={submitButtonName}
          onClick={handleSubmit}
          type="submit"
          variant="contained"
        />
      ) : (
        <CustomButton
          fullWidth
          className="submitButton"
          name={submitButtonName}
          onClick={handleSubmit}
          type="submit"
          variant="outlined"
        />
      )}
      {cancelButtonName && (
        <CustomButton
          fullWidth
          colorType="secondary"
          name={cancelButtonName}
          onClick={handleCancel}
          variant="text"
        />
      )}
      {!!dialogHint && (
        <DialogActionsHint>
          <Typography variant="body2">{dialogHint}</Typography>
        </DialogActionsHint>
      )}
    </CustomDialogActions>
  </CustomDialog>
);
