import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { StyledSwitcher } from 'components/styledComponents';
import { SWITCHER_LABELS } from 'constants/common';
import { MyAccountBlockWrapper, MyAccountSelectorRow } from 'MyAccountView/styledComponents';
import { DEFAULT_VOTING_TIME } from 'constants/myAccount';
import { DialogPopUp } from 'components/DialogPopUp/DialogPopUp';
import { myAccountDataSelector } from 'Redux/selectors/myAccount';
import { useUpdateMyAccount } from 'hooks/useUpdateMyAccountData';
import { BallotVotingTime } from './BallotVotingTime';

const SystemDefaultsBlock = ({ myAccountData, t }) => {
  const [confirmationActionPopup, setConfirmationActionPopup] = useState(null);
  const [isOpenBallotByDefault, setIsOpenBallotByDefault] = useState(false);
  const [votingTime, setVotingTime] = useState(DEFAULT_VOTING_TIME);
  const { updateAccount } = useUpdateMyAccount();

  useEffect(() => {
    if (myAccountData) {
      const { votingTime, defaultVoteIsOpen } = myAccountData || {};
      setVotingTime(votingTime || DEFAULT_VOTING_TIME);
      setIsOpenBallotByDefault(!!defaultVoteIsOpen);
    }
  }, [myAccountData]);

  const onChangeVotingTime = (value) => {
    setConfirmationActionPopup({
      actionType: 'changeVotingTime',
      value
    });
  };
  const onChangeDefaultVoteType = (e) => {
    setConfirmationActionPopup({
      actionType: 'changeDefaultVoteType',
      value: e.target.checked
    });
  };

  const confirmAction = () => {
    if (confirmationActionPopup?.actionType === 'changeDefaultVoteType') {
      updateAccount({ defaultVoteIsOpen: confirmationActionPopup.value });
    }
    if (confirmationActionPopup?.actionType === 'changeVotingTime') {
      updateAccount({ votingTime: confirmationActionPopup.value });
    }
    setConfirmationActionPopup(null);
  };

  const cancelAction = () => {
    setConfirmationActionPopup(null);
  };

  return (
    <MyAccountBlockWrapper>
      <Typography variant="body2" className="input-label">
        {t('titles.systemDefault')}
      </Typography>
      <MyAccountSelectorRow className="with-bottom-margin">
        <Typography
          variant="body1"
          onClick={() => setIsOpenBallotByDefault(!isOpenBallotByDefault)}
        >
          {t('switcherLabels.voteUsingOpenBallot')}
        </Typography>
        <StyledSwitcher
          checked={isOpenBallotByDefault || false}
          onChange={onChangeDefaultVoteType}
          name={SWITCHER_LABELS.ballotTypeSwitcher}
        />
      </MyAccountSelectorRow>
      <BallotVotingTime selectedVotingTime={votingTime} changeValue={onChangeVotingTime} t={t} />
      {confirmationActionPopup && (
        <DialogPopUp
          isOpen={confirmationActionPopup}
          title={
            confirmationActionPopup?.actionType
            && t(`dialogsAndPopups.${confirmationActionPopup?.actionType}.title`, '')
          }
          contentText={
            confirmationActionPopup?.actionType
            && t(`dialogsAndPopups.${confirmationActionPopup?.actionType}.content`, '')
          }
          handleSubmit={confirmAction}
          handleCancel={cancelAction}
          submitButtonName={t('buttonsNames.save')}
          cancelButtonName={t('buttonsNames.discard')}
        />
      )}
    </MyAccountBlockWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  myAccountData: myAccountDataSelector()
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(SystemDefaultsBlock);
