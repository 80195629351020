export const ASSEMBLY_STATUSES = {
  done: 'done',
  followup: 'follow-up',
  live: 'Live',
  planned: 'planned'
};

export const ASSEMBLY_LIST_HEADER_PANEL_ITEMS = [
  {
    id: 'name',
    className: 'assembly-name column-name',
    isSortable: true
  },
  {
    id: 'date',
    className: 'assembly-date column-name',
    isSortable: true
  },
  {
    id: 'participantsCount',
    className: 'assembly-participants-count column-name',
    isSortable: true
  },
  {
    id: 'status',
    className: 'assembly-status column-name'
  }
];

export const BATCHES_LIST_ITEMS = [
  {
    id: 'Name',
    className: 'batch-name'
  },
  {
    id: 'Status',
    className: 'status-batch'
  },
  {
    id: 'Sent',
    className: 'sent-date'
  },
  {
    id: 'Recipients',
    className: 'recipients-count'
  },
  {
    id: 'Actions',
    className: 'batch-item-action'
  }
];

export const BATCH_INVITATIONS_LIST_ITEMS = [
  {
    id: 'Sequence',
    className: 'sequence-number',
    withTooltip: false
  },
  {
    id: 'Status',
    className: 'status-batch',
    withTooltip: false
  },
  {
    id: 'Email',
    className: 'invitation-email',
    withTooltip: true
  },
  {
    id: 'Invitation',
    className: 'invitation-version',
    withTooltip: false
  },
  {
    id: 'Agenda',
    className: 'agenda-version',
    withTooltip: false
  }
];

export const NEW_ASSEMBLY_TEXT_FIELD_WIDTH = {
  year: '100px',
  day: '68px',
  month: '68px',
  time: '116px',
  timeZone: '150px'
};

export const TIME_ZONES_LIST = [
  {
    value: '-1200',
    timeZoneBias: '-12',
    label: 'GMT-12'
  },
  {
    value: '-1100',
    timeZoneBias: '-11',
    label: 'GMT-11'
  },
  {
    value: '-1000',
    timeZoneBias: '-10',
    label: 'GMT-10'
  },
  {
    value: '-0900',
    timeZoneBias: '-9',
    label: 'GMT-9'
  },
  {
    value: '-0800',
    timeZoneBias: '-8',
    label: 'GMT-8'
  },
  {
    value: '-0700',
    timeZoneBias: '-7',
    label: 'GMT-7'
  },
  {
    value: '-0600',
    timeZoneBias: '-6',
    label: 'GMT-6'
  },
  {
    value: '-0500',
    timeZoneBias: '-5',
    label: 'GMT-5'
  },
  {
    value: '-0400',
    timeZoneBias: '-4',
    label: 'GMT-4'
  },
  {
    value: '-0300',
    timeZoneBias: '-3',
    label: 'GMT-3'
  },
  {
    value: '-0200',
    timeZoneBias: '-2',
    label: 'GMT-2'
  },
  {
    value: '-0100',
    timeZoneBias: '-1',
    label: 'GMT-1'
  },
  {
    value: '+0000',
    timeZoneBias: '0',
    label: 'GMT+0'
  },
  {
    value: '+0100',
    timeZoneBias: '1',
    label: 'GMT+1'
  },
  {
    value: '+0200',
    timeZoneBias: '2',
    label: 'GMT+2'
  },
  {
    value: '+0300',
    timeZoneBias: '3',
    label: 'GMT+3'
  },
  {
    value: '+0400',
    timeZoneBias: '4',
    label: 'GMT+4'
  },
  {
    value: '+0500',
    timeZoneBias: '5',
    label: 'GMT+5'
  },
  {
    value: '+0600',
    timeZoneBias: '6',
    label: 'GMT+6'
  },
  {
    value: '+0700',
    timeZoneBias: '7',
    label: 'GMT+7'
  },
  {
    value: '+0800',
    timeZoneBias: '8',
    label: 'GMT+8'
  },
  {
    value: '+0900',
    timeZoneBias: '9',
    label: 'GMT+9'
  },
  {
    value: '+1000',
    timeZoneBias: '10',
    label: 'GMT+10'
  },
  {
    value: '+1100',
    timeZoneBias: '11',
    label: 'GMT+11'
  },
  {
    value: '+1200',
    timeZoneBias: '12',
    label: 'GMT+12'
  },
  {
    value: '+1300',
    timeZoneBias: '13',
    label: 'GMT+13'
  },
  {
    value: '+1400',
    timeZoneBias: '14',
    label: 'GMT+14'
  }
];

export const AGENDA_ITEM_STATUSES = {
  Current: 'Current',
  Finished: 'Finished',
  Planned: 'Planned'
};

export const PROGRESS_BAR_ITEMS_IDS = {
  agendaAndBallots: 'agendaAndBallots',
  agendaName: 'name',
  invitationEmail: 'invitationEmail',
  participants: 'participants',
  timeAndLocation: 'timeAndLocation'
};

export const PROGRESS_BAR_ITEMS = [
  {
    descriptionsKeys: {
      empty: 'nothingYet',
      filled: 'greatChoice'
    },
    id: PROGRESS_BAR_ITEMS_IDS.agendaName,
    stepName: 'name'
  },
  {
    descriptionsKeys: {},
    id: PROGRESS_BAR_ITEMS_IDS.participants,
    stepName: 'participants'
  },
  {
    descriptionsKeys: {
      empty: 'nothingYet',
      filled: 'allSet'
    },
    id: PROGRESS_BAR_ITEMS_IDS.timeAndLocation,
    stepName: 'date'
  },
  {
    descriptionsKeys: {
      empty: 'nothingYet',
      filled: 'allSet'
    },
    id: PROGRESS_BAR_ITEMS_IDS.invitationEmail,
    stepName: 'invitationEmail'
  },
  {
    descriptionsKeys: {
      empty: 'nothingYet',
      filled: 'itemsCount'
    },
    id: PROGRESS_BAR_ITEMS_IDS.agendaAndBallots,
    stepName: 'setupAgenda'
  }
];

export const ADD_EDIT_ASSEMBLY_FIELDS_NAMES = {
  agendaHeadline: 'agenda-headline',
  agendaItemTitle: 'agenda-item-title',
  assemblyName: 'assembly-name',
  assemblyParticipants: 'participants-emails',
  dateField: 'day',
  endTime: 'end',
  invitationSubject: 'invitation-body',
  invitationTitle: 'email-invitation-title',
  locationFiled: 'location-field',
  monthField: 'month',
  startTime: 'start',
  timeZone: 'timezone',
  yearField: 'year'
};

export const BATCHES_STATUSES = {
  received: 'Received',
  rejected: 'Rejected'
};

export const ASSEMBLY_STATUSES_NAMES = {
  Done: 'Done',
  Draft: 'Draft',
  FollowUp: 'Follow-up',
  Live: 'LIVE',
  Planned: 'Planned'
};

export const ASSEMBLY_FILTER_PANEL_TABS = [
  {
    id: 'All'
  },
  {
    id: 'Draft'
  },
  {
    id: 'Planned'
  },
  {
    id: 'FollowUp'
  },
  {
    id: 'Done'
  }
];

export const COUNT_ASSEMBLIES_PER_PAGE = 10;

export const EDIT_ASSEMBLY_FIELDS_NAMES = {
  name: 'name',
  dateAndTime: 'dateAndTime',
  location: 'location',
  invitationHeader: 'invitationHeader',
  invitationBody: 'invitationBody',
  agendaItemTitle: 'title',
  agendaItemDescription: 'description'
};

export const EDIT_AGENDA_ITEMS_ACTIONS = {
  addNewAgendaItem: {
    key: 'addNewAgendaItem'
  },
  addNewBallot: {
    key: 'addNewBallot'
  },
  editBallot: {
    key: 'editBallot'
  }
};

export const AGENDA_ITEMS_ACTIONS_KEYS = {
  deleteAgendaItem: 'deleteAgendaItem',
  deleteAgendaItemBallot: 'deleteAgendaItemBallot',
  deleteAgendaItemFile: 'deleteAgendaItemFile',
  deleteInvitationDescription: 'deleteInvitationDescription'
};

export const ASSEMBLY_BLOCKS_AND_ITEMS_BOTTOM_MARGIN_VALUE = '40px';

export const MAX_VOTES_COUNT_INITIAL_VALUE = '1';

export const BATCHES_ACTIONS_NAMES = {
  removeParticipantFromBatch: 'removeParticipantFromBatch',
  resendInvitationToParticipant: 'resendInvitationToParticipant'
};
