import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  AssemblyStatus,
  StepHeaderWrapper,
  TitleGroup,
  TitleWrapper
} from 'AdminView/AddNewAssemblyView/styledComponets';
import { assemblyStatusesIcon } from 'AdminView/utils';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { setAssemblySetupStepBackFlag } from 'Redux/actions/newAssembly';
import { setupNewAssemblyStepSelector } from 'Redux/selectors/newAssembly';
import { createStructuredSelector } from 'reselect';
import history from 'utils/history';
import { ASSEMBLY_STATUSES_NAMES } from 'constants/assemblies';

export const StepHeader = ({
  actionSetAssemblySetupStepBackFlag,
  isEditAssembly,
  hiddenBackButton,
  status,
  statusName,
  subTitle,
  title
}) => {
  const onBackButtonClick = () => {
    if (isEditAssembly) {
      history.push(`/dashboard`);
    } else {
      actionSetAssemblySetupStepBackFlag(true);
    }
  };

  return (
    <StepHeaderWrapper hiddenBackButton={hiddenBackButton}>
      <TitleGroup>
        {subTitle && <Typography variant="body2">{subTitle}</Typography>}
        <TitleWrapper>
          <IconButton
            tabIndex="-1"
            color="inherit"
            disableFocusRipple={true}
            disableRipple={true}
            onClick={() => onBackButtonClick()}
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <Tooltip placement="bottom-start" title={title || ''}>
            <Typography variant="h2">{title}</Typography>
          </Tooltip>
        </TitleWrapper>
      </TitleGroup>
      {statusName && (
        <AssemblyStatus isDone={statusName === ASSEMBLY_STATUSES_NAMES.Done}>
          <Typography variant="body1">{statusName?.toUpperCase()}</Typography>
          {assemblyStatusesIcon(status?.toLowerCase())}
        </AssemblyStatus>
      )}
    </StepHeaderWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  setupAssemblyStep: setupNewAssemblyStepSelector()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionSetAssemblySetupStepBackFlag: setAssemblySetupStepBackFlag
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(StepHeader);
