import {
  CLEAR_STORAGE,
  SET_FOLLOW_UP_AND_DONE_ASSEMBLY_DATA,
  SET_FOLLOW_UP_AND_DONE_AGENDA_ITEMS_DATA,
  CLEAR_FOLLOW_UP_AND_DONE_ASSEMBLY_STORAGE,
  SET_FOLLOW_UP_AND_DONE_BALLOTS_DATA
} from 'constants/types';

const initialState = {
  followUpAndDoneAssemblyData: null,
  followUpAndDoneAgendaItemsData: [],
  ballots: {}
};

const followUpAndDoneAssemblyReducer = (
  state = {
    ...initialState
  },
  { type, payload }
) => {
  switch (type) {
    case SET_FOLLOW_UP_AND_DONE_ASSEMBLY_DATA:
      return {
        ...state,
        followUpAndDoneAssemblyData: payload
      };
    case SET_FOLLOW_UP_AND_DONE_AGENDA_ITEMS_DATA:
      return {
        ...state,
        followUpAndDoneAgendaItemsData: [...payload]
      };
    case SET_FOLLOW_UP_AND_DONE_BALLOTS_DATA:
      return {
        ...state,
        ballots: { ...state.ballots, ...payload }
      };
    case CLEAR_STORAGE:
    case CLEAR_FOLLOW_UP_AND_DONE_ASSEMBLY_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default followUpAndDoneAssemblyReducer;
