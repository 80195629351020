import { createSelector } from 'reselect';

const selectAssembliesState = (state) => state.newAssemblyStore;

const newAssemblyDataSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.newAssemblyData);

const setupNewAssemblyStepSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.setupAssemblyStep);

const newAssemblySubStepSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.setupAssemblySubStep);

const assemblyIdSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.assemblyId);

const firstAgendaItemSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.firstAgendaItem);

const firstAgendaItemBallotSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.firstAgendaItemBallot);

const assemblySetupProgressSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.assemblySetupProgress);

const assemblyDateAndTimeDataSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.assemblyDateAndTimeData);

const invitationEmailUploadedFilesSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.invitationEmailUploadedFiles);

const assemblyUnsavedChangesSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.assemblyUnsavedChanges);

const nextAssemblySetupStepSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.nextAssemblySetupStep);

const isAddNewAgendaItemSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.isAddNewAgendaItem);

const assemblySetupStepBackFlagSelector = () =>
  createSelector(selectAssembliesState, (state) => state?.assemblySetupStepBackFlag);

const isDuplicatedAssemblySelector = () =>
  createSelector(selectAssembliesState, (state) => state?.isDuplicatedAssembly);

export {
  assemblyDateAndTimeDataSelector,
  assemblyIdSelector,
  assemblySetupProgressSelector,
  assemblySetupStepBackFlagSelector,
  assemblyUnsavedChangesSelector,
  invitationEmailUploadedFilesSelector,
  isAddNewAgendaItemSelector,
  isDuplicatedAssemblySelector,
  firstAgendaItemSelector,
  firstAgendaItemBallotSelector,
  newAssemblyDataSelector,
  newAssemblySubStepSelector,
  nextAssemblySetupStepSelector,
  setupNewAssemblyStepSelector
};
