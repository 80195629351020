export const SET_CURRENT_USER = 'userLogin/SET_CURRENT_USER';
export const SET_LOGIN_ERROR = 'userLogin/SET_LOGIN_ERROR';
export const CLEAR_LOGIN_ERROR = 'userLogin/CLEAR_LOGIN_ERROR';
export const USER_LOGOUT = 'userLogin/USER_LOGOUT';
export const PARTICIPANT_LOGOUT = 'userLogin/PARTICIPANT_LOGOUT';

export const SET_NEW_ASSEMBLY_DATA = 'newAssembly/SET_NEW_ASSEMBLY_DATA';
export const FIRST_AGENDA_ITEM = 'newAssembly/FIRST_AGENDA_ITEM';
export const SET_NEW_ASSEMBLY_STEP = 'newAssembly/SET_NEW_ASSEMBLY_STEP';
export const SET_NEW_ASSEMBLY_SUB_STEP = 'newAssembly/SET_NEW_ASSEMBLY_SUB_STEP';
export const SET_ASSEMBLY_ID = 'newAssembly/SET_ASSEMBLY_ID';
export const SET_ASSEMBLY_TIMEZONE = 'newAssembly/SET_ASSEMBLY_TIMEZONE';
export const CLEAR_NEW_ASSEMBLY_DATA = 'newAssembly/CLEAR_NEW_ASSEMBLY_DATA';
export const FIRST_AGENDA_ITEM_BALLOT = 'newAssembly/FIRST_AGENDA_ITEM_BALLOT';
export const SET_ASSEMBLY_SETUP_PROGRESS = 'newAssembly/SET_ASSEMBLY_SETUP_PROGRESS';
export const SET_ASSEMBLY_DATE_AND_TIME_DATA = 'newAssembly/SET_ASSEMBLY_DATE_AND_TIME_DATA';
export const SET_INVITATION_EMAIL_FILES = 'newAssembly/SET_INVITATION_EMAIL_FILES';
export const SET_ASSEMBLY_UNSAVED_CHANGES = 'newAssembly/SET_ASSEMBLY_UNSAVED_CHANGES';
export const NEXT_ASSEMBLY_SETUP_STEP = 'newAssembly/NEXT_ASSEMBLY_SETUP_STEP';
export const ADD_NEW_AGENDA_ITEM_FLAG = 'newAssembly/ADD_NEW_AGENDA_ITEM_FLAG';
export const ASSEMBLY_SETUP_STEP_BACK = 'newAssembly/ASSEMBLY_SETUP_STEP_BACK';
export const IS_DUPLICATED_ASSEMBLY = 'newAssembly/IS_DUPLICATED_ASSEMBLY';

export const SET_UPLOADED_FILES = 'files/SET_UPLOADED_FILES';
export const CLEAR_UPLOADED_FILES = 'files/CLEAR_UPLOADED_FILES';
export const SET_FILE_UPLOAD_ERROR = 'files/SET_FILE_UPLOAD_ERROR';
export const CLEAR_FILE_UPLOAD_ERROR = 'files/CLEAR_FILE_UPLOAD_ERROR';

export const SET_FOLLOW_UP_AND_DONE_ASSEMBLY_DATA = 'followUpEndDone/SET_FOLLOW_UP_AND_DONE_ASSEMBLY_DATA';
export const SET_FOLLOW_UP_AND_DONE_AGENDA_ITEMS_DATA =
  'followUpEndDone/SET_FOLLOW_UP_AND_DONE_AGENDA_ITEMS_DATA';
export const CLEAR_FOLLOW_UP_AND_DONE_ASSEMBLY_STORAGE =
  'followUpEndDone/CLEAR_FOLLOW_UP_AND_DONE_ASSEMBLY_STORAGE';
export const SET_FOLLOW_UP_AND_DONE_BALLOTS_DATA =
  'followUpEndDone/SET_FOLLOW_UP_AND_DONE_BALLOTS_DATA';

export const SET_MY_ACCOUNT_DATA = 'myAccount/SET_MY_ACCOUNT_DATA';
export const SET_COMPANY_LOGO_URL = 'myAccount/SET_COMPANY_LOGO_URL';

export const SET_ASSEMBLIES_COLLECTION = 'assemblies/SET_ASSEMBLIES_COLLECTION';

export const SET_AGENDA_ITEMS_DATA = 'plannedAssembly/SET_AGENDA_ITEMS_DATA';
export const SET_ASSEMBLY_EDITING_DATA = 'plannedAssembly/SET_ASSEMBLY_EDITING_DATA';
export const SET_NEW_BATCHES_LIST = 'plannedAssembly/SET_NEW_BATCHES_LIST';
export const CLEAR_PLANNED_ASSEMBLY_STORAGE = 'plannedAssembly/CLEAR_PLANNED_ASSEMBLY_STORAGE';
export const SET_INVITED_EMAILS_LIST = 'plannedAssembly/SET_INVITED_EMAILS_LIST';
export const SET_JOINED_PARTICIPANTS_LIST = 'plannedAssembly/SET_JOINED_PARTICIPANTS_LIST';
export const UPDATE_SHARES_DATA = 'plannedAssembly/UPDATE_SHARES_DATA';
export const SET_OPEN_ADD_SHARES_POPUP = 'plannedAssembly/SET_OPEN_ADD_SHARES_POPUP';
export const SET_BATCH_ID_WAITING_TO_SEND = 'plannedAssembly/SET_BATCH_ID_WAITING_TO_SEND';
export const SET_LAST_UPDATED_VOTE_DATA = 'plannedAssembly/SET_LAST_UPDATED_VOTE_DATA';
export const SET_ASSEMBLY_STATUS_FILTER_VALUE = 'plannedAssembly/SET_ASSEMBLY_STATUS_FILTER_VALUE';
export const SET_NEW_BATCHES_REQUEST_ERROR = 'plannedAssembly/SET_NEW_BATCHES_REQUEST_ERROR';
export const SET_ALERT_ERROR = 'plannedAssembly/SET_ALERT_ERROR';
export const SET_PLANNED_ASSEMBLY_BALLOTS_DATA = 'plannedAssembly/SET_PLANNED_ASSEMBLY_BALLOTS_DATA';

export const SET_ANNOUNCED_VOTING_AGENDA_ITEM_ID =
  'liveAssembly/SET_ANNOUNCED_VOTING_AGENDA_ITEM_ID';
export const SET_LIVE_ASSEMBLY_START_DATE = 'liveAssembly/SET_LIVE_ASSEMBLY_START_DATE';
export const SET_LIVE_ASSEMBLY_DATA = 'liveAssembly/SET_LIVE_ASSEMBLY_DATA';
export const SET_LIVE_ASSEMBLY_AGENDA_ITEMS_DATA =
  'liveAssembly/SET_LIVE_ASSEMBLY_AGENDA_ITEMS_DATA';
export const ADD_NEW_BALLOT = 'liveAssembly/ADD_NEW_BALLOT';
export const SET_IS_VOTE_GOING_ON = 'liveAssembly/SET_IS_VOTE_GOING_ON';
export const SET_LIVE_ASSEMBLY_VOTING_PARTICIPANTS_COUNTING =
  'liveAssembly/SET_LIVE_ASSEMBLY_VOTING_PARTICIPANTS_COUNTING';
export const SET_ACTIVE_BALLOT_ID = 'liveAssembly/SET_ACTIVE_BALLOT_ID';
export const CLEAR_LIVE_ASSEMBLY_DATA = 'liveAssembly/CLEAR_LIVE_ASSEMBLY_DATA';
export const SET_LIVE_ASSEMBLY_BALLOTS_DATA = 'liveAssembly/SET_LIVE_ASSEMBLY_BALLOTS_DATA';

export const SET_ASSEMBLY_PREVIEW_DATA = 'assemblyPreviewPage/SET_ASSEMBLY_PREVIEW_DATA';
export const SET_ASSEMBLY_PREVIEW_BALLOTS_DATA = 'assemblyPreviewPage/SET_ASSEMBLY_PREVIEW_BALLOTS_DATA';
export const SET_ASSEMBLY_PREVIEW_AGENDA_ITEMS_DATA =
  'assemblyPreviewPage/SET_ASSEMBLY_PREVIEW_AGENDA_ITEMS_DATA';
export const IS_DONT_SHOW_BATHES_EMAILS_ACTION_CONFIRMATION_POPUP =
  'assemblyPreviewPage/IS_DONT_SHOW_BATHES_EMAILS_ACTION_CONFIRMATION_POPUP';

export const SET_PARTICIPANT_VOTE_DATA = 'participant/SET_PARTICIPANT_VOTE_DATA';
export const SET_ASSEMBLY_IDS = 'participant/SET_ASSEMBLY_IDS';
export const SET_IS_VOTED = 'participant/SET_IS_VOTED';
export const SET_CURRENT_PARTICIPANT = 'participant/SET_CURRENT_PARTICIPANT';
export const SET_PARTICIPANT_AGENDA_ITEMS_DATA = 'participant/SET_PARTICIPANT_AGENDA_ITEMS_DATA';
export const SET_PARTICIPANT_ASSEMBLY_DATA = 'participant/SET_PARTICIPANT_ASSEMBLY_DATA';
export const SET_ANONYMOUS_VOTING_HAS_BEEN_REQUESTED =
  'participant/SET_ANONYMOUS_VOTING_HAS_BEEN_REQUESTED';
export const SET_VOTING_DATA = 'participant/SET_VOTING_DATA';
export const SET_LOADING_PARTICIPANT = 'participant/SET_LOADING_PARTICIPANT';
export const SET_PARTICIPANT_BRANDING_DATA = 'participant/SET_PARTICIPANT_BRANDING_DATA';
export const SET_SENT_ADMIN_MESSAGE = 'participant/SET_SENT_ADMIN_MESSAGE';
export const SET_END_VOTING_DATA = 'participant/SET_END_VOTING_DATA';
export const SET_ACTIVE_AGENDA_ITEM_ID = 'participant/SET_ACTIVE_AGENDA_ITEM_ID';
export const SET_PARTICIPANT_VIEW_BALLOTS_DATA = 'participant/SET_PARTICIPANT_VIEW_BALLOTS_DATA';
export const SET_DATA_OF_VOTE_GOING_ON = 'participant/SET_DATA_OF_VOTE_GOING_ON';

export const CLEAR_STORAGE = 'common/CLEAR_STORAGE';
export const SET_LOADING = 'common/SET_LOADING';
