import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import assembliesReducer from 'Redux/reducers/assemblies';
import authReducer from 'Redux/reducers/auth';
import commonReducer from 'Redux/reducers/common';
import participantReducer from 'Redux/reducers/participant';
import assemblyPreviewPageReducer from 'Redux/reducers/assemblyPreviewPage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import newAssemblyReducer from 'Redux/reducers/newAssembly';
import filesReducer from 'Redux/reducers/files';
import plannedAssemblyReducer from 'Redux/reducers/plannedAssembly';
import liveAssemblyReducer from 'Redux/reducers/liveAssembly';
import followUpAndDoneAssemblyReducer from 'Redux/reducers/followUpAndDoneAssembly';
import myAccountReducer from 'Redux/reducers/myAccount';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['participant'],
  whitelist: [
    'userDataStore',
    'plannedAssembly',
    'assembliesData',
    'liveAssembly',
    'filesData',
    'newAssemblyData'
  ],
  stateReconciler: autoMergeLevel2
};

const userDataPersistConfig = {
  key: 'userDataStore',
  storage,
  blacklist: ['loginError', 'participantData'],
  whitelist: ['userData']
};

const participantPersistConfig = {
  key: 'participant',
  storage: sessionStorage,
  blacklist: [],
  whitelist: [
    'isVoted',
    'participantAssemblyData',
    'agendaItemsData',
    'participantViewBrandingData',
    'participantVoteData',
    'assemblyIds',
    'votingData',
    'commonData',
    'ballots'
  ]
};

const assembliesPersistConfig = {
  key: 'assembliesData',
  storage,
  blacklist: ['assemblies'],
  whitelist: ['assemblyData', 'joinedParticipants']
};

const myAccountPersistConfig = {
  key: 'myAccountData',
  storage,
  whitelist: ['myAccountData', 'logoURL']
};

const liveAssemblyPersistConfig = {
  key: 'liveAssembly',
  storage,
  blacklist: [],
  whitelist: [
    'liveAssemblyStartDate',
    'liveAssemblyData',
    'agendaItemsData',
    'isVoteGoingOn',
    'activeBallotId',
    'ballots'
  ]
};

const plannedAssemblyPersistConfig = {
  key: 'plannedAssembly',
  storage,
  blacklist: [],
  whitelist: [
    'editingAssemblyData',
    'newBatchesList',
    'agendaItemsData',
    'shares',
    'invitedEmailsList',
    'batchIdWaitingToSend',
    'lastUpdatedVoteData',
    'openSharesPopup',
    'ballots'
  ]
};

const newAssemblyPersistConfig = {
  key: 'newAssemblyData',
  storage,
  blacklist: ['assemblyUnsavedChanges', 'nextAssemblySetupStep'],
  whitelist: [
    'newAssemblyData',
    'setupAssemblyStep',
    'setupAssemblySubStep',
    'assemblyId',
    'isDuplicatedAssembly',
    'assemblyTimezone',
    'firstAgendaItem',
    'firstAgendaItemBallot',
    'assemblyDateAndTimeData',
    'isAddNewAgendaItem',
    'assemblySetupProgress',
    'invitationEmailUploadedFiles'
  ]
};

const filesPersistConfig = {
  key: 'filesData',
  storage,
  blacklist: [],
  whitelist: ['uploadedFiles', 'fileUploadError']
};

const commonPersistConfig = {
  key: 'commonData',
  storage,
  blacklist: ['loading'],
  whitelist: ['assemblyStatusFilterValue', 'isDontShowBatchesEmailsActionConfirmationPopup']
};

const rootReducer = combineReducers({
  authStore: persistReducer(userDataPersistConfig, authReducer),
  assembliesStore: persistReducer(assembliesPersistConfig, assembliesReducer),
  filesStore: persistReducer(filesPersistConfig, filesReducer),
  newAssemblyStore: persistReducer(newAssemblyPersistConfig, newAssemblyReducer),
  liveAssemblyStore: persistReducer(liveAssemblyPersistConfig, liveAssemblyReducer),
  myAccountStore: persistReducer(myAccountPersistConfig, myAccountReducer),
  plannedAssemblyStore: persistReducer(plannedAssemblyPersistConfig, plannedAssemblyReducer),
  assemblyPreviewPageStore: assemblyPreviewPageReducer,
  followUpAndDoneAssemblyStore: followUpAndDoneAssemblyReducer,
  participantStore: persistReducer(participantPersistConfig, participantReducer),
  commonStore: persistReducer(commonPersistConfig, commonReducer)
});

export default persistReducer(rootPersistConfig, rootReducer);
