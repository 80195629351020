import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade/Fade';
import { PageSpinner } from 'components/Spinner/Spinner';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { loadingSelector } from 'Redux/selectors/common';
import { createStructuredSelector } from 'reselect';
import { StepHeader } from 'AdminView/AddNewAssemblyView/components/StepsComponents/StepHeader';
import { getAccountData } from 'Redux/actions/myAccount';
import DetailsBlock from 'MyAccountView/componetns/DetailsBlock/Details';
import BrandingBlock from 'MyAccountView/componetns/BrandingBlock/Branding';
import SystemDefaultsBlock from 'MyAccountView/componetns/SystemDefaultsBlock/SystemDefaultsBlock';
// import TwoFactorAuthBlock from 'MyAccountView/componetns/TwoFactorAuthBlock/TwoFactorAuthBlock';
// import DeleteAccount from './componetns/DeleteAccountBlock/DeleteAccount';
import {
  MyAccountBodyWrapper,
  MyAccountContentWrapper,
  MyAccountViewWrapper
} from './styledComponents';
import { useTranslation } from 'react-i18next';

const MyAccountView = ({ isLoading, actionGetAccountData }) => {
  const { t } = useTranslation();

  useEffect(() => {
    actionGetAccountData();
  }, []);

  return (
    <MyAccountViewWrapper>
      {isLoading && (
        <Fade in={isLoading}>
          <PageSpinner />
        </Fade>
      )}
      <MyAccountContentWrapper>
        <StepHeader title={t('titles.myAccount')} hiddenBackButton={1} />
        <MyAccountBodyWrapper>
          <DetailsBlock t={t} />
          {/*<TwoFactorAuthBlock t={t} />*/}
          <Divider orientation="horizontal" />
          <SystemDefaultsBlock t={t} />
          <Divider orientation="horizontal" />
          <BrandingBlock t={t} />
          {/*<DeleteAccount t={t}/>*/}
        </MyAccountBodyWrapper>
      </MyAccountContentWrapper>
    </MyAccountViewWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: loadingSelector()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionGetAccountData: getAccountData
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(MyAccountView);
