import Typography from '@material-ui/core/Typography';
import { MY_ACCOUNT_DETAILS_FIELDS_NAMES } from 'constants/myAccount';
import { CustomButton } from 'components/Buttons/CustomButton';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { myAccountDataSelector } from 'Redux/selectors/myAccount';
import { createStructuredSelector } from 'reselect';
import { CustomizedModal } from 'components/ModalComponent/ModalComponent';
import { useUpdateMyAccount } from 'hooks/useUpdateMyAccountData';
import { DialogPopUp } from 'components/DialogPopUp/DialogPopUp';
import { ERROR_CODES_FIELDS } from 'constants/common';
import { MyAccountBlockWrapper, MyAccountDataItemRow } from 'MyAccountView/styledComponents';
import { MyAccountFieldItem } from '../MyAccountFieldItem';
import ChangePasswordForm from './ChangePasswordForm';
import { ContentItemWrapper } from './styledComponents';

const PASSWORD_FAKE_STRING = new Array(14).join('\u2022');

const DetailsBlock = ({ myAccountData, t }) => {
  const [editingFields, setEditingFields] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [fieldsData, setFieldsData] = useState(null);
  const [openChangePasswordPopup, setOpenChangePasswordPopup] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const { updateAccount } = useUpdateMyAccount();

  useEffect(() => {
    const { email, userName, password } = myAccountData || {};
    const detailsData = {
      email,
      userName,
      password
    };
    setFieldsData(detailsData);
  }, [myAccountData]);

  const changeEditingFields = (id) => {
    const elementIndex = editingFields.indexOf(id);
    if (elementIndex > -1) {
      if (requestError) {
        setRequestError(null);
      }
      const newArray = [...editingFields];
      newArray.splice(elementIndex, 1);
      setEditingFields(newArray);
    } else {
      setEditingFields([...editingFields, id]);
    }
  };

  const onUpdateAccountData = async (data, editingFiledId) => {
    if (requestError) {
      setRequestError(null);
    }
    const updatedAccountData = { ...myAccountData, [editingFiledId]: data };
    const result = await updateAccount(updatedAccountData);
    if (result?.errorCode) {
      const fieldWithError = ERROR_CODES_FIELDS[result.errorCode];
      setRequestError({
        [fieldWithError]: t([`requestsErrors.${result.errorCode}`, 'requestsErrors.ANOTHER_ERROR'])
      });
    } else {
      changeEditingFields(editingFiledId);
    }
  };

  const closePasswordDialog = () => {
    setOpenConfirmationDialog(true);
    setOpenChangePasswordPopup(false);
  };

  return (
    <MyAccountBlockWrapper>
      <Typography variant="body1" className="block-title title-margin">
        {t('titles.generalSettings')}
      </Typography>
      {MY_ACCOUNT_DETAILS_FIELDS_NAMES.map(({ key, initialValue }) => (
        <MyAccountFieldItem
          key={key}
          changeEditingFields={changeEditingFields}
          editingFields={editingFields}
          fieldsData={fieldsData}
          dataName={key}
          title={t(`myAccountDetailsFields.${key}.title`)}
          requestError={requestError && requestError[key]}
          setRequestError={setRequestError}
          placeholder={t(`myAccountDetailsFields.${key}.placeHolder`)}
          onUpdateAccountData={onUpdateAccountData}
          initialValue={initialValue}
          t={t}
        />
      ))}
      <ContentItemWrapper className="details-item">
        <Typography variant="body2" className="input-label">
          {t('titles.password')}
        </Typography>
        <MyAccountDataItemRow>
          <Typography variant="body1">{PASSWORD_FAKE_STRING}</Typography>
          <CustomButton
            fullWidth
            colorType="secondary"
            name={t('buttonsNames.edit')}
            onClick={() => setOpenChangePasswordPopup(true)}
            variant="text"
          />
        </MyAccountDataItemRow>
      </ContentItemWrapper>
      {openChangePasswordPopup && (
        <CustomizedModal
          title={t('titles.changePassword')}
          handleClose={() => setOpenChangePasswordPopup(false)}
          open={openChangePasswordPopup}
          width={460}
          height={730}
        >
          <ChangePasswordForm
            t={t}
            password={fieldsData.password}
            closePasswordDialog={closePasswordDialog}
          />
        </CustomizedModal>
      )}
      <DialogPopUp
        isOpen={openConfirmationDialog}
        title={t('dialogsAndPopups.resetPasswordSuccess.title')}
        contentText={t('dialogsAndPopups.resetPasswordSuccess.content')}
        handleSubmit={() => setOpenConfirmationDialog(false)}
        submitButtonName={t('buttonsNames.ok')}
      />
    </MyAccountBlockWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  myAccountData: myAccountDataSelector()
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(DetailsBlock);
