export const BALLOT_OPTIONS_INITIAL_STATE = [
  '',
  ''
];

export const VOTE_STATUSES = {
  results: 'Results',
  restarted: 'Restarted',
  inProcess: 'Process',
  announced: 'Announced',
  completed: 'Completed',
  none: 'None'
};

export const SECONDS_IN_A_MINUTE = 60;
export const ANON_REQUEST_BALLOT_TIMER_SECONDS_DELAY = 30;
export const RESTART_VOTING_TIME_IN_SECONDS = 10;
