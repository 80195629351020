import {
  ADD_NEW_AGENDA_ITEM_FLAG,
  ASSEMBLY_SETUP_STEP_BACK,
  CLEAR_NEW_ASSEMBLY_DATA,
  NEXT_ASSEMBLY_SETUP_STEP,
  SET_NEW_ASSEMBLY_DATA,
  SET_ASSEMBLY_DATE_AND_TIME_DATA,
  SET_ASSEMBLY_ID,
  SET_ASSEMBLY_SETUP_PROGRESS,
  SET_ASSEMBLY_TIMEZONE,
  SET_ASSEMBLY_UNSAVED_CHANGES,
  SET_INVITATION_EMAIL_FILES,
  FIRST_AGENDA_ITEM,
  SET_NEW_ASSEMBLY_STEP,
  FIRST_AGENDA_ITEM_BALLOT,
  SET_NEW_ASSEMBLY_SUB_STEP,
  IS_DUPLICATED_ASSEMBLY
} from 'constants/types';

import { setLoading } from 'Redux/actions/common';
import api from '../../api';
import { encryptData } from 'utils/decryptEncryptAES';
import { updateInvitationEmailResponseDataWithAttachmentsIds } from 'utils/updateDataWithAttachmentsIds';

export const getDraftAssemblyData = (data) => (dispatch) => {
  return api.commonApi
    .getAssemblyData({ data: encryptData(data) })
    .then((res) => {
      dispatch(setAssemblyId(res.assembly.id));
      return updateInvitationEmailResponseDataWithAttachmentsIds(res);
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      dispatch(setLoading(false));
    });
};

export const addNewAssembly = (data) => (dispatch) => {
  return api.newAssembly
    .addNewAssemblyRequest(data)
    .then((res) => {
      dispatch(setAssemblyId(res.id));
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const deleteAssembly = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.newAssembly
    .deleteAssembly(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const addNewAgendaItem = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.commonAssemblies
    .addAgendaItem(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const onUpdateAgendaItem = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.commonAssemblies
    .updateAgendaItem(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      dispatch(setLoading(false));
      return error.data;
    });
};

export const addNewAgendaItemBallot = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.voting
    .addNewVotingBallot(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const onUpdateAgendaItemBallot = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.voting
    .updateVotingBallot(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      dispatch(setLoading(false));
      return error.data;
    });
};

export function setFirstAgendaItem(data) {
  return {
    type: FIRST_AGENDA_ITEM,
    payload: data
  };
}

export function setFirstAgendaItemBallot(data) {
  return {
    type: FIRST_AGENDA_ITEM_BALLOT,
    payload: data
  };
}

export function setNewAssemblyData(data) {
  return {
    type: SET_NEW_ASSEMBLY_DATA,
    payload: data
  };
}

export function setAssemblyTimezone(data) {
  return {
    type: SET_ASSEMBLY_TIMEZONE,
    payload: data
  };
}

export function setAssemblyId(data) {
  return {
    type: SET_ASSEMBLY_ID,
    payload: data
  };
}

export function setSetupNewAssemblyStep(data) {
  return {
    type: SET_NEW_ASSEMBLY_STEP,
    payload: data
  };
}

export function setSetupNewAssemblySubStep(data) {
  return {
    type: SET_NEW_ASSEMBLY_SUB_STEP,
    payload: data
  };
}

export function clearNewAssemblyData() {
  return { type: CLEAR_NEW_ASSEMBLY_DATA };
}

export function setAssemblySetupProgress(data) {
  return {
    type: SET_ASSEMBLY_SETUP_PROGRESS,
    payload: data
  };
}

export function setAssemblyDateAndTimeData(data) {
  return {
    type: SET_ASSEMBLY_DATE_AND_TIME_DATA,
    payload: data
  };
}

export function setInvitationEmailFiles(data) {
  return {
    type: SET_INVITATION_EMAIL_FILES,
    payload: data
  };
}

export function setAssemblyUnsavedChanges(data) {
  return {
    type: SET_ASSEMBLY_UNSAVED_CHANGES,
    payload: data
  };
}

export function setNextAssemblySetupStep(data) {
  return {
    type: NEXT_ASSEMBLY_SETUP_STEP,
    payload: data
  };
}

export function addNewAgendaItemFlag(data) {
  return {
    type: ADD_NEW_AGENDA_ITEM_FLAG,
    payload: data
  };
}

export function setAssemblySetupStepBackFlag(data) {
  return {
    type: ASSEMBLY_SETUP_STEP_BACK,
    payload: data
  };
}

export function setIsDuplicatedAssembly(data) {
  return {
    type: IS_DUPLICATED_ASSEMBLY,
    payload: data
  };
}
