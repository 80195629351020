import styled from 'styled-components';

export const MenuWrapper = styled.div`
  & .MuiMenuItem-root {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #ffffff;
    font-size: 16px;
    font-family: Open Sans, sans-serif;
    font-weight: normal;
    line-height: 24px;
  }
  & .MuiListItem-button:hover {
    font-weight: 700;
  }
  & .Mui-selected {
    font-weight: 700;
    position: initial;
    &:before {
      content: '';
      position: absolute;
      height: 12px;
      width: 12px;
      left: -26px;
      background-color: #ffffff;
      border-radius: 50%;
      margintop: 0;
    }
  }
  & .user-name {
    color: rgba(250, 250, 250, 0.6);
    font-weight: 400;
    max-width: 150px;
  }
`;
