import api from 'api';
import {
  SET_ASSEMBLY_PREVIEW_DATA,
  SET_ASSEMBLY_PREVIEW_AGENDA_ITEMS_DATA,
  SET_ASSEMBLY_PREVIEW_BALLOTS_DATA
} from 'constants/types';
import { encryptData } from 'utils/decryptEncryptAES';

import { setLoading } from './common';

export const getAssemblyDataPreviewPage = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.commonApi
    .getAssemblyData({ data: encryptData(data) })
    .then((res) => {
      dispatch(setPreviewPageAssemblyData(res));
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      dispatch(setLoading(false));
    });
};

export function setPreviewPageAssemblyData(data) {
  return {
    type: SET_ASSEMBLY_PREVIEW_DATA,
    payload: data
  };
}

export function setPreviewPageAgendaItemsData(data) {
  return {
    type: SET_ASSEMBLY_PREVIEW_AGENDA_ITEMS_DATA,
    payload: data
  };
}

export function setPreviewPageBallotsData(data) {
  return {
    type: SET_ASSEMBLY_PREVIEW_BALLOTS_DATA,
    payload: data
  };
}
