import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isUserAuth, userRolesSelector } from 'Redux/selectors/auth';
import { createStructuredSelector } from 'reselect';
import { userDataSelector } from 'Redux/selectors/auth';

const AdminPrivateRoute = ({
  isAuthenticated,
  component: Component,
  userRole,
  availableRoles,
  prevPath,
  assemblyStatus,
  ...rest
}) => {
  if (isAuthenticated && !availableRoles.includes(userRole)) {
    return <Redirect to={prevPath} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} assemblyStatus={assemblyStatus} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: isUserAuth(),
  userRole: userRolesSelector(),
  userData: userDataSelector()
});
export default connect(mapStateToProps)(AdminPrivateRoute);
