import {
  SET_CURRENT_USER,
  SET_LOGIN_ERROR,
  CLEAR_LOGIN_ERROR,
  USER_LOGOUT,
  PARTICIPANT_LOGOUT,
  SET_CURRENT_PARTICIPANT
} from 'constants/types';

const authReducer = (
  state = {
    userData: null,
    loginError: null,
    participantData: null
  },
  { type, payload }
) => {
  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        userData: payload
      };
    case SET_CURRENT_PARTICIPANT:
      return {
        ...state,
        participantData: payload
      };
    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: payload
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginError: null
      };
    case USER_LOGOUT:
      return {
        ...state,
        userData: null,
        loginError: null
      };
    case PARTICIPANT_LOGOUT:
      return {
        ...state,
        participantData: null
      };
    default:
      return state;
  }
};

export default authReducer;
