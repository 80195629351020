import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ContentWrapper } from '../styledComponents';

export const CommonInfoBlock = ({ translate }) => (
  <ContentWrapper>
    <Typography component="h3" variant="h3">
      {translate('titles.boardplaceAsmbly')}
    </Typography>
  </ContentWrapper>
);
