import { AssemblyStatusIcon } from 'components/AssemblyStatusIcon/AssemblyStatusIcon';
import { AGENDA_ITEM_STATUSES, ASSEMBLY_STATUSES } from 'constants/assemblies';
import {
  ADD_NEW_ASSEMBLY_STEPS_INDEXES,
  ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES,
  ASSEMBLY_MAIN_PAGE_NAME,
  STORES_NAMES
} from 'constants/common';
import { VOTE_STATUSES } from 'constants/voting';
import { format, getDate, getMonth, getYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import i18next from 'i18next';
import DoneIcon from 'img/done-icon.svg';
import FollowUpIcon from 'img/followUp-icon.svg';
import PlannedIcon from 'img/planned-icon.svg';
import React from 'react';
import { onSendInvitationsWithMakingBatch, onUpdateAssembly } from 'Redux/actions/assemblies';
import { setLoading } from 'Redux/actions/common';
import { downloadSomeFile, uploadFilesToServer } from 'Redux/actions/files';
import {
  clearNewAssemblyData,
  setAssemblySetupStepBackFlag,
  setSetupNewAssemblyStep,
  setSetupNewAssemblySubStep
} from 'Redux/actions/newAssembly';
import {
  setAlertError,
  setAssemblyEditingData,
  setBatchIdWaitingToSend,
  setNewBatches
} from 'Redux/actions/plannedAssembly';
import { store } from 'store';
import { getItemsData } from 'utils/assemblies';
import { digitValue, getValueByTimeZoneBias } from 'utils/date';
import { blobToBase64 } from 'utils/files';
import history from 'utils/history';
import { onGetAssemblyData } from '../Redux/actions/assemblies';
import {
  onSetCurrentVote,
  onSkipAgendaItem,
  onUnsetCurrentVote,
  setActiveBallotId,
  setLiveAssemblyAgendaItemsData,
  setLiveAssemblyParticipantsVotingCounting
} from '../Redux/actions/liveAssembly';

export const isAssemblyStatusLive = (status) => status === ASSEMBLY_STATUSES.live;
export const isFieldsCompleted = (dateObject) =>
  Object.values(dateObject)?.every((item) => item.toString().length > 0);

export const getTitleKeyBySubStep = (subStep) => {
  if (subStep === 0) {
    return 'addNewAssemblyDate';
  }
  if (subStep === 1) {
    return 'addNewAssemblyTime';
  }
  if (subStep === 2) {
    return 'addNewAssemblyLocation';
  }
};

export const assemblyStatusesIcon = (status) => {
  switch (status) {
    case ASSEMBLY_STATUSES.done:
      return <AssemblyStatusIcon icon={DoneIcon} isDone />;

    case ASSEMBLY_STATUSES.followup:
      return <AssemblyStatusIcon icon={FollowUpIcon} />;

    case ASSEMBLY_STATUSES.planned:
      return <AssemblyStatusIcon icon={PlannedIcon} />;

    default:
      return null;
  }
};

export const checkDuplicatedFiles = (acceptedFiles, uploadedFilesNames) => {
  const results = [];
  for (let i = 0; i < acceptedFiles.length; i++) {
    if (uploadedFilesNames.includes(acceptedFiles[i].name)) {
      results.push(acceptedFiles[i]);
    }
  }
  return results;
};

export const findDuplicatesInArray = (array) =>
  array.filter((item, index) => array.indexOf(item) !== index && item.length > 0);

export const parsingNewAssemblyDataFromResponse = (assembly) => {
  const locationsList = [...(assembly.locations || []), ...(assembly.onlineLocations || [])];
  return {
    name: assembly.name || null,
    plannedTime: {
      startTimeUtc: assembly.plannedTime.startTimeUtc || null,
      endTimeUtc: assembly.plannedTime.endTimeUtc || null
    },
    participants: assembly.participants?.length ? assembly.participants : null,
    participantsShares: assembly.participantsShares?.length? assembly.participantsShares : null,
    locations: locationsList,
    invitation: assembly.invitation
      ? {
          subject: assembly.invitation.subject,
          body: assembly.invitation.body,
          ...(assembly.invitation.attachmentsIds.length
            ? { attachmentsIds: assembly.invitation.attachmentsIds }
            : {})
        }
      : null,
    timeZoneBias: assembly.timeZoneBias,
    agendaItemsIds: assembly.agendaItemsIds || null,
    agendaHeadline: assembly.agendaHeadline || null
  };
};

export const parsingStepsDataFromResponse = (assembly) => {
  const locationsList = [...(assembly.locations || []), ...(assembly.onlineLocations || [])];
  return {
    mainSteps: {
      agendaAndBallots: !!assembly?.agendaHeadline && !!assembly?.agendaItemsIds?.length,
      invitationEmail:
        !!assembly?.invitation && assembly?.invitation.subject && assembly?.invitation.body,
      name: !!assembly?.name,
      participants: !!assembly?.participants?.length,
      timeAndLocation:
        assembly?.plannedTime?.startTimeUtc
        && !assembly?.plannedTime?.startTimeUtc.includes('0001-01-01')
        && !!assembly?.plannedTime?.endTimeUtc
        && !!locationsList.length && locationsList.every((item) => item.length)
    },
    date:
      !!assembly?.plannedTime.startTimeUtc
      && !assembly?.plannedTime.startTimeUtc.includes('0001-01-01'),
    time: !!assembly?.plannedTime.endTimeUtc,
    locations: !!locationsList.length && locationsList.every((item) => item.length),
    agendaHeadline: !!assembly?.agendaHeadline,
    agendaItems: !!assembly?.agendaItemsIds?.length
  };
};

export const parsingDateAndTimeDataFromResponse = (assembly) => {
  const timeZoneValue = getValueByTimeZoneBias(assembly.timeZoneBias?.toString());

  const getDateValueIfOnlyTimeSaved = (date) =>
    `${format(new Date(), 'yyyy-MM-dd')}T${date?.split('T')[1]}`;

  const startDateValue = assembly?.plannedTime.startTimeUtc?.includes('0001-01-01')
    ? getDateValueIfOnlyTimeSaved(assembly?.plannedTime.startTimeUtc)
    : assembly?.plannedTime.startTimeUtc;
  const endDateValue = assembly?.plannedTime.startTimeUtc?.includes('0001-01-01')
    ? getDateValueIfOnlyTimeSaved(assembly?.plannedTime.endTimeUtc)
    : assembly?.plannedTime.endTimeUtc;

  const startDate = assembly?.plannedTime.startTimeUtc
    ? utcToZonedTime(startDateValue, timeZoneValue)
    : null;
  const endDate = assembly?.plannedTime.endTimeUtc
    ? utcToZonedTime(endDateValue, timeZoneValue)
    : null;

  const noDateValue =
    !assembly.plannedTime.startTimeUtc || assembly.plannedTime.startTimeUtc.includes('0001-01-01');

  return {
    day: noDateValue ? '' : digitValue(getDate(startDate)),
    month: noDateValue ? '' : digitValue(getMonth(startDate) + 1),
    year: noDateValue ? '' : getYear(startDate),
    start: endDate ? format(startDate, 'HH.mm') : '',
    end: endDate ? format(endDate, 'HH.mm') : '',
    timezone: timeZoneValue
  };
};

export const goToAssemblyFirstEmptyStep = (assembly) => {
  const state = store.getState();

  const { setupAssemblyStep, setupAssemblySubStep, newAssemblyData } = state.newAssemblyStore;

  if (!newAssemblyData) {
    store.dispatch(setSetupNewAssemblyStep(0));
    store.dispatch(setSetupNewAssemblySubStep(0));
    return;
  }

  if (setupAssemblyStep !== null) {
    store.dispatch(setSetupNewAssemblyStep(setupAssemblyStep));
    store.dispatch(setSetupNewAssemblySubStep(setupAssemblySubStep));
    return;
  }

  const { mainSteps, date, time, locations, agendaHeadline, agendaItems } =
    parsingStepsDataFromResponse(assembly);

  const { name, participants, timeAndLocation, invitationEmail, agendaAndBallots } = mainSteps;

  const allStepsCompleted =
    name && participants && timeAndLocation && invitationEmail && agendaAndBallots;

  if (allStepsCompleted) {
    store.dispatch(setSetupNewAssemblyStep(ADD_NEW_ASSEMBLY_STEPS_INDEXES.setupAgenda));
    store.dispatch(setSetupNewAssemblySubStep(ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES.agendaFinish));
    return;
  }
  if (!name) {
    store.dispatch(setSetupNewAssemblyStep(ADD_NEW_ASSEMBLY_STEPS_INDEXES.name));
    store.dispatch(setSetupNewAssemblySubStep(0));
    return;
  }
  if (!participants) {
    store.dispatch(setSetupNewAssemblyStep(ADD_NEW_ASSEMBLY_STEPS_INDEXES.participants));
    store.dispatch(setSetupNewAssemblySubStep(0));
    return;
  }
  if (!timeAndLocation) {
    store.dispatch(setSetupNewAssemblyStep(ADD_NEW_ASSEMBLY_STEPS_INDEXES.date));
    if (!date) {
      store.dispatch(setSetupNewAssemblySubStep(ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES.date));
      return;
    }
    if (!time) {
      store.dispatch(setSetupNewAssemblySubStep(ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES.time));
      return;
    }
    if (!locations) {
      store.dispatch(setSetupNewAssemblySubStep(ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES.locations));
      return;
    }
    return;
  }
  if (!invitationEmail) {
    store.dispatch(setSetupNewAssemblyStep(ADD_NEW_ASSEMBLY_STEPS_INDEXES.invitationEmail));
    store.dispatch(setSetupNewAssemblySubStep(0));
    return;
  }
  if (!agendaAndBallots) {
    store.dispatch(setSetupNewAssemblyStep(ADD_NEW_ASSEMBLY_STEPS_INDEXES.setupAgenda));
    if (!agendaHeadline) {
      store.dispatch(setSetupNewAssemblySubStep(ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES.agendaHeadline));
      return;
    }
    if (!agendaItems) {
      store.dispatch(
        setSetupNewAssemblySubStep(ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES.agendaItemSetup)
      );
      return;
    }
    return;
  }
};

export const getManageAgendaItemButtonName = (openAgendaItemVotingData, availableBallotEditing) => {
  switch (true) {
    case !openAgendaItemVotingData && availableBallotEditing:
      return i18next.t('buttonsNames.agendaItemViewDetails');
    case openAgendaItemVotingData && availableBallotEditing:
      return i18next.t('buttonsNames.closeAgendaItemEditingMode');
    case !openAgendaItemVotingData && !availableBallotEditing:
      return i18next.t('buttonsNames.viewDetails');
    case openAgendaItemVotingData && !availableBallotEditing:
      return i18next.t('buttonsNames.closeDetails');
    default:
      return null;
  }
};

export const goToNextView = (
  nextAssemblySetupStep,
  arrowsStep,
  arrowsSubStep,
  assemblySubStep,
  isGoBackByArrow
) => {
  const state = store.getState();

  const { setupAssemblyStep, assemblySetupStepBackFlag } = state.newAssemblyStore;

  if (assemblySetupStepBackFlag) {
    store.dispatch(setAssemblySetupStepBackFlag(false));
  }
  if (nextAssemblySetupStep.toString().includes(ASSEMBLY_MAIN_PAGE_NAME) || isGoBackByArrow) {
    history.push('/dashboard');
    store.dispatch(clearNewAssemblyData());
    return;
  }
  if (nextAssemblySetupStep) {
    store.dispatch(setSetupNewAssemblyStep(+nextAssemblySetupStep));
    return;
  }
  if (arrowsStep !== null && arrowsStep > -1 && arrowsSubStep !== null && arrowsSubStep > -1) {
    store.dispatch(setSetupNewAssemblyStep(arrowsStep));
    store.dispatch(setSetupNewAssemblySubStep(arrowsSubStep));
    return;
  }
  if (arrowsStep !== null && arrowsStep > -1) {
    store.dispatch(setSetupNewAssemblyStep(arrowsStep));
    return;
  }

  if (arrowsSubStep !== null && arrowsSubStep > -1) {
    store.dispatch(setSetupNewAssemblySubStep(arrowsSubStep));
    return;
  }

  if (assemblySubStep !== null) {
    store.dispatch(setSetupNewAssemblySubStep(assemblySubStep));
  }
  if (assemblySubStep === null) {
    store.dispatch(setSetupNewAssemblyStep(setupAssemblyStep + 1));
  }
};

export const updateAssemblyInvitationAttachmentsStatus = () => {
  const { dispatch } = store;
  const state = store.getState();

  const { editingAssemblyData } = state.editAssemblyStore;

  const updatedInvitationAttachments = editingAssemblyData.invitation.attachments?.map((item) => ({
    ...item,
    hasBeenSentInAnInvitation: true
  }));

  const updateEditedAssemblyData = {
    assembly: {
      ...editingAssemblyData,
      invitation: {
        ...editingAssemblyData.invitation,
        attachments: updatedInvitationAttachments
      }
    }
  };
  dispatch(setAssemblyEditingData(updateEditedAssemblyData));
};

export const updateBatchesCounting = () => {
  const { dispatch } = store;
  const state = store.getState();

  const { editingAssemblyData, newBatchesList, batchIdWaitingToSend } = state.plannedAssemblyStore;

  if(!newBatchesList.length) {
    return;
  }

  const newBatchesCountStart = +editingAssemblyData.batches?.length + 1;
  const updatedNewBatchesData = newBatchesList
    .filter((item) => item.id !== batchIdWaitingToSend)
    .map((batch, index) => ({
      ...batch,
      id: `Batch# ${newBatchesCountStart + index}`
    }));
  dispatch(setNewBatches(updatedNewBatchesData));
  dispatch(setLoading(false));
};

export const sendInvitationsAndMakeBatch = () => {
  const { dispatch } = store;
  const state = store.getState();

  const { editingAssemblyData, newBatchesList, batchIdWaitingToSend } = state.plannedAssemblyStore;

  dispatch(setLoading(true));

  const emailsToSend = newBatchesList
    .find((item) => item.id === batchIdWaitingToSend)
    ?.recipients.map((item) => item.email);

  const sendInvitationData = {
    assemblyId: editingAssemblyData.id,
    participants: emailsToSend
  };

  const preSavedNewBatchesList = newBatchesList.filter(item => item.id !== batchIdWaitingToSend);
  const updatedAssemblyParticipants = emailsToSend?.filter(
    (item) => !editingAssemblyData.participants.includes(item)
  );

  const newAssemblyData = {
    assembly: {
      ...editingAssemblyData,
      participants: [...editingAssemblyData.participants, ...updatedAssemblyParticipants]
    }
  };

  return dispatch(onUpdateAssembly(newAssemblyData)).then(() => {
    const needToUpdateInvitationAttachments = true;
    dispatch(onGetAssemblyData({ id: editingAssemblyData.id }, needToUpdateInvitationAttachments))
      .then(response => dispatch(setAssemblyEditingData(response)));
    dispatch(setBatchIdWaitingToSend(null));
    dispatch(onSendInvitationsWithMakingBatch(sendInvitationData)).then((response) => {
      if (response.success) {
        dispatch(setNewBatches(preSavedNewBatchesList));
      } else {
        dispatch(setAlertError(
          i18next.t([`requestsErrors.${response.errorCode}`, 'requestsErrors.ANOTHER_ERROR'])
        ))
      }
    });
  });
};

export const checkEditorContent = (editorState) => {
  const content = editorState.getCurrentContent();
  const isEditorEmpty = !content.hasText();
  const currentPlainText = content.getPlainText();
  const lengthOfTrimmedContent = currentPlainText.trim().length;
  return !!(!isEditorEmpty && lengthOfTrimmedContent);
};

export const processingFiles = async (files, savedFiles, filesHasBeenReplaced) => {
  const { dispatch } = store;

  const filteredSavedFiles = savedFiles?.filter((fileItem) => fileItem.id);

  let uploadedIds =
    savedFiles?.length && filteredSavedFiles?.length ? filteredSavedFiles.map((item) => item.id) : [];

  let preSavedFiles = savedFiles?.length ? savedFiles?.filter((fileItem) => fileItem.id) : [];

  if (filesHasBeenReplaced) {
    const newFilesNames = files.map((file) => file.name);

    uploadedIds = savedFiles
      .filter((attachment) => !newFilesNames.includes(attachment.name))
      .map((attachment) => attachment.id);

    preSavedFiles = preSavedFiles.filter((attachment) => !newFilesNames.includes(attachment.name));
  }

  if (files.length) {
    dispatch(setLoading(true));
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i].file, files[i].name);

      const res = await dispatch(uploadFilesToServer(formData));
      if (res.isError) {
        break;
      } else {
        uploadedIds.push(res.id);
        preSavedFiles.push({
          id: res.id,
          url: URL.createObjectURL(files[i].file),
          name: files[i].name
        });
      }
    }
  }

  return { uploadedIds, preSavedFiles };
};

export const calculateParticipantsVotingData = (newVotingParticipantsData) => {
  const { dispatch } = store;
  const state = store.getState();

  const { votingParticipantsCounting } = state.liveAssemblyStore;

  if (votingParticipantsCounting === null) {
    dispatch(setLiveAssemblyParticipantsVotingCounting(newVotingParticipantsData));
    return;
  }

  const prevValues = votingParticipantsCounting.alreadyVoted;
  const newValues = newVotingParticipantsData.alreadyVoted;

  if (prevValues !== newValues) {
    dispatch(setLiveAssemblyParticipantsVotingCounting(newVotingParticipantsData));
  };

};

export const controlAgendaItemsAndBallot = () => {

  const { dispatch } = store;

  const getLiveAssemblyStore = () => store.getState().liveAssemblyStore;

  const setCurrentVoteToAgendaItem = (agendaItemId, nextBallotId) => {
    dispatch(
      onSetCurrentVote({
        agendaItemId,
        voteId: nextBallotId
      })).then(() => {
      dispatch(setActiveBallotId(nextBallotId));
      dispatch(setLoading(false));
    });
  };

  const changeActiveBallotId = (agendaItemId, currentBallotId) => {

    const ballotsData = getLiveAssemblyStore().ballots;

    const availableBallotsToSwitch = ballotsData[agendaItemId].filter((item) =>
      item.status === VOTE_STATUSES.none || item.id === currentBallotId
    ).map((item) => item.id);

    if(availableBallotsToSwitch.length <= 1) {
      dispatch(
        onUnsetCurrentVote({
          agendaItemId
        })).then(() => {
        dispatch(setActiveBallotId(null));
        dispatch(setLoading(false));
      });
      return;
    }

    const indexOfActiveBallotItem = availableBallotsToSwitch.indexOf(currentBallotId);
    const nextBallotId =
      indexOfActiveBallotItem === availableBallotsToSwitch.length - 1
        ? availableBallotsToSwitch[0]
        : availableBallotsToSwitch[indexOfActiveBallotItem + 1];

    setCurrentVoteToAgendaItem(agendaItemId, nextBallotId);
  };

  const updateAgendaItemsData = () => {

    const agendaItemsIds = getLiveAssemblyStore().liveAssemblyData.agendaItemsIds;

    dispatch(setActiveBallotId(null));
    getItemsData(agendaItemsIds, STORES_NAMES.liveAssembly).then((response) => {
      dispatch(setLiveAssemblyAgendaItemsData(response));
      dispatch(setLoading(false));
    });
  };

  const changeActiveAgendaItem = (nextAgendaItemId) => {
    const agendaItemsData = getLiveAssemblyStore()?.agendaItemsData;
    dispatch(setLoading(true));
    const currentAgendaItem = agendaItemsData.find(item =>
      item.discussionStatus === AGENDA_ITEM_STATUSES.Current);
    const data = {
      assemblyId: getLiveAssemblyStore().liveAssemblyData.id,
      currentAgendaItemId: currentAgendaItem ? currentAgendaItem.id : null,
      agendaItemId: nextAgendaItemId
    };
    dispatch(onSkipAgendaItem(data)).then(() => updateAgendaItemsData());
  };

  return {
    setCurrentVoteToAgendaItem,
    changeActiveBallotId,
    changeActiveAgendaItem,
    updateAgendaItemsData
  }
};

export const parseLocations = (locations) => {
  const realLocations = [];
  const onlineLocations = [];

  locations.forEach((item) => {
    if(item.includes('http')) {
      onlineLocations.push(item)
    } else {
      realLocations.push(item)
    }
  });

  return {
    locations: realLocations,
    onlineLocations: onlineLocations
  }
};

export const getLocationsKey = (isVirtualLocation) => isVirtualLocation
  ? 'onlineLocations'
  : 'locations';

export const getNewBatchIndex = () => {
  const state = store.getState();

  const { editingAssemblyData, newBatchesList } = state.plannedAssemblyStore;
  return (editingAssemblyData?.batches?.length || 0) + newBatchesList.length + 1;
};

export const checkIsOptionsFilled = (options) => (
  options?.every((item) => item.trim().length > 0)
);

export const checkIsAvailableToSaveData = (agendaTitle, isIncludeBallot, options) => {
  if(!isIncludeBallot) {
    return !!agendaTitle?.trim().length
  } else {
    return !!agendaTitle?.trim().length && options?.every((item) => item.trim().length > 0)
  }
};

export const parseBrandingData = async (brandingData) => {

  const { dispatch } = store;
  if (!brandingData) {
    return null;
  }
  const { companyName, companyAddress, businessId, backgroundColour, logoId, textColour } =
    brandingData;

  const data = {
    companyName: companyName,
    companyAddress: companyAddress,
    businessId: businessId,
    backgroundColour: backgroundColour,
    textColour: textColour
  };

  const logoURL = logoId ? await dispatch(downloadSomeFile({ fileId: logoId })).then((fileResponse) =>
      blobToBase64(fileResponse).then(response => response))
    : null;

    return {
      ...data,
      logoURL
    }
};
