import { ADMINS_ROLES_TYPES } from 'constants/common';
import { GeneralLayout } from 'GeneralLayout/GeneralLayout';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Router, Switch } from 'react-router-dom';
import { isUserAuth, userDataSelector, userRolesSelector } from 'Redux/selectors/auth';
import { isParticipantVotedSelector } from 'Redux/selectors/participant';
import { createStructuredSelector } from 'reselect';
import ParticipantPrivateRote from 'routers/ParticipantPrivateRote';
import PrivateRoute from 'routers/AdminPrivateRoute';
import PublicRoute from 'routers/PublicRoute';
import history from 'utils/history';
import { PageSpinner } from './components/Spinner/Spinner';
import MyAccountView from './MyAccountView/MyAccountView';
import { ASSEMBLY_STATUSES_NAMES } from 'constants/assemblies';
import { usePrevious } from './hooks/usePrevious';
import getUnixTime from 'date-fns/getUnixTime';

const AuthenticationView = React.lazy(() => import('Authentication/AuthenticationView'));
const ChangeSetupPasswordForm = React.lazy(() =>
  import('Authentication/components/ChangeSetupPasswordForm')
);
const AssembliesListView = React.lazy(() =>
  import('AdminView/AssembliesListView/AssembliesListView')
);
const PlannedAssemblyView = React.lazy(() =>
  import('AdminView/PlannedAssemblyView/PlannedAssemblyView')
);
const ParticipantPagePreview = React.lazy(() =>
  import('AdminView/ParticipantPreviews/ParticipantPagePreview')
);
const VersionedAgendaView = React.lazy(() =>
  import('AdminView/ParticipantPreviews/VersionsPreviews/AgendaView')
);
const VersionedEmailInvitationView = React.lazy(() =>
  import('AdminView/ParticipantPreviews/VersionsPreviews/EmailInvitationView')
);
const LiveAssembly = React.lazy(() => import('AdminView/LiveAssembly/LiveAssembly'));
const FollowUpAssemblyView = React.lazy(() =>
  import('AdminView/FollowUpAndDoneAssembliesViews/FollowUpAssemblyView')
);
const DoneAssemblyView = React.lazy(() =>
  import('AdminView/FollowUpAndDoneAssembliesViews/DoneAssemblyView')
);
const AddNewAssemblyView = React.lazy(() =>
  import('AdminView/AddNewAssemblyView/AddNewAssemblyView')
);
const ParticipantAccess = React.lazy(() => import('ParticipantView/ParticipantAccess'));
const ParticipantView = React.lazy(() => import('ParticipantView/ParticipantAssemblyView'));
const ParticipantBallotView = React.lazy(() => import('ParticipantView/ParticipantBallotView'));
const InvitationAttachmentDownload = React.lazy(() =>
  import('ParticipantView/InvitationAttachmentDownload')
);
const ChooseAnonymousVotingView = React.lazy(() =>
  import('ParticipantView/ChooseAnonymousVotingView')
);
const ReopenBallotAwaitingView = React.lazy(() =>
  import('ParticipantView/ReopenBallotAwaitingView')
);

const App = ({ userRole, userData, isAuthenticated }) => {
  const [prevRoute, setPrevRoute] = useState({ from: history.location.pathname });

  const prevRouteData = usePrevious(prevRoute);

  useEffect(() => {
    history.listen((location) => {
      setPrevRoute({ from: location.pathname });
    });
  }, [history]);

  useEffect(() => {
    if (
      isAuthenticated
      && ADMINS_ROLES_TYPES.includes(userRole)
      && history.location.pathname.includes('dashboard')
      && !history.location.pathname.includes('/dashboard/assembly-preview')
      && !history.location.pathname.includes('/dashboard/agenda-preview')
      && !history.location.pathname.includes('/dashboard/invitation-preview')
    ) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'p8coxldp',
        name: userData?.userName,
        email: userData?.email,
        created_at: getUnixTime(new Date(userData?.responseCreationTimeUtc))
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    return () => {
      if (isAuthenticated && ADMINS_ROLES_TYPES.includes(userRole)) {
        window.Intercom('shutdown');
      }
    };
  }, []);

  return (
    <Router history={history}>
      <GeneralLayout
        userRole={userRole}
        isParticipantView={history.location.pathname.includes('/participant-login')}
      >
        <Suspense fallback={<PageSpinner/>}>
          <Switch>
            <Route path="/reset-password" component={() => <ChangeSetupPasswordForm/>}/>
            <Route
              path="/setup-new-password"
              component={() => <ChangeSetupPasswordForm isFirstLogin={true}/>}
            />
            <PublicRoute path={'/'} exact component={() => <AuthenticationView/>}/>
            <PublicRoute path={'/participant-login'} component={() => <AuthenticationView/>}/>
            <PrivateRoute
              exact
              path="/dashboard"
              component={AssembliesListView}
              prevPath={prevRouteData?.from}
              userRole={userRole}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/my-account"
              component={MyAccountView}
              prevPath={prevRouteData?.from}
              userRole={userRole}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path={[
                '/dashboard/add-new-assembly',
                '/dashboard/edit-draft/:assemblyId'
              ]}
              component={AddNewAssemblyView}
              userRole={userRole}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/edit-assembly/:assemblyId"
              component={PlannedAssemblyView}
              userRole={userRole}
              assemblyStatus={ASSEMBLY_STATUSES_NAMES.Planned}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/live-assembly/:assemblyId"
              component={LiveAssembly}
              userRole={userRole}
              assemblyStatus={ASSEMBLY_STATUSES_NAMES.Live}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/follow-up-assembly/:assemblyId"
              component={FollowUpAssemblyView}
              userRole={userRole}
              assemblyStatus={ASSEMBLY_STATUSES_NAMES.FollowUp}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/done-assembly/:assemblyId"
              component={DoneAssemblyView}
              userRole={userRole}
              assemblyStatus={ASSEMBLY_STATUSES_NAMES.Done}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/assembly-preview/:assemblyId"
              component={ParticipantPagePreview}
              userRole={userRole}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/agenda-preview/:versionData"
              component={VersionedAgendaView}
              userRole={userRole}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <PrivateRoute
              exact
              path="/dashboard/invitation-preview/:currentVersion"
              component={VersionedEmailInvitationView}
              userRole={userRole}
              prevPath={prevRouteData?.from}
              availableRoles={ADMINS_ROLES_TYPES}
            />
            <ParticipantPrivateRote
              exact
              withAgendaTitle={1}
              path="/assembly/:assemblyId"
              component={ParticipantView}
            />
            <ParticipantPrivateRote
              exact
              path="/assembly/request-anon-ballot/:voteId"
              component={ChooseAnonymousVotingView}
            />
            <ParticipantPrivateRote
              exact
              path="/assembly/awaiting-restart-ballot/:restartData"
              component={ReopenBallotAwaitingView}
            />
            <ParticipantPrivateRote
              exact
              withAgendaTitle={1}
              path="/assembly/voting/:voteId"
              component={ParticipantBallotView}
            />
            <Route exact path="/access/:assemblyId" component={() => <ParticipantAccess/>}/>
            <Route
              path="/download/attachment/invitation"
              component={() => <InvitationAttachmentDownload/>}
            />
          </Switch>
        </Suspense>
      </GeneralLayout>
    </Router>
  );
};

const mapStateToProps = createStructuredSelector({
  userRole: userRolesSelector(),
  userData: userDataSelector(),
  isAuthenticated: isUserAuth(),
  isParticipantVoted: isParticipantVotedSelector()
});

export default connect(mapStateToProps)(App);
