import React from 'react';
import { ContentWrapper } from '../styledComponents';
import AdminViewLeftMenu from './Navigation/AdminViewLeftMenu';
import { withRouter } from 'react-router';

const LeftNavigation = () => (
  <ContentWrapper>
    <AdminViewLeftMenu />
  </ContentWrapper>
);

export default withRouter(LeftNavigation);
