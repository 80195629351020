import {
  ASSEMBLY_SETUP_STEP_BACK,
  SET_NEW_ASSEMBLY_STEP,
  SET_NEW_ASSEMBLY_DATA,
  SET_ASSEMBLY_ID,
  SET_ASSEMBLY_TIMEZONE,
  CLEAR_NEW_ASSEMBLY_DATA,
  FIRST_AGENDA_ITEM,
  SET_ASSEMBLY_SETUP_PROGRESS,
  SET_ASSEMBLY_DATE_AND_TIME_DATA,
  SET_INVITATION_EMAIL_FILES,
  SET_NEW_ASSEMBLY_SUB_STEP,
  SET_ASSEMBLY_UNSAVED_CHANGES,
  NEXT_ASSEMBLY_SETUP_STEP,
  ADD_NEW_AGENDA_ITEM_FLAG,
  FIRST_AGENDA_ITEM_BALLOT,
  IS_DUPLICATED_ASSEMBLY,
  CLEAR_STORAGE
} from 'constants/types';

const initialState = {
  assemblySetupStepBackFlag: false,
  assemblySetupStepUnsavedChangesFlag: false,
  newAssemblyData: null,
  setupAssemblyStep: null,
  setupAssemblySubStep: null,
  assemblyId: null,
  assemblyTimezone: '',
  firstAgendaItem: null,
  firstAgendaItemBallot: null,
  assemblyDateAndTimeData: null,
  assemblyUnsavedChanges: false,
  nextAssemblySetupStep: false,
  isAddNewAgendaItem: false,
  isDuplicatedAssembly: false,
  assemblySetupProgress: {
    mainSteps: {
      name: false,
      participants: false,
      timeAndLocation: false,
      invitationEmail: false,
      agendaAndBallots: false
    },
    date: false,
    time: false,
    locations: false,
    agendaHeadline: false,
    agendaItems: false
  }
};

const newAssemblyReducer = (
  state = {
    ...initialState
  },
  { type, payload }
) => {
  switch (type) {
    case SET_NEW_ASSEMBLY_DATA:
      return {
        ...state,
        newAssemblyData: state.newAssemblyData ? { ...state.newAssemblyData, ...payload } : payload
      };
    case SET_NEW_ASSEMBLY_STEP:
      return {
        ...state,
        setupAssemblyStep: payload,
        ...(state.setupAssemblySubStep ? { setupAssemblySubStep: 0 } : {})
      };
    case SET_NEW_ASSEMBLY_SUB_STEP:
      return {
        ...state,
        setupAssemblySubStep: payload
      };
    case SET_ASSEMBLY_ID:
      return {
        ...state,
        assemblyId: payload
      };
    case SET_ASSEMBLY_TIMEZONE:
      return {
        ...state,
        assemblyTimezone: payload
      };
    case FIRST_AGENDA_ITEM:
      return {
        ...state,
        firstAgendaItem: payload
      };
    case FIRST_AGENDA_ITEM_BALLOT:
      return {
        ...state,
        firstAgendaItemBallot: payload
      };
    case SET_ASSEMBLY_SETUP_PROGRESS:
      return {
        ...state,
        assemblySetupProgress: payload
      };
    case SET_ASSEMBLY_DATE_AND_TIME_DATA:
      return {
        ...state,
        assemblyDateAndTimeData: payload
      };
    case SET_INVITATION_EMAIL_FILES:
      return {
        ...state,
        invitationEmailUploadedFiles: payload
      };
    case SET_ASSEMBLY_UNSAVED_CHANGES:
      return {
        ...state,
        assemblyUnsavedChanges: payload
      };
    case NEXT_ASSEMBLY_SETUP_STEP:
      return {
        ...state,
        nextAssemblySetupStep: payload
      };
    case ADD_NEW_AGENDA_ITEM_FLAG:
      return {
        ...state,
        isAddNewAgendaItem: payload
      };
    case ASSEMBLY_SETUP_STEP_BACK:
      return {
        ...state,
        assemblySetupStepBackFlag: payload
      };
    case IS_DUPLICATED_ASSEMBLY:
      return {
        ...state,
        isDuplicatedAssembly: payload
      };
    case CLEAR_NEW_ASSEMBLY_DATA:
      return initialState;
    case CLEAR_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default newAssemblyReducer;
