import styled from 'styled-components';

export const ContentItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 10px;
  &.details-item .fields-form-wrapper {
    width: 100%;
  }
  & .MuiTypography-body2 {
    margin-bottom: 2px;
    margin-left: 1px;
  }
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  & label {
    margin-left: 15px;
    margin-bottom: 5px;
  }
`;

export const ChangePasswordFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  margin: 0 auto;
  & .MuiTypography-h3 {
    line-height: 32px;
  }
  & .submitButton {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  & hr {
    margin-bottom: 15px;
  }
  & .strong-password-helper {
    display: none;
  }
  & .error-hint {
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin-left: 0;
  }
`;
