import api from '../api';

export const addNewVotingBallot = (data) => api.post('/api/Vote/Add', data).then((res) => res.data);
export const updateVotingBallot = (data) =>
  api.post('/api/Vote/Update', data).then((res) => res.data);
export const announceBallotVoting = (data) =>
  api.post('/api/Vote/Announce', data).then((res) => res.data);
export const startVote = (data) => api.post('/api/Vote/Start', data).then((res) => res.data);
export const restartVote = (data) => api.post('/api/Vote/Restart', data).then((res) => res.data);
export const reopenAgendaItem = (data) =>
  api.post('/api/Assembly/Session/ReopenItem', data).then((res) => res.data);
export const skipAgendaItem = (data) =>
  api.post('/api/Assembly/Session/SkipItem', data).then((res) => res.data);
export const requestSecretVote = (data) =>
  api.post('/api/Vote/RequestSecretVote', data).then((res) => res.data);
export const castVote = (data) => api.post('/api/Vote/Cast', data).then((res) => res.data);
export const finishBallotVoting = (data) =>
  api.post('/api/Vote/Finish', data).then((res) => res.data);
export const announceBallotResults = (data) =>
  api.post('/api/Vote/Results/Announce', data).then((res) => res.data);

export const getBallotData = (data) => api.get('/api/Vote/Get', data).then((res) => res.data);
export const getBallotDataWithResults = (data) =>
  api.get('/api/Vote/GetInfoWithResults', data).then((res) => res.data);
export const userHasBeenVoted = (data) =>
  api.get('/api/Vote/HasUserCast', data).then((res) => res.data);
export const setCurrentVote = (data) =>
  api.post('/api/AgendaItem/SetCurrentVote', data).then((res) => res.data);
export const unsetCurrentVote = (data) =>
  api.post('/api/AgendaItem/UnsetCurrentVote', data).then((res) => res.data);
