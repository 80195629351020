import * as auth from 'api/auth';
import * as commonAssemblies from 'api/commonAssemblies';
import * as participant from 'api/participant';
import * as commonApi from 'api/commonApi';
import * as myAccount from 'api/myAccount';
import * as newAssembly from 'api/newAssembly';
import * as files from 'api/files';
import * as voting from 'api/voting';

export default {
  auth,
  commonAssemblies,
  commonApi,
  files,
  myAccount,
  newAssembly,
  participant,
  voting
};
