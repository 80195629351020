import Typography from '@material-ui/core/Typography';
import React from 'react';
import { GuidelinesWrapper, StyledList } from './styledComponents';
import { useTranslation } from 'react-i18next';

const GUIDELINES = [
  { key: 'moreThan' },
  { key: 'hasNumber' },
  { key: 'hasLowercaseSymbol' },
  { key: 'hasUppercaseSymbol' },
  { key: 'hasSpecialSymbol' }
];

export const StrongPasswordGuidelines = () => {
  const { t } = useTranslation();

  return (
    <GuidelinesWrapper>
      <Typography variant="body2">{t('titles.strongPasswordGuidelines')}</Typography>
      <StyledList data-header="guidelines">
        {GUIDELINES.map(({ key }) => (
          <li key={key}>
            <Typography variant="body2">{t(`guidelines.${key}`)}</Typography>
          </li>
        ))}
      </StyledList>
      <Typography paragraph variant="body2" color="textPrimary" className="strong-password-helper">
        {t('descriptions.setupNewPasswordHelper')}
      </Typography>
    </GuidelinesWrapper>
  );
};
