import styled from 'styled-components';
import { COLORS } from 'constants/common';

export const MyAccountBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 54px;
  /* margin-left: 40px; */
  box-sizing: border-box;
  /* margin-right: 150px; */
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .fields-form-wrapper {
    width: 100%;
  }
`;

export const MyAccountViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${COLORS.lightGray};
  & form.fields-form-wrapper-loc {
    width: auto;
  }
`;
export const MyAccountContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${COLORS.lightGray};
  padding-left: 55px;
  padding-right: 100px;
  overflow: hidden;
  & hr {
    margin: 24px 0;
  }
`;

export const MyAccountBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & .title-margin {
    margin-bottom: 16px;
  }
  & .MuiTypography-body1.block-title {
    font-weight: 600;
  }
  & .MuiTypography-body1 {
    color: #000000;
  }
  & .MuiTypography-body2 {
    font-weight: 400;
    color: #000000;
  }
  & .MuiTypography-body2.input-label {
    font-weight: 600;
    margin-bottom: 8px;
    margin-left: 1px;
    color: ${COLORS.gray};
  }
  & .MuiButton-root.MuiButton-textSecondary {
    min-width: auto;
    padding: 6px 0;
    justify-content: end;
    margin-left: 15px;
  }
`;

export const MyAccountInputComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  ${(props) => props.error && 'margin-bottom: 20px'};
  & .MuiButton-root.MuiButton-textSecondary {
    margin-left: 15px;
  }
  & .error-hint {
    position: absolute;
    bottom: -40px;
    font-size: 16px;
  }
  &.new-location-input {
    margin-top: 16px;
  }
`;

export const MyAccountDataItemRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-transform: ${(props) => (props.upperCase ? 'uppercase' : 'none')};
  & .MuiButton-textSecondary {
    height: fit-content;
  }
  & .delete-button {
    width: inherit;
    margin: auto;
    color: ${COLORS.error};
  }
  & .MuiTypography-body1 {
    width: calc(100% - 80px);
    word-wrap: break-word;
    opacity: ${(props) => props.emptyItem && 0.6};
  }
`;

export const MyAccountSelectorRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  &.with-bottom-margin {
    margin-bottom: 20px;
  }
  & > .MuiTypography-body1 {
    cursor: pointer;
  }
`;

export const FieldItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 28px;
  &.details-item .fields-form-wrapper {
    width: 100%;
  }
`;
