import api from 'api';
import {
  SET_CURRENT_USER,
  SET_LOGIN_ERROR,
  CLEAR_LOGIN_ERROR,
  USER_LOGOUT,
  CLEAR_STORAGE,
  SET_CURRENT_PARTICIPANT
} from 'constants/types';

export const checkUserTFA = (loginData) => (dispatch) => {
  return api.auth
    .checkTFA(loginData)
    .then((res) => res)
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error.data?.errorCode });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const sendSMSVerificationCode = (data) => (dispatch) => {
  return api.auth
    .smsVerification(data)
    .then((res) => res)
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error.data?.errorCode });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const userLogin = (user) => (dispatch) => {
  return api.auth
    .login(user)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error.data?.errorCode });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const participantLogin = (user) => (dispatch) => {
  return api.auth
    .loginParticipant(user)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error.data?.errorCode });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const checkLinkAvailability = (data) => (dispatch) => {
  return api.auth
    .checkIsLinkAvailable(data)
    .then((res) => res)
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error.data?.errorCode });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const resetPasswordSendLink = (data) => (dispatch) => {
  return api.auth
    .resetPasswordSendResetLink(data)
    .then((res) => res)
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error.data?.errorCode });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const updateUserPassword = (data) => (dispatch) => {
  return api.auth
    .updatePassword(data)
    .then((res) => res)
    .catch((error) => {
      dispatch({ type: SET_LOGIN_ERROR, payload: error.data?.errorCode });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const userLogout = (data) => (dispatch) => {
  return api.auth
    .logout(data)
    .then((res) => {
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: CLEAR_STORAGE });
      return res;
    })
    .catch((error) => {
      dispatch({ type: USER_LOGOUT });
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const setCurrentUserData = (data) => {
  return { type: SET_CURRENT_USER, payload: data };
};

export const clearLoginError = () => (dispatch) => {
  dispatch({ type: CLEAR_LOGIN_ERROR });
};

export const setCurrentParticipantData = (data) => {
  return { type: SET_CURRENT_PARTICIPANT, payload: data };
};

// export const showSpinner = () => (dispatch) => {
//   dispatch({ type: SHOW_WINDOW_SPINNER });
// };
//
// export const hideSpinner = () => (dispatch) => {
//   dispatch({ type: HIDE_WINDOW_SPINNER });
// };
