import Typography from '@material-ui/core/Typography';
import { MY_ACCOUNT_BRANDING_FIELDS_NAMES } from 'constants/myAccount';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { myAccountDataSelector } from 'Redux/selectors/myAccount';
import { createStructuredSelector } from 'reselect';
import { useUpdateMyAccount } from 'hooks/useUpdateMyAccountData';
import { ERROR_CODES_FIELDS } from 'constants/common';
import { MyAccountBlockWrapper } from 'MyAccountView/styledComponents';
import { MyAccountFieldItem } from '../MyAccountFieldItem';
import { UploadLogoBlock } from '../UploadLogoBlock/UploadLogo';

const BrandingBlock = ({ myAccountData, t }) => {
  const [editingFields, setEditingFields] = useState([]);
  const [fieldsData, setFieldsData] = useState(null);
  const [requestError, setRequestError] = useState(null);

  const { updateAccount } = useUpdateMyAccount();

  useEffect(() => {
    const { businessId, companyName, companyAddress, companyLogo, backgroundColour, textColour } =
      myAccountData || {};
    const brandingFieldsData = {
      businessId,
      companyName,
      companyAddress,
      companyLogo,
      backgroundColour,
      textColour
    };
    setFieldsData(brandingFieldsData);
  }, [myAccountData]);

  const changeEditingFields = (id) => {
    const elementIndex = editingFields.indexOf(id);
    if (elementIndex > -1) {
      if (requestError) {
        setRequestError(null);
      }
      const newArray = [...editingFields];
      newArray.splice(elementIndex, 1);
      setEditingFields(newArray);
    } else {
      setEditingFields([...editingFields, id]);
    }
  };

  const onUpdateAccountData = async (data, editingFiledId) => {
    if (requestError) {
      setRequestError(null);
    }
    const updatedData = ['backgroundColour', 'textColour'].includes(editingFiledId)
      ? data.toUpperCase()
      : data;
    const updatedAccountData = { ...myAccountData, [editingFiledId]: updatedData };
    const result = await updateAccount(updatedAccountData);
    if (result?.errorCode) {
      const fieldWithError = ERROR_CODES_FIELDS[result.errorCode];
      setRequestError({
        [fieldWithError]: t([`requestsErrors.${result.errorCode}`, 'requestsErrors.ANOTHER_ERROR'])
      });
    } else {
      changeEditingFields(editingFiledId);
    }
  };

  return (
    <MyAccountBlockWrapper>
      <Typography variant="body1" className="block-title">
        {t('titles.brandSettings')}
      </Typography>
      <Typography variant="body2" className="title-margin">
        {t('hints.brandSettingsHint')}
      </Typography>
      {MY_ACCOUNT_BRANDING_FIELDS_NAMES.map(({ key, enableEmptyData, initialValue, upperCase }) =>
        key === 'companyLogo' ? (
          <UploadLogoBlock
            key={key}
            title={t(`myAccountDetailsFields.companyLogo.title`)}
            placeHolder={t(`myAccountDetailsFields.companyLogo.placeHolder`)}
            hint={t(`myAccountDetailsFields.companyLogo.hint`)}
          />
        ) : (
          <MyAccountFieldItem
            key={key}
            enableEmptyData={enableEmptyData}
            changeEditingFields={changeEditingFields}
            editingFields={editingFields}
            fieldsData={fieldsData}
            dataName={key}
            initialValue={initialValue}
            title={t(`myAccountDetailsFields.${key}.title`)}
            requestError={requestError && requestError[key]}
            setRequestError={setRequestError}
            placeholder={t(`myAccountDetailsFields.${key}.placeHolder`)}
            onUpdateAccountData={onUpdateAccountData}
            t={t}
            upperCase={upperCase}
          />
        )
      )}
    </MyAccountBlockWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  myAccountData: myAccountDataSelector()
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BrandingBlock);
