import React from 'react';
import { ModalTitle } from './ModalTitle';
import { StyledModal, StyledMuiDialogContent } from './styledComponents';

export const CustomizedModal = ({
  isEmailFormModal,
  open,
  handleClose,
  title,
  children,
  width,
  height
}) => {
  return (
    <StyledModal
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="lg"
      height={height}
      width={width}
      isEmailFormModal={isEmailFormModal}
    >
      <ModalTitle title={title} onClose={handleClose} />
      <StyledMuiDialogContent>{children}</StyledMuiDialogContent>
    </StyledModal>
  );
};
