import {
  SET_IS_VOTED,
  SET_PARTICIPANT_ASSEMBLY_DATA,
  SET_PARTICIPANT_AGENDA_ITEMS_DATA,
  SET_ANONYMOUS_VOTING_HAS_BEEN_REQUESTED,
  SET_PARTICIPANT_VOTE_DATA,
  SET_PARTICIPANT_BRANDING_DATA,
  SET_ASSEMBLY_IDS,
  SET_VOTING_DATA,
  SET_LOADING_PARTICIPANT,
  SET_SENT_ADMIN_MESSAGE,
  SET_END_VOTING_DATA,
  SET_ACTIVE_AGENDA_ITEM_ID,
  SET_DATA_OF_VOTE_GOING_ON,
  SET_PARTICIPANT_VIEW_BALLOTS_DATA
} from 'constants/types';

const participantReducer = (
  state = {
    adminMessage: null,
    assemblyIds: null,
    anonVotingRequestStartTime: null,
    anonymousVotingHasBeenRequested: false,
    endVotingData: null,
    isLoading: false,
    isVoted: false,
    agendaItemsData: [],
    participantAssemblyData: null,
    participantViewBrandingData: null,
    participantVoteData: null,
    votingData: null,
    activeAgendaItemId: null,
    dataOfVoteGoingOn: null,
    ballots: {}
  },
  { type, payload }
) => {
  switch (type) {
    case SET_IS_VOTED:
      return {
        ...state,
        isVoted: payload
      };
    case SET_PARTICIPANT_ASSEMBLY_DATA:
      return {
        ...state,
        participantAssemblyData: payload?.assembly
      };
    case SET_PARTICIPANT_AGENDA_ITEMS_DATA:
      return {
        ...state,
        agendaItemsData: [...payload]
      };
    case SET_ANONYMOUS_VOTING_HAS_BEEN_REQUESTED:
      return {
        ...state,
        anonymousVotingHasBeenRequested: payload
      };
    case SET_PARTICIPANT_VOTE_DATA:
      return {
        ...state,
        participantVoteData: payload
      };
    case SET_ASSEMBLY_IDS:
      return {
        ...state,
        assemblyIds: payload
      };
    case SET_VOTING_DATA:
      return {
        ...state,
        votingData: payload
      };
    case SET_LOADING_PARTICIPANT:
      return {
        ...state,
        isLoading: payload
      };
    case SET_PARTICIPANT_BRANDING_DATA:
      return {
        ...state,
        participantViewBrandingData: payload
      };
    case SET_SENT_ADMIN_MESSAGE:
      return {
        ...state,
        adminMessage: payload
      };
    case SET_PARTICIPANT_VIEW_BALLOTS_DATA:
      return {
        ...state,
        ballots: { ...state.ballots, ...payload }
      };
    case SET_ACTIVE_AGENDA_ITEM_ID:
      return {
        ...state,
        activeAgendaItemId: payload
      };
    case SET_END_VOTING_DATA:
      return {
        ...state,
        endVotingData: payload
      };
    case SET_DATA_OF_VOTE_GOING_ON:
      return {
        ...state,
        dataOfVoteGoingOn: payload
      };
    default:
      return state;
  }
};

export default participantReducer;
