import { SnackBarAlert } from 'components/Alert/SnackbarAlert';
import { CustomButton } from 'components/Buttons/CustomButton';
import { MAX_UPLOADED_FILE_SIZE, MIN_UPLOADED_FILE_SIZE } from 'constants/common';
import { useUpdateMyAccount } from 'hooks/useUpdateMyAccountData';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { setLoading } from 'Redux/actions/common';
import { downloadSomeFile, uploadLogoToServer } from 'Redux/actions/files';
import { companyLogoURLSelector, myAccountDataSelector } from 'Redux/selectors/myAccount';
import { createStructuredSelector } from 'reselect';
import { blobToBase64 } from 'utils/files';
import {
  ContentWrapper,
  LogoWrapper,
  StyledDropZone,
  UploadButtonContainer
} from './styledComponents';

const UploadLogoComponent = ({
  actionDownloadSomeFile,
  actionSetLoading,
  actionUploadLogoToServer,
  logoURL,
  myAccountData
}) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ['.png', '.jpg', '.jpeg'],
    multiple: false,
    maxSize: MAX_UPLOADED_FILE_SIZE,
    minSize: MIN_UPLOADED_FILE_SIZE,
    onDrop: (acceptedFiles, rejectedFiles) => onDropHandle(acceptedFiles, rejectedFiles)
  });

  const [error, setError] = useState(null);
  const [logoImg, setLogoImg] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (logoURL) {
      setLogoImg(logoURL);
    }
  }, [logoURL]);

  const { updateAccount } = useUpdateMyAccount();

  const onDropHandle = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      await uploadFiles(acceptedFiles);
    }

    const errorCode =
      !!rejectedFiles.length
      && rejectedFiles[0].errors[0].code
        .replace(/-/g, ' ')
        .split(' ')
        .map((word, index) => (index ? word.charAt(0).toUpperCase() + word.slice(1) : word))
        .join('');
    if (errorCode) {
      setError(t(`clientSideValidationErrors.logoFileFormatError.${errorCode}`, ''));
    }
  };

  const uploadFiles = async (files) => {
    actionSetLoading(true);

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i], files[i].name);

      const res = await actionUploadLogoToServer(formData);
      if (res.isError) {
        break;
      } else {
        actionDownloadSomeFile({ fileId: res.id }).then((fileDownloadResponse) => {
          blobToBase64(fileDownloadResponse).then((response) => setLogoImg(response));
        });
        const updatedAccountData = { ...myAccountData, logoId: res.id };
        await updateAccount(updatedAccountData);
      }
    }
  };

  const onDeleteItem = async () => {
    setLogoImg(null);
    const updatedAccountData = { ...myAccountData, logoId: null };
    await updateAccount(updatedAccountData);
  };

  return (
    <ContentWrapper>
      {error && (
        <SnackBarAlert openAlert={error} closeAlert={() => setError(null)} message={error} />
      )}
      <UploadButtonContainer>
        {logoImg ? (
          <LogoWrapper url={logoImg} />
        ) : (
          <StyledDropZone
            onDrop={onDropHandle}
            {...getRootProps({
              className: 'dropzone',
              isDragActive,
              isDragAccept,
              isDragReject
            })}
          >
            <input {...getInputProps()} />
            <CustomButton
              colorType="secondary"
              name={t('buttonsNames.upload')}
              onClick={() => {}}
              variant="text"
            />
          </StyledDropZone>
        )}
      </UploadButtonContainer>
      {logoImg && (
        <CustomButton
          colorType="secondary"
          name={t('buttonsNames.delete')}
          onClick={onDeleteItem}
          variant="text"
        />
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  myAccountData: myAccountDataSelector(),
  logoURL: companyLogoURLSelector()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionDownloadSomeFile: downloadSomeFile,
      actionSetLoading: setLoading,
      actionUploadLogoToServer: uploadLogoToServer
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UploadLogoComponent);
