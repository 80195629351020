import {
  SET_UPLOADED_FILES,
  CLEAR_UPLOADED_FILES,
  CLEAR_STORAGE,
  SET_FILE_UPLOAD_ERROR,
  CLEAR_FILE_UPLOAD_ERROR
} from 'constants/types';

const initialState = {
  uploadedFiles: [],
  fileUploadError: ''
};

const filesReducer = (
  state = {
    ...initialState
  },
  { type, payload }
) => {
  switch (type) {
    case SET_UPLOADED_FILES:
      return {
        ...state,
        uploadedFiles: payload
      };
    case CLEAR_UPLOADED_FILES:
      return {
        ...state,
        uploadedFiles: []
      };
    case SET_FILE_UPLOAD_ERROR:
      return {
        ...state,
        fileUploadError: []
      };
    case CLEAR_FILE_UPLOAD_ERROR:
      return {
        ...state,
        fileUploadError: ''
      };
    case CLEAR_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default filesReducer;
