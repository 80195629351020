import { createSelector } from 'reselect';

const selectLiveAssemblyState = (state) => state.myAccountStore;

const myAccountDataSelector = () =>
  createSelector(selectLiveAssemblyState, (state) => state?.myAccountData);

const userNameSelector = () =>
  createSelector(selectLiveAssemblyState, (state) => state?.myAccountData?.userName);

const userLanguageSelector = () =>
  createSelector(selectLiveAssemblyState, (state) => state?.myAccountData?.language);

const companyLogoURLSelector = () =>
  createSelector(selectLiveAssemblyState, (state) => state?.logoURL);

export { myAccountDataSelector, userNameSelector, userLanguageSelector, companyLogoURLSelector };
