import React from 'react';
import { GeneralLayoutWrapper, MainContentWrapper, LeftSidebar } from './styledComponents';
import LeftSidebarContent from './components/LeftSidebarContent';
import { checkSideBarType, checkNoSideBarLayout } from 'utils/common';
import history from 'utils/history';

export const GeneralLayout = ({ children, userRole, isParticipantView }) => {
  const sideBarType = checkSideBarType(userRole, history.location.pathname);
  const noSideBarLayout = checkNoSideBarLayout(userRole, history.location.pathname);
  return (
    <GeneralLayoutWrapper>
      {!noSideBarLayout && (
        <LeftSidebar
          userRole={userRole}
          sideBarType={sideBarType}
          isParticipantView={isParticipantView}
        >
          <LeftSidebarContent sideBarType={sideBarType} />
        </LeftSidebar>
      )}
      <MainContentWrapper>{children}</MainContentWrapper>
    </GeneralLayoutWrapper>
  );
};
