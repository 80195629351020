import { createSelector } from 'reselect';

const selectCommonState = (state) => state.commonStore;

const loadingSelector = () => createSelector(selectCommonState, (state) => state?.loading);
const assemblyStatusFilterValueSelector = () =>
  createSelector(selectCommonState, (state) => state?.assemblyStatusFilterValue);

const isDontShowBatchesEmailsActionConfirmationPopupSelector = () =>
  createSelector(
    selectCommonState,
    (state) => state?.isDontShowBatchesEmailsActionConfirmationPopup
  );

export {
  loadingSelector,
  assemblyStatusFilterValueSelector,
  isDontShowBatchesEmailsActionConfirmationPopupSelector
};
