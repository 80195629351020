import createTheme from '@material-ui/core/styles/createTheme';
import { COLORS } from 'constants/common';

const globalTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.darkBlue,
      contrastText: '#ffffff'
    },
    secondary: {
      main: COLORS.blue,
      contrastText: '#ffffff'
    },
    textPrimary: {
      main: '#000000'
    },
    textSecondary: {
      main: '#ffffff'
    },
    error: {
      main: COLORS.error
    }
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    h2: {
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: 'normal',
      color: '#000000'
    },
    h3: {
      fontSize: 24,
      lineHeight: '40px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: 'normal',
      color: '#ffffff'
    },
    h4: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 600,
      alignItems: 'center',
      letterSpacing: 'normal',
      color: '#000000'
    },
    body1: {
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: '24px',
      color: '#ffffff'
    },
    body2: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: COLORS.gray
    }
  }
});

export const mainTheme = createTheme(
  {
    overrides: {
      MuiFormHelperText: {
        root: {
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          marginLeft: 14,
          marginBottom: 0
        }
      },
      MuiInput: {
        root: {
          '&.MuiInput-underline': {
            borderWidth: 1,
            borderColor: `${COLORS.borderLightGray}`
          },
          '&.Mui-error': {
            '&.MuiInput-underline': {
              borderWidth: 1,
              borderColor: COLORS.error
            }
          },
          '&.Mui-focused': {
            '&.MuiInput-underline': {
              borderWidth: 1,
              borderColor: `${COLORS.borderLightGray}`
            }
          },
          '&.MuiInput-underline:hover': {
            borderWidth: 1,
            borderColor: '#b1b1b1'
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: 4,
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: `${COLORS.borderLightGray}`
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: COLORS.error
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: `${COLORS.borderLightGray}`
            }
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: '#b1b1b1'
            }
          }
        },
        input: {
          padding: 14,
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #fff inset',
            padding: 14,
            '&::first-line': {
              fontSize: 16,
              fontFamily: 'Open sans'
            }
          }
        }
      },
      MuiButton: {
        root: {
          fontWeight: 700,
          fontSize: 16,
          lineHeight: '16px',
          textTransform: 'inherit',
          padding: 16,
          '&.MuiButton-containedSizeLarge': {
            padding: '8px 16px',
            fontSize: 16
          },
          '&.MuiButton-containedSizeSmall': {
            padding: 16,
            fontSize: 16
          },
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: globalTheme.palette.secondary.main
          },
          '&.MuiButton-textSecondary': {
            width: 'max-content',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '20px'
          },
          '&.MuiButton-text:hover': {
            backgroundColor: 'transparent'
          },
          '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: COLORS.lightGray,
            color: COLORS.textLightGray,
            border: `1px solid ${COLORS.textLightGray}`
          }
        },
        label: {
          lineHeight: '16px'
        }
      },
      MuiIconButton: {
        root: {
          fontWeight: 700,
          fontSize: 16,
          lineHeight: '16px',
          textTransform: 'inherit',
          padding: 16,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiLink: {
        root: {
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '20px'
        }
      },
      MuiInputAdornment: {
        root: {
          '& .MuiSvgIcon-root': {
            width: '0.9em',
            height: '0.9em'
          }
        }
      },
      MuiDialog: {
        paper: {
          boxSizing: 'border-box'
        },
        paperWidthXs: {
          padding: '48px'
        }
      },
      MuiDialogTitle: {
        root: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#000000',
          padding: '0 20px',
          marginBottom: '24px'
        }
      },
      MuiDialogContent: {
        root: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '24px',
          paddingTop: 0,
          padding: '0 20px'
        }
      },
      MuiDialogActions: {
        root: {
          padding: 0,
          marginTop: 20
        }
      },
      MuiDialogContentText: {
        root: {
          fontSize: 16,
          fontWeight: 'normal',
          lineHeight: '24px',
          color: '#000000'
        }
      },
      MuiTab: {
        root: {
          '@media (min-width:600px)': {
            minWidth: 0
          },
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 16,
          lineHeight: '24px',
          color: COLORS.darkBlue,
          padding: '4px 0',
          minWidth: 0,
          textTransform: 'none'
        },
        textColorPrimary: {
          color: COLORS.darkBlue
        }
      },
      MuiTimeline: {
        root: {
          padding: '6px 0px'
        }
      },
      MuiTimelineItem: {
        root: {
          alignItems: 'baseline'
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none'
          }
        }
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiMenu: {
        list: {
          height: 300
        }
      },
      MuiMenuItem: {
        root: {
          color: '#000000'
        }
      },
      MUIRichTextEditor: {
        root: {
          display: 'flex',
          boxSizing: 'border-box',
          width: '100%',
          height: '180px',
          backgroundColor: '#ffffff',
          border: `1px solid ${COLORS.borderLightGray}`,
          borderRadius: '0 0 4px 4px'
        },
        toolbar: {
          borderTop: `1px solid ${COLORS.borderLightGray}`,
          borderRadius: '0 0 4px 4px',
          backgroundColor: `${COLORS.lightGray}`,
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20
          }
        },
        placeHolder: {
          top: 0,
          paddingLeft: 16,
          paddingTop: 10,
          margin: 0,
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 40px)',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '24px',
          color: `${COLORS.textLightGray}`
        },
        editor: {
          width: '100%',
          height: '100%',
          padding: '0 16px',
          backgroundColor: `#ffffff`,
          boxSizing: 'border-box',
          overflow: 'auto',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '24px',
          color: '#000000'
        },
        editorContainer: {
          width: '100%'
        },
        container: {
          '& .MuiButtonBase-root': {
            padding: '5px 10px'
          },
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'space-between',
          backgroundColor: '#ffffff',
          borderRadius: '0 0 4px 4px',
          margin: 0
        }
      },
      MuiTooltip: {
        tooltip: {
          maxWidth: '700px'
        }
      },
      MuiAlert: {
        filledInfo: {
          backgroundColor: COLORS.blue
        }
      }
    }
  },
  globalTheme
);
