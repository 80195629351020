import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { AppLogo } from 'components/AppLogo/AppLogo';
import React from 'react';
import { connect } from 'react-redux';
import { isUserAuth } from 'Redux/selectors/auth';
import { userLogout } from 'Redux/actions/auth';
import { createStructuredSelector } from 'reselect';
import { LINKS, APP_VERSION, TOKEN_KEY } from 'constants/common';
import { bindActionCreators, compose } from 'redux';
import { userLanguageSelector } from 'Redux/selectors/myAccount';
import { BottomBlock, HeadBlock, SidebarContentWrapper, SupportBlock } from '../styledComponents';
import { CommonInfoBlock } from './CommonInfoBlock';
import LeftNavigation from './LeftNavigation';
import { LocalizationSelect } from './LocalizationSelector/LocalizationSelect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const LeftSidebarContent = ({ isAuthenticated, sideBarType, userLanguage, actionUserLogout }) => {
  const history = useHistory();
  const logout = () => {
    const data = {};
    actionUserLogout(data).then(() => {
      localStorage.removeItem(TOKEN_KEY);
      history.go(0);
    });
  };

  if (isAuthenticated && window.location.pathname.includes('/reset-password')) {
    logout();
  }
  const { t } = useTranslation();
  return (
    <SidebarContentWrapper>
      <HeadBlock>
        <AppLogo isFullSizeLogo={sideBarType !== 'small'} />
        <SidebarContentWrapper>
          {sideBarType !== 'small' &&
            (isAuthenticated ? <LeftNavigation /> : <CommonInfoBlock translate={t} />)}
        </SidebarContentWrapper>
      </HeadBlock>
      {sideBarType !== 'small' && isAuthenticated && (
        <BottomBlock>
          <SupportBlock>
            <Link
              href={LINKS.support}
              component="a"
              variant="body1"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              {t('buttonsNames.support', '')}
            </Link>
            <Typography variant="body2">
              {t('commonTexts.versionNumber', { versionNumber: APP_VERSION })}
            </Typography>
          </SupportBlock>
          <LocalizationSelect userLanguage={userLanguage} />
        </BottomBlock>
      )}
    </SidebarContentWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: isUserAuth(),
  userLanguage: userLanguageSelector()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionUserLogout: userLogout
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LeftSidebarContent);
