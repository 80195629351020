import { createSelector } from 'reselect';
import { TOKEN_KEY } from 'constants/common';

const selectAuthorizationState = (state) => state.authStore;

const userRolesSelector = () =>
  createSelector(selectAuthorizationState, (state) => state.userData?.role);

const userDataSelector = () =>
  createSelector(selectAuthorizationState, (state) => state.userData);

const loginErrorSelector = () =>
  createSelector(selectAuthorizationState, (state) => state?.loginError);

const isUserAuth = () =>
  createSelector(
    selectAuthorizationState,
    (state) => !!state?.userData && localStorage.getItem(TOKEN_KEY)
  );

export { isUserAuth, loginErrorSelector, userRolesSelector, userDataSelector };
