import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { TOKEN_KEY } from 'constants/common';
import { Redirect, withRouter } from 'react-router';
import { compose } from 'redux';
import ParticipantViewContainer from '../ParticipantView/ParticipantViewContainer';

const ParticipantPrivateRote = ({ withAgendaTitle, component: Component, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem(TOKEN_KEY) ? (
          <ParticipantViewContainer
            t={t}
            withAgendaTitle={withAgendaTitle}
            props={props}
            assemblyId={props.match.params.assemblyId}
          >
            <Component {...props} />
          </ParticipantViewContainer>
        ) : (
          <Redirect
            to={{
              pathname: `/access/${rest.computedMatch.params.assemblyId}`,
              state: {
                authStorageError: t('requestsErrors.STORAGE_DATA_ACCESS_DENIED')
              }
            }}
          />
        )
      }
    />
  );
};

export default compose(withRouter)(React.memo(ParticipantPrivateRote));
