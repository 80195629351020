import { da, de, enGB, nb, sv } from 'date-fns/locale';
import GBRFlag from 'img/eng_flag.svg';
import DenmarkFlag from 'img/de_flag.svg';
import GermanyFlag from 'img/ger_flag.svg';
import SwedenFlag from 'img/swe_flag.svg';
// import NorwayFlag from 'img/nor_flag.svg';

export const DATE_FNS_LOCALES_IDS = {
  en: enGB,
  da: da,
  de: de,
  no: nb,
  sw: sv
};

export const STORAGES_KEYS_VALUES = {
  token: 'TOKEN_KEY',
  participantRedirectURL: 'PARTICIPANT_LOGIN_REDIRECT_URL',
  participantData: 'PARTICIPANT_DATA',
  assemblyId: 'ASSEMBLY_ID'
};
export const TOKEN_KEY = 'TOKEN_KEY';

export const MAX_UPLOADED_FILE_SIZE = 31457280;
export const MIN_UPLOADED_FILE_SIZE = 1;
export const PARTICIPANTS_LIST_MAX_HEIGHT = 220;

export const COLORS = {
  darkBlue: '#141435',
  blue: '#3161A9',
  gray: '#6F6F6F',
  lightBlue: '#EBF3F9',
  lightGray: '#F4F5F6',
  borderLightGray: '#cccccc',
  hintsLightGray: '#c4c4c4',
  textLightGray: '#999999',
  orange: '#ea7317',
  error: '#E02A36',
  darkGreen: '#003F35',
  greenBorder: '#0A5349',
  lightGreenBackground: '#E3EFDA',
  lightGreen: '#D1E4C1',
  lightYellow: '#FFF8BA',
  borderLightYellow: '#E5D96F'
};

export const USER_ROLES = {
  superAdmin: 'super_admin',
  userAdmin: 'admin',
  usualParticipant: 'usual_participant',
  participantLawyer: 'participant_lawyer',
  participantAuditor: 'participant_auditor',
  supportManager: 'support_manager'
};

export const userData = {
  accessToken:
    'FcKqfeHlsZGLudg+cGSPikkX/80MCwX6DzA1CNWwPyCMvxr1wnb4IerQ2dVwKzsSaK51lQLgzwm8r22wr6i82nq4srBJmcfSHt3jOzIviuT1IqAmO6odq/AQORMIR3FCFP9buHiKyc7monYl/ZPXjCWJuo0HlZ/PmwfAOWC0ytdsoCYy1NJra1nSQJk0+K1cOKzuf4zkO39fjB1xyY9tMtifuF1spLhSHU8zNVUGmqJZYwCzxkpKiGWn00e1+qHKZA3AlT9Q75P0m2E9sAUqWfF6GGaOuWuNVDD3xdFwnSOuadBHRQVjCHNtvyvpDddffHlVOuE7O6qhM0APWCrBgw==',
  errorCode: null,
  role: 'admin',
  success: true,
  tokenType: 'Custom',
  userName: 'd.khramykh@softteco.com'
};

export const ORDER_TYPE = {
  asc: {
    type: 'ASC',
    fullName: 'ascending'
  },
  desc: {
    type: 'DESC',
    fullName: 'descending'
  }
};

export const ADD_NEW_ASSEMBLY_STEPS_INDEXES = {
  name: 0,
  participants: 1,
  date: 2,
  invitationEmail: 3,
  setupAgenda: 4
};

export const ADD_NEW_ASSEMBLY_SUB_STEPS_INDEXES = {
  date: 0,
  time: 1,
  locations: 2,
  agendaHeadline: 0,
  agendaItemSetup: 1,
  agendaFinish: 2
};

export const ASSEMBLY_MAIN_PAGE_NAME = 'dashboard';

export const ADMINS_ROLES_TYPES = [USER_ROLES.superAdmin, USER_ROLES.userAdmin];

export const UP_AND_DOWN_KEY_NAMES = {
  arrowDown: 'ArrowDown',
  arrowUp: 'ArrowUp'
};

export const LOCALIZATIONS_FLAGS = {
  en: GBRFlag,
  da: DenmarkFlag,
  // no: NorwayFlag,
  de: GermanyFlag,
  sw: SwedenFlag
};

export const LANGUAGES_LIST = [
  { id: 'en', nameKey: 'english' },
  { id: 'da', nameKey: 'danish' },
  // { id: 'no', nameKey: 'norwegian' },
  { id: 'de', nameKey: 'german' },
  { id: 'sw', nameKey: 'swedish' }
];

export const SWITCHER_LABELS = {
  includeBallot: 'includeBallot',
  multiply: 'multiply',
  abstain: 'abstain',
  openBallot: 'openBallot',
  voteBasedOnShares: 'voteBasedOnShares',
  twoFactorAuth: 'twoFactorAuth',
  voteUsingOpenBallot: 'voteUsingOpenBallot',
  ballotTypeSwitcher: 'ballotTypeSwitcher'
};

export const ABSTAIN_OF_VOTING_OPTION_NAMES = [
  'Abstain from voting',
  'Undlad at stemme',
  SWITCHER_LABELS.abstain
];

export const LINKS = {
  support: 'https://www.boardplace.com/kontakt'
};

export const ERROR_CODES_FIELDS = {
  EMAIL_ALREADY_TAKEN: 'email'
};

export const APP_VERSION = 20;

export const MOBILE_PARTICIPANT_INFO_BOTTOM_LINE_HEIGHT = 60;
export const MOBILE_PARTICIPANT_VOTING_TIMER_BALLOT_TIME_LINE = 80;

export const STORES_NAMES = {
  plannedAssembly: 'plannedAssemblyStore',
  liveAssembly: 'liveAssemblyStore',
  participantAssembly: 'participantStore',
  previewAssembly: 'assemblyPreviewPageStore',
  followUpAndDone: 'followUpAndDoneAssemblyStore'
};
