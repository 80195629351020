import api from 'api';
import {
  SET_PARTICIPANT_AGENDA_ITEMS_DATA,
  SET_PARTICIPANT_ASSEMBLY_DATA,
  SET_ANONYMOUS_VOTING_HAS_BEEN_REQUESTED,
  SET_IS_VOTED,
  SET_ASSEMBLY_IDS,
  SET_PARTICIPANT_VOTE_DATA,
  SET_PARTICIPANT_BRANDING_DATA,
  SET_DATA_OF_VOTE_GOING_ON,
  SET_VOTING_DATA,
  SET_ACTIVE_AGENDA_ITEM_ID,
  SET_LOADING_PARTICIPANT,
  SET_SENT_ADMIN_MESSAGE,
  SET_END_VOTING_DATA,
  SET_PARTICIPANT_VIEW_BALLOTS_DATA
} from 'constants/types';

import { encryptData } from 'utils/decryptEncryptAES';

export const getParticipantAssemblyData = (data) => (dispatch) => {
  const sendingData = { ...{ data: encryptData(data) }, isParticipantSide: true };
  return api.commonApi
    .getAssemblyData(sendingData)
    .then((res) => {
      dispatch(setParticipantAssemblyData(res));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const downloadBrandingLogoByParticipant = (data) => () => {
  const sendingData = { ...{ data: encryptData(data) }, isParticipantSide: true };
  return api.files
    .getBrandingLogoParticipantView(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const getParticipantAgendaItemData = (data) => () => {
  const sendingData = { ...{ data: encryptData(data) }, isParticipantSide: true };
  return api.commonApi
    .getAgendaItemData(sendingData)
    .then((res) => {
      return res.agendaItem;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const sendParticipantVote = (data) => () => {
  const sendingData = { ...data, isParticipantSide: true };
  return api.voting
    .castVote(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const sendAnonymousVotingRequest = (data) => () => {
  const sendingData = { ...data, isParticipantSide: true };
  return api.voting
    .requestSecretVote(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const checkUserHasBeenVoted = (data) => () => {
  const sendingData = { ...{ data: encryptData(data) }, isParticipantSide: true };
  return api.voting
    .userHasBeenVoted(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const getParticipantVoteData = (data) => () => {
  const sendingData = { ...{ data: encryptData(data) }, isParticipantSide: true };
  return api.voting
    .getBallotData(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export function setAssemblyIds(data) {
  return {
    type: SET_ASSEMBLY_IDS,
    payload: data
  };
}

export function setParticipantAgendaItemsData(data) {
  return {
    type: SET_PARTICIPANT_AGENDA_ITEMS_DATA,
    payload: data
  };
}

export function setParticipantVoteData(data) {
  return {
    type: SET_PARTICIPANT_VOTE_DATA,
    payload: data
  };
}

export function setParticipantAssemblyData(data) {
  return {
    type: SET_PARTICIPANT_ASSEMBLY_DATA,
    payload: data
  };
}

export function setIsParticipantVoted(data) {
  return {
    type: SET_IS_VOTED,
    payload: data
  };
}

export function setAnonymousVotingHasBeenRequested(data) {
  return {
    type: SET_ANONYMOUS_VOTING_HAS_BEEN_REQUESTED,
    payload: data
  };
}
export function setVotingData(data) {
  return {
    type: SET_VOTING_DATA,
    payload: data
  };
}

export function setLoadingParticipantView(data) {
  return {
    type: SET_LOADING_PARTICIPANT,
    payload: data
  };
}

export function setParticipantBrandingData(data) {
  return {
    type: SET_PARTICIPANT_BRANDING_DATA,
    payload: data
  };
}

export function setSentAdminMessage(data) {
  return {
    type: SET_SENT_ADMIN_MESSAGE,
    payload: data
  };
};

export function setParticipantViewBallotsData(data) {
  return {
    type: SET_PARTICIPANT_VIEW_BALLOTS_DATA,
    payload: data
  };
};

export function setEndVotingData(data) {
  return {
    type: SET_END_VOTING_DATA,
    payload: data
  };
};

export function setActiveAgendaItemId(data) {
  return {
    type: SET_ACTIVE_AGENDA_ITEM_ID,
    payload: data
  };
}

export function setBallotDataOfVoteGoingOn(data) {
  return {
    type: SET_DATA_OF_VOTE_GOING_ON,
    payload: data
  };
}
