import { MY_ACCOUNT_FIELDS_PATTERNS } from 'constants/myAccount';
import { MyAccountInputComponentWrapper } from '../styledComponents';
import { CustomButton } from 'components/Buttons/CustomButton';
import { InputFieldWithButtonGroup } from 'components/InputFieldWithButtonsGroup/InputFieldWithButtonsGroup';
import React, { useEffect, useState } from 'react';

export const MyAccountInputComponent = ({
  value,
  onSaveChanges,
  enableEmptyData,
  name,
  placeholder,
  requestError,
  setRequestError,
  setEditStatus,
  t,
  upperCase
}) => {
  const [itemValue, setItemValue] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    setItemValue(value);
    return () => {
      setError(null);
    };
  }, []);

  useEffect(() => {
    setError(requestError);
  }, [requestError]);

  const onChangeValue = (e) => {
    if (error?.length) {
      setError('');
    }
    if (requestError?.length) {
      setRequestError(null);
    }
    setItemValue(e.target.value);
  };

  const clearInputValue = () => {
    if (error?.length) {
      setError('');
    }
    setItemValue('');
  };

  const onSaveValue = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!itemValue.trim().length && !enableEmptyData) {
      return;
    }
    const isValueContainRegex = MY_ACCOUNT_FIELDS_PATTERNS[name]?.test(itemValue.trim());
    if (
      (!['email', 'backgroundColour', 'textColour'].includes(name) && isValueContainRegex)
      || (['email', 'backgroundColour', 'textColour'].includes(name) && !isValueContainRegex)
    ) {
      setError(t(`myAccountFieldsErrors.${name}`));
      return;
    }

    if (error?.length) {
      setError('');
    }

    onSaveChanges(itemValue.trim(), name);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={onSaveValue} className="fields-form-wrapper">
      <MyAccountInputComponentWrapper error={error?.length}>
        <InputFieldWithButtonGroup
          name={name}
          autoFocus={true}
          inputValue={itemValue}
          hasValue={!!itemValue?.trim()?.length}
          withoutValueChecking={enableEmptyData}
          changeValue={onChangeValue}
          clearInput={clearInputValue}
          onSaveButtonClick={onSaveValue}
          error={error}
          placeholder={placeholder}
          upperCase={upperCase}
        />
        <CustomButton
          fullWidth
          colorType="secondary"
          name={t('buttonsNames.cancel')}
          onClick={setEditStatus}
          variant="text"
        />
      </MyAccountInputComponentWrapper>
    </form>
  );
};
