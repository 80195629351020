import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import {
  AdditionalInfo,
  CompanyInformation,
  CompanyInformationWrapper,
  LogoComponent,
  InformationBlock,
  ParticipantViewHeaderWrapper,
  MainInformationBlock
} from '../../ParticipantView/styledComponents';

export const ParticipantViewHeader = ({
  backgroundColour,
  businessId,
  className,
  companyAddress,
  companyName,
  logo,
  textColour
}) => {
  const [openCompanyInfo, setOpenCompanyInfo] = useState(false);
  const { innerWidth: width } = window;

  const isHighWidth = width > 820;

  const getMainInfo = () => {
    switch (true) {
      case !!logo:
        return <LogoComponent url={logo} />;
      case !isHighWidth && !logo && !!companyName:
        return (
          <Typography variant="h2" className="company-name-item">
            {companyName}
          </Typography>
        );
      case !isHighWidth && !logo && !companyName && (!!companyAddress || !!businessId):
        return (
          <AdditionalInfo>
            <Typography variant="body2">{companyAddress}</Typography>
            <Typography variant="body2">{businessId}</Typography>
          </AdditionalInfo>
        );
      default:
        return '';
    }
  };

  const checkIsCollapseIconVisible = () => {
    const infoBlocksAdded = [!!logo, !!companyName, !!companyAddress || !!businessId].filter(
      (item) => item
    );
    return infoBlocksAdded.length > 1;
  };

  return (
    <ParticipantViewHeaderWrapper className={className} backgroundColour={backgroundColour}>
      <CompanyInformationWrapper textColour={textColour} openCompanyInfo={openCompanyInfo}>
        <InformationBlock>
          <MainInformationBlock>{getMainInfo()}</MainInformationBlock>
          <Collapse in={openCompanyInfo || isHighWidth} timeout="auto" unmountOnExit>
            <CompanyInformation className="company-info-block" id="company-info">
              {(!!logo || isHighWidth) && !!companyName && (
                <Typography variant="h2" className="company-name-item">
                  {companyName}
                </Typography>
              )}
              {(isHighWidth || !!logo || !!companyName) && (
                <AdditionalInfo>
                  <Typography variant="body2">{companyAddress}</Typography>
                  <Typography variant="body2">{businessId}</Typography>
                </AdditionalInfo>
              )}
            </CompanyInformation>
          </Collapse>
        </InformationBlock>
        {checkIsCollapseIconVisible() && (
          <IconButton
            onClick={() => setOpenCompanyInfo(!openCompanyInfo)}
            size="small"
            disableRipple={true}
            tabIndex="-1"
            className="collapse-icon-button"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </CompanyInformationWrapper>
    </ParticipantViewHeaderWrapper>
  );
};
