import React from 'react';
import FullSizeLogo from 'img/logo-full.svg';
import ShortSizeLogo from 'img/logo-short.svg';
import { StyledImg } from './styledComponents';

export const AppLogo = ({ isFullSizeLogo }) => (
  <StyledImg
    src={isFullSizeLogo ? FullSizeLogo : ShortSizeLogo}
    alt="Boardplace Logo"
    isFullSizeLogo={isFullSizeLogo}
  />
);
