import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { COLORS } from 'constants/common';

export const CustomDialog = styled(Dialog)`
  & .MuiTypography-h6 {
    font-size: 24px;
    font-weight: 400;
  }
  @media (max-width: 820px) {
    & .MuiPaper-root.MuiDialog-paper {
      padding: 30px 10px;
    }
  }
`;

export const CustomDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & .MuiButton-outlined {
    padding: 15px;
    background: #ffffff;
    border: 1px solid ${COLORS.error};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${COLORS.error};
    margin-bottom: 18px;
    &:hover {
      background: #ffffff;
      border: 1px solid ${COLORS.error};
    }
  }
  & button {
    width: ${(props) => (props.isOneButtonRow ? 'auto' : '100%')};
  }
`;

export const DialogActionsHint = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: 16px;
  border-top: 1px solid ${COLORS.borderLightGray};
  box-sizing: border-box;
  & .MuiTypography-body2 {
    font-weight: 600;
    color: #98a0a6;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  & hr {
    width: 100%;
  }
`;

export const StyledCheckBoxLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    min-height: 66px;
    background: #ffffff;
    margin: 0;
    &.MuiFormControlLabel-root .MuiTypography-body1 {
      color: ${COLORS.blue};
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      padding: 10px 0;
    }
    & .MuiIconButton-colorSecondary:hover {
      background-color: transparent;
    }
    & .icon {
      width: 24px;
      height: 24px;
      box-shadow: inset 0 0 0 1px ${COLORS.blue}, inset 0 -1px 0 rgba(16, 22, 26, 0.1);
      background: transparent;
    }
    .checkbox-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #ffffff;
    }
    & .checkedIcon {
      display: flex;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      box-shadow: inset 0 0 0 2px ${COLORS.blue}, inset 0 -1px 0 rgba(16, 22, 26, 0.1);
      background: #ffffff;
    }

    & .checkedIcon::before {
      display: block;
      margin: auto;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: ${COLORS.blue};
      content: '';
    }
    & .MuiCheckbox-root {
      outline: 1px;
      padding: 16px;
      color: ${COLORS.blue};
    }
    & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
`;
