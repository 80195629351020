import Typography from '@material-ui/core/Typography';
import React from 'react';
import { BlockWithIconWrapper } from 'ParticipantView/styledComponents';

export const BlockWithIcon = ({ icon, label, className }) => (
  <BlockWithIconWrapper className={className}>
    <img src={icon} />
    <Typography variant="body2">{label}</Typography>
  </BlockWithIconWrapper>
);
