import api from 'api';
import {
  SET_ASSEMBLIES_COLLECTION,
  SET_JOINED_PARTICIPANTS_LIST,
  SET_NEW_BATCHES_REQUEST_ERROR
} from 'constants/types';
import { encryptData } from 'utils/decryptEncryptAES';
import { setLoading } from 'Redux/actions/common';
import {
  updateInvitationEmailResponseDataWithAttachmentsIds,
  updateAgendaItemResponseDataWithAttachmentsIds
} from 'utils/updateDataWithAttachmentsIds';

export const getAssembliesCollectionList = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.commonAssemblies
    .getAssembliesCollection({ data: encryptData(data) })
    .then((res) => {
      dispatch(setAssembliesCollection(res));
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
    });
};

export const onDuplicateAssembly = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.commonAssemblies
    .duplicateAssembly(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
    });
};

export const onGetAssemblyData = (data, isNeedToUpdateInvitations) => (dispatch) => {
  return api.commonApi
    .getAssemblyData({ data: encryptData(data) })
    .then((res) => {
      dispatch(setLoading(false));
      if (isNeedToUpdateInvitations) {
        return updateInvitationEmailResponseDataWithAttachmentsIds(res);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      dispatch(setLoading(false));
    });
};

export const addNewAgendaItem = (data) => () => {
  return api.commonAssemblies
    .addAgendaItem(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export const onUpdateAssembly = (data) => (dispatch) => {
  return api.commonAssemblies
    .updateAssembly(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const onUpdateAgendaItem = (data) => () => {
  return api.commonAssemblies
    .updateAgendaItem(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export const onSendInvitationsWithMakingBatch = (data) => (dispatch) => {
  return api.commonAssemblies
    .sendInvitationsWithMakingBatch(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setNewBatchesRequestError(error.data.errorCode));
      return error.data;
    });
};

export const getAssemblyAgendaItemData = (data, withoutAttachmentsIds, isParticipantSide) => () => {
  const sendingData = { ...{ data: encryptData(data) }, isParticipantSide };
  return api.commonApi
    .getAgendaItemData(sendingData)
    .then((res) => {
      return withoutAttachmentsIds
        ? res.agendaItem
        : updateAgendaItemResponseDataWithAttachmentsIds(res.agendaItem);
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const addNewAgendaItemBallot = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.voting
    .addNewVotingBallot(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
      return error.data;
    });
};

export const onUpdateAgendaItemBallot = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.voting
    .updateVotingBallot(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      dispatch(setLoading(false));
      return error.data;
    });
};

export const onSendTestAssemblyInvitation = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.commonAssemblies
    .sendTestAssemblyInvitation(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      dispatch(setLoading(false));
      return error.data;
    });
};

export const getAgendaBallot = (data, isParticipantSide) => () => {
  const sendingData = isParticipantSide
    ? { ...{ data: encryptData(data) }, isParticipantSide }
    : { data: encryptData(data) };
  return api.voting
    .getBallotData(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const getAgendaBallotWithResults = (data, isParticipantSideRequest) => () => {
  const sendingData = isParticipantSideRequest
    ? { ...{ data: encryptData(data) }, isParticipantSide: true }
    : { data: encryptData(data) };
  return api.voting
    .getBallotDataWithResults(sendingData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const getBatchData = (data) => () => {
  return api.commonAssemblies
    .getBatch({ data: encryptData(data) })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const getAssemblyBatchesList = (data) => () => {
  return api.commonAssemblies
    .getBatchesList({ data: encryptData(data) })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const getJoinedParticipantsList = (data) => (dispatch) => {
  return api.commonApi
    .getJoinedParticipants({ data: encryptData(data) })
    .then((res) => {
      dispatch(setJoinedParticipantsList(res.activeParticipantsList));
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export function setJoinedParticipantsList(data) {
  return {
    type: SET_JOINED_PARTICIPANTS_LIST,
    payload: data
  };
}

export function setAssembliesCollection(data) {
  return {
    type: SET_ASSEMBLIES_COLLECTION,
    payload: data
  };
}

export function setNewBatchesRequestError(data) {
  return {
    type: SET_NEW_BATCHES_REQUEST_ERROR,
    payload: data
  };
}
