import styled from 'styled-components';
import { COLORS } from 'constants/common';

export const AssemblyStatusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border: 2px solid ${(props) => (props.isDone ? COLORS.greenBorder : COLORS.orange)};
  background-color: ${(props) => (props.isDone ? COLORS.lightGreen : '#ffffff')};
  border-radius: 50%;
`;
