import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { userNameSelector } from 'Redux/selectors/myAccount';
import { setLoading } from 'Redux/actions/common';
import { MenuWrapper } from './styledComponents';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import { TOKEN_KEY } from 'constants/common';
import { bindActionCreators, compose } from 'redux';
import { userLogout } from 'Redux/actions/auth';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AdminViewLeftMenu = ({ actionSetLoaderStatus, actionUserLogout, userName }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const logout = () => {
    const data = {};
    actionSetLoaderStatus(true);
    window.Intercom('shutdown');
    actionUserLogout(data).then(() => {
      localStorage.removeItem(TOKEN_KEY);
      actionSetLoaderStatus(false);
      history.push('/');
    });
  };
  return (
    <MenuWrapper>
      <MenuList>
        <MenuItem
          disableRipple={true}
          disableGutters
          selected={history.location.pathname !== '/dashboard/my-account'}
          onClick={() => history.push('/dashboard')}
        >
          {t('adminNavigationTabs.myAssemblies')}
        </MenuItem>
        <MenuItem
          disableRipple={true}
          disableGutters
          selected={history.location.pathname === '/dashboard/my-account'}
          onClick={() => history.push('/dashboard/my-account')}
        >
          {t('adminNavigationTabs.myAccount')}
        </MenuItem>
        <MenuItem disableRipple={true} disableGutters onClick={logout}>
          {t('adminNavigationTabs.logout')}
        </MenuItem>
      </MenuList>
    </MenuWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  userName: userNameSelector()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionSetLoaderStatus: setLoading,
      actionUserLogout: userLogout
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(AdminViewLeftMenu);
