export const updateInvitationEmailResponseDataWithAttachmentsIds = (response) => {
  const { attachments } = response.assembly?.invitation || []

  return {
    ...response,
    assembly: {
      ...response.assembly,
      invitation: response.assembly?.invitation && {
        ...response.assembly?.invitation,
        attachmentsIds: attachments && attachments.length
          ? response.assembly?.invitation?.attachments.map((item) => item.id)
          : []
      }
    }
  };
};

export const updateAgendaItemResponseDataWithAttachmentsIds = (response) => {
  const { attachments } = response;
  return {
    ...response,
    attachmentsIds: attachments && attachments.length ? attachments.map((item) => item.id) : []
  };
};
