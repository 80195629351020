import styled from 'styled-components';

export const GuidelinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-bottom: 40px;
  & > .MuiTypography-body2 {
    margin: 4px 0 8px 0;
  }
  & .strong-password-helper {
    font-weight: 400;
    margin: 0;
  }
`;

export const StyledList = styled.ul`
  margin: 0 0 24px 0;
  padding-left: 0;
  & li {
    list-style-type: none;
  }
  & li .MuiTypography-body2 {
    margin: 0;
    font-weight: 400;
    line-height: 24px;
  }
`;
