import styled from 'styled-components';

export const ButtonGroupWrapper = styled.div`
  display: flex;
  visibility: ${(props) => props.hidden && 'hidden'};
  justify-content: flex-end;
  & .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
  & .MuiButton-contained:hover {
    box-shadow: none;
  }
  & .second-buttons-group-button.MuiButtonBase-root {
    padding: 12px;
  }
`;
