import api from '../api';

export const getAgendaItemFile = (data) =>
  api.get('/api/File/Download/Attachment/AgendaItem', data, 'blob').then((res) => res.data);
export const getInvitationFile = (data) =>
  api.get('/api/File/Download/Attachment/Invitation', data, 'blob').then((res) => res.data);
export const getSomeFile = (data) =>
  api.get('/api/File/Download', data, 'blob').then((res) => res.data);
export const getBrandingLogoParticipantView = (data) =>
  api.get('api/File/Download/Logo', data, 'blob').then((res) => res.data);
export const uploadFiles = (data) => api.post('/api/File/Upload', data).then((res) => res.data);
export const uploadLogoImg = (data) =>
  api.post('api/File/UploadLogo', data).then((res) => res.data);
