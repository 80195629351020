import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { FormsButtonsGroup } from 'components/ButtonsGroup/ButtonsGroup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFieldWithButtonsGroup, InputGroupWrapper } from '../styledComponents';

export const InputFieldWithButtonGroup = ({
  autoFocus,
  clearInput,
  changeValue,
  error,
  inputValue,
  maxLength,
  name,
  onSaveButtonClick,
  hasValue,
  placeholder,
  upperCase,
  withoutValueChecking
}) => {
  const { t } = useTranslation();

  return (
    <InputGroupWrapper>
      <InputFieldWithButtonsGroup>
        <TextField
          autoFocus={autoFocus}
          variant="outlined"
          name={name}
          autoComplete="off"
          value={inputValue}
          placeholder={placeholder}
          onChange={changeValue}
          error={!!error?.length}
          inputProps={{
            style: {
              textTransform: upperCase ? 'uppercase' : 'none'
            },
            ...(!!maxLength && { maxLength: maxLength })
          }}
          InputProps={{
            endAdornment: hasValue && (
              <IconButton
                onClick={clearInput}
                edge="end"
                size="small"
                disableRipple={true}
                tabIndex="-1"
              >
                <CloseIcon />
              </IconButton>
            )
          }}
        />
        {!!error?.length && (
          <FormHelperText component="span" error className="error-hint">
            {error}
          </FormHelperText>
        )}
      </InputFieldWithButtonsGroup>
      {(hasValue || withoutValueChecking) && (
        <FormsButtonsGroup onSubmit={onSaveButtonClick} name={t('buttonsNames.ok')} />
      )}
    </InputGroupWrapper>
  );
};
