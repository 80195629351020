import Typography from '@material-ui/core/Typography';
import React from 'react';
import { BodyWrapper, InformationBlock, UploadLogoBlockWrapper } from './styledComponents';
import UploadLogoComponent from './UploadLogoComponent';

export const UploadLogoBlock = ({ title, placeHolder, hint }) => (
  <UploadLogoBlockWrapper>
    <Typography variant="body2" className="input-label">
      {title}
    </Typography>
    <BodyWrapper>
      <InformationBlock>
        <Typography variant="body1">{placeHolder}</Typography>
        <Typography variant="body2" className="block-title">
          {hint}
        </Typography>
      </InformationBlock>
      <UploadLogoComponent />
    </BodyWrapper>
  </UploadLogoBlockWrapper>
);
