import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from 'components/Buttons/CustomButton';
import { useTranslation } from 'react-i18next';
import { StyledMuiDialogTitle } from './styledComponents';

export const ModalTitle = ({ title, onClose, ...other }) => {
  const { t } = useTranslation();
  return (
    <StyledMuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{title}</Typography>
      {onClose ? (
        <CustomButton
          fullWidth
          name={t('buttonsNames.cancel')}
          onClick={() => onClose()}
          variant="text"
        />
      ) : null}
    </StyledMuiDialogTitle>
  );
};
