import {
  CLEAR_FOLLOW_UP_AND_DONE_ASSEMBLY_STORAGE,
  SET_FOLLOW_UP_AND_DONE_AGENDA_ITEMS_DATA,
  SET_FOLLOW_UP_AND_DONE_ASSEMBLY_DATA,
  SET_FOLLOW_UP_AND_DONE_BALLOTS_DATA
} from 'constants/types';
import api from '../../api';
import { setLoading } from './common';

export const onSendMeetingMinutesEmail = (data) => (dispatch) => {
  dispatch(setLoading(true));
  return api.commonAssemblies
    .sendMeetingMinutesEmail(data)
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      console.error('REQUEST ERROR', error);
    });
};

export function setFollowUpAndDoneAssemblyData(data) {
  return {
    type: SET_FOLLOW_UP_AND_DONE_ASSEMBLY_DATA,
    payload: data
  };
}

export function setFollowUpAgendaItemsData(data) {
  return {
    type: SET_FOLLOW_UP_AND_DONE_AGENDA_ITEMS_DATA,
    payload: data
  };
}

export function setFollowUpBallotsData(data) {
  return {
    type: SET_FOLLOW_UP_AND_DONE_BALLOTS_DATA,
    payload: data
  };
}

export function clearFollowUpAssemblyStorage(data) {
  return {
    type: CLEAR_FOLLOW_UP_AND_DONE_ASSEMBLY_STORAGE,
    payload: data
  };
}
