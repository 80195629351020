import api from 'api';

import {
  SET_ASSEMBLY_EDITING_DATA,
  CLEAR_PLANNED_ASSEMBLY_STORAGE,
  IS_DONT_SHOW_BATHES_EMAILS_ACTION_CONFIRMATION_POPUP,
  SET_AGENDA_ITEMS_DATA,
  SET_BATCH_ID_WAITING_TO_SEND,
  SET_INVITED_EMAILS_LIST,
  SET_LAST_UPDATED_VOTE_DATA,
  SET_ALERT_ERROR,
  SET_NEW_BATCHES_LIST,
  SET_OPEN_ADD_SHARES_POPUP,
  SET_PLANNED_ASSEMBLY_BALLOTS_DATA,
  UPDATE_SHARES_DATA
} from 'constants/types';
import { encryptData } from 'utils/decryptEncryptAES';

export const getVersionedAssemblyData = (data) => () => {
  return api.commonAssemblies
    .getVersionedAssembly({ data: encryptData(data) })
    .then((res) => {
      return res.assembly;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const getVersionedAgendaItemData = (data) => () => {
  return api.commonAssemblies
    .getVersionedAgendaItem({ data: encryptData(data) })
    .then((res) => {
      return res.agendaItem;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const getVersionedBallotData = (data) => () => {
  return api.commonAssemblies
    .getVersionedBallot({ data: encryptData(data) })
    .then((res) => {
      return res.vote;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
    });
};

export const onDeleteAgendaItem = (data) => () => {
  return api.commonAssemblies
    .deleteAgendaItem(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export const onDeleteParticipantFromBatch = (data) => () => {
  return api.commonAssemblies
    .deleteParticipantFromBatch(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export const onResendInvitation = (data) => () => {
  return api.commonAssemblies
    .reSendInvitation(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export const onSendCustomEmail = (data) => () => {
  return api.commonAssemblies
    .sendCustomEmail(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export const onDeleteBatch = (data) => () => {
  return api.commonAssemblies
    .deleteBatch(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export const onResendInvitationsToAllParticipants = (data) => () => {
  return api.commonAssemblies
    .resendInvitationsToAllParticipants(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('REQUEST ERROR', error);
      return { isError: true };
    });
};

export function setAgendaItemsData(data) {
  return {
    type: SET_AGENDA_ITEMS_DATA,
    payload: data
  };
}

export function setIsDontShowBatchesEmailsActionConfirmationPopup(data) {
  return {
    type: IS_DONT_SHOW_BATHES_EMAILS_ACTION_CONFIRMATION_POPUP,
    payload: data
  };
}

export function setNewBatches(data) {
  return {
    type: SET_NEW_BATCHES_LIST,
    payload: data
  };
}

export function setAssemblyEditingData(data) {
  return {
    type: SET_ASSEMBLY_EDITING_DATA,
    payload: data
  };
}

export function setInvitedEmailsList(data) {
  return {
    type: SET_INVITED_EMAILS_LIST,
    payload: data
  };
}

export function updateSharesData(data) {
  return {
    type: UPDATE_SHARES_DATA,
    payload: data
  };
}

export function setOpenSharesPopup(data) {
  return {
    type: SET_OPEN_ADD_SHARES_POPUP,
    payload: data
  };
}

export function setBatchIdWaitingToSend(data) {
  return {
    type: SET_BATCH_ID_WAITING_TO_SEND,
    payload: data
  };
};

export function setLastUpdatedVoteData(data) {
  return {
    type: SET_LAST_UPDATED_VOTE_DATA,
    payload: data
  };
};

export function setPlannedAssemblyBallotsData(data) {
  return {
    type: SET_PLANNED_ASSEMBLY_BALLOTS_DATA,
    payload: data
  };
};

export function setAlertError(data) {
  return {
    type: SET_ALERT_ERROR,
    payload: data
  };
}

export function clearPlannedAssemblyStorage(data) {
  return {
    type: CLEAR_PLANNED_ASSEMBLY_STORAGE,
    payload: data
  };
}
