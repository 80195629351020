import styled from 'styled-components';
import { COLORS } from 'constants/common';

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  & .MuiTypography-body2 {
    font-weight: 400;
    color: #000000;
  }
`;

export const RadioButtonsWrapper = styled.div`
  & .MuiFormGroup-root {
    flex-wrap: nowrap;
  }
  & .MuiRadio-root {
    width: 32px;
    height: 32px;
    padding: 0;
  }
  & .MuiRadio-root:not(:last-child) {
    margin-right: 8px;
  }
  & .icon {
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-weight: 400;
    color: #000000;
    border: 1px solid ${COLORS.borderLightGray};
    background: #ffffff;
  }
  & .checkedIcon {
    border: 1px solid ${COLORS.darkGreen};
    background: ${COLORS.lightGreen};
  }
`;
