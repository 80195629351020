import { uploadFilesToServer } from 'Redux/actions/files';
import { store } from 'store';
const fileDownload = require('js-file-download');

export const uploadFilesData = async (uploadedIds, files) => {
  const { dispatch } = store;
  for (let i = 0; i < files.length; i++) {
    const formData = new FormData();
    formData.append('file', files[i], files[i].name);

    const res = await dispatch(uploadFilesToServer(formData));
    if (res.isError) {
      break;
    } else {
      uploadedIds.push(res.id);
    }
  }
  return uploadedIds;
};

export const downloadFile = (data, name) => {
  fileDownload(data, name);
};

export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
