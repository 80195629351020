import {
  CLEAR_STORAGE,
  SET_IS_VOTE_GOING_ON,
  SET_LIVE_ASSEMBLY_START_DATE,
  SET_LIVE_ASSEMBLY_DATA,
  SET_ANNOUNCED_VOTING_AGENDA_ITEM_ID,
  SET_LIVE_ASSEMBLY_AGENDA_ITEMS_DATA,
  SET_LIVE_ASSEMBLY_VOTING_PARTICIPANTS_COUNTING,
  ADD_NEW_BALLOT,
  SET_ACTIVE_BALLOT_ID,
  SET_LIVE_ASSEMBLY_BALLOTS_DATA,
  CLEAR_LIVE_ASSEMBLY_DATA
} from 'constants/types';

const initialState = {
  announcedVotingAgendaItemId: null,
  liveAssemblyStartDate: null,
  liveAssemblyData: null,
  agendaItemsData: [],
  isAddNewBallot: null,
  votingParticipantsCounting: null,
  isVoteGoingOn: false,
  activeBallotId: null,
  ballots: {}
};

const liveAssemblyReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_LIVE_ASSEMBLY_START_DATE:
      return {
        ...state,
        liveAssemblyStartDate: payload
      };
    case SET_LIVE_ASSEMBLY_DATA:
      return {
        ...state,
        liveAssemblyData: payload?.assembly
      };
    case SET_LIVE_ASSEMBLY_AGENDA_ITEMS_DATA:
      return {
        ...state,
        agendaItemsData: [...payload]
      };
    case SET_ANNOUNCED_VOTING_AGENDA_ITEM_ID:
      return {
        ...state,
        announcedVotingAgendaItemId: payload
      };
    case ADD_NEW_BALLOT:
      return {
        ...state,
        isAddNewBallot: payload
      };
    case SET_LIVE_ASSEMBLY_VOTING_PARTICIPANTS_COUNTING:
      return {
        ...state,
        votingParticipantsCounting: payload
      };
    case SET_IS_VOTE_GOING_ON:
      return {
        ...state,
        isVoteGoingOn: payload
      };
    case SET_LIVE_ASSEMBLY_BALLOTS_DATA:
      return {
        ...state,
        ballots: { ...state.ballots, ...payload }
      };
    case SET_ACTIVE_BALLOT_ID:
      return {
        ...state,
        activeBallotId: payload
      };
    case CLEAR_LIVE_ASSEMBLY_DATA:
      return initialState;
    case CLEAR_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default liveAssemblyReducer;
