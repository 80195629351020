import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { COLORS } from 'constants/common';
import Button from '@material-ui/core/Button';

export const StyledSwitcher = styled(Switch)`
  &.MuiSwitch-root {
    display: flex;
    width: 40px;
    height: 20px;
    padding: 0;
    box-sizing: border-box;
    overflow: visible;
  }
  & .MuiSwitch-switchBase {
    padding: 3px 2px;
    color: ${COLORS.gray};
  }
  & .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
    box-shadow: none;
  }
  & .MuiSwitch-track {
    border: 1px solid grey;
    border-radius: 10px;
    opacity: 1;
    background-color: white;
  }
  & .MuiSwitch-input {
    left: 0;
    width: 40px;
  }
  & .MuiSwitch-switchBase.Mui-checked {
    width: 20px;
    color: #003f35;
    transform: translateX(20px);
    & .MuiSwitch-input {
      left: -20px;
    }
    & .MuiIconButton-label {
      margin-right: 5px;
    }
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${COLORS.lightGreen};
  }
`;

export const CustomButton = styled(Button)`
  &.MuiButton-outlined {
    padding: 12px;
    background: ${COLORS.lightGreen};
    border: 1px solid #003f35;
    box-sizing: border-box;
    border-radius: 4px;
    &:hover {
      background: ${COLORS.lightGreen};
      border: 1px solid #003f35;
    }
  }
  & img {
    margin-right: 8px;
  }
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  & .MuiButton-root.MuiButton-textSecondary {
    margin-left: 15px;
  }
  & .MuiButtonGroup-root {
    & .MuiButton-root:first-child {
      border-radius: 0;
    }
  }
  & .MuiFormHelperText-root.Mui-error {
    margin-bottom: 10px;
  }
  & .MuiButtonGroup-contained {
    box-shadow: none;
    border-radius: 0 4px 4px 0;
  }
  &.with-disabled-buttons .MuiButtonGroup-contained {
    border: 1px solid ${COLORS.textLightGray};
    margin-left: -1px;
    z-index: 100;
  }
  & .MuiButton-contained.Mui-disabled {
    background-color: ${COLORS.lightGray};
    color: #999999;
  }
`;

export const InputFieldWithButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
  color: ${COLORS.gray};
  & .MuiSelect-selectMenu {
    letter-spacing: 0.5px;
  }
  & .MuiInputBase-root {
    font-size: 16px;
    height: 48px;
    color: #000000;
    border-radius: 4px 0 0 4px;
    &::placeholder {
      color: ${COLORS.textLightGray};
    }
    input {
      padding: 20px 16px;
    }
  }
  & .MuiMenuItem-root {
    color: #000000;
  }
`;

export const VotingDataItemGroupBlock = styled.div`
  display: flex;
  max-width: ${(props) => (props.isBasedOnShares
  ? '68%'
  : '92%')};
`;

export const VotingDataHeader = styled.div`
  display: ${(props) => (props.hideHeader
  ? 'none'
  : 'flex')};
  width: ${(props) => (props.fullWidth
  ? '100%'
  : '84%')};
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 5px;
  box-sizing: border-box;
  & .MuiTypography-body2 {
    color: #000000;
  }
  & .MuiTypography-body2:first-child {
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VotingDataWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => props.isPreviewItemsView && '496px'};
  margin-left: ${(props) => props.isPreviewItemsView && '48px'};
  flex-direction: column;
  padding: ${(props) => (props.isActiveItem
  ? '20px 5px'
  : '20px 0')};
  box-sizing: border-box;
  background-color: ${(props) => (props.isActiveItem
  ? COLORS.lightYellow
  : 'transparent')};
`;

export const VotersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: ${(props) => (props.isWinner
  ? '32px'
  : '16px')};
  &:last-child {
    margin-bottom: 0;
  }
  & .MuiTypography-body1.voter-name {
    color: #000000;
    margin: 10px 0 0 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .MuiTypography-body1.preview-results {
    margin: 10px 0 0 55px;
  }
  & .voting-option-item {
    background: #ffffff;
  }
  & .my-vote-label {
    display: ${(props) => (props.isMyVoteVisible
  ? 'inline-flex'
  : 'none')};
    margin-left: 16px;
    background-color: ${COLORS.darkGreen};
  }
  @media (max-width: 768px) {
    margin-bottom: ${(props) => (props.isWinner
  ? '16px'
  : '8px')};
    & .outside-item-label {
      display: none;
    }
  }
  @media (min-width: 769px) {
    margin-bottom: ${(props) => (props.isWinner
  ? '16px'
  : '8px')};
    & .inside-item-label {
      display: none;
    }
  }
`;

export const VotingDataItemWithLabel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const VotingDataItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => (props.fullWidth
  ? '100%'
  : '84%')};
  align-items: start;
  min-height: 34px;
  background: ${(props) => (props.isWinner
  ? COLORS.lightGreen
  : COLORS.lightGray)};
  border: 1px solid ${(props) => (props.isWinner
  ? COLORS.darkGreen
  : COLORS.borderLightGray)};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 10px;
  & img {
    height: 24px;
    margin-right: 10px;
  }
  & .MuiTypography-body1 {
    ${(props) => props.isWinner && 'font-weight: bold'};
    color: #000000;
    & span {
      color: ${COLORS.gray};
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    & .results-value {
      max-width: 70px;
    }
  }
`;

export const BigVotingDataItem = styled(VotingDataItem)`
  width: 100%;
  min-height: 64px;
  padding: 20px;
  background: ${(props) => (props.isWinner
  ? COLORS.lightGreenBackground
  : COLORS.lightGray)};
  border: 1px solid ${(props) => (props.isWinner
  ? COLORS.greenBorder
  : COLORS.borderLightGray)};
  & .MuiTypography-h4 {
    font-weight: ${(props) => (props.isWinner
  ? 'bold'
  : 'normal')};
    color: #000000;
    line-height: 24px;
    & span {
      color: ${COLORS.gray};
    }
  }
`;

export const CollapsedDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  background-color: #ffffff;
  box-sizing: border-box;
  border-top: 1px solid ${(props) => (props.isActive
  ? COLORS.borderLightYellow
  : COLORS.hintsLightGray)};
  padding: ${(props) => (props.isActive
  ? '16px 42px'
  : '16px 24px')};
  //&.participant-view-collapsed-block {
  //  border-radius: 0 0 4px 4px;
  //}
  & > .MuiTypography-h4 {
    margin: 40px auto;
    line-height: 24px;
    font-weight: normal;
  }
  & .MuiButton-outlined {
    padding: 12px 64px;
    width: 200px;
    height: 48px;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    padding: 0 16px;
  }
  @media (max-width: 576px) {
    padding: 0 8px;
  }
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  margin-bottom: 16px;
  color: ${COLORS.gray};
  & .MuiSelect-selectMenu {
    letter-spacing: 0.5px;
  }
  & .MuiInputBase-root {
    font-size: 20px;
    height: 64px;
    color: #000000;
    &::placeholder {
      color: ${COLORS.textLightGray};
    }
    input {
      padding: 20px 16px;
    }
  }
  & .test-invitation-input .MuiInputBase-root {
    font-size: 16px;
    height: 48px;
    input {
      padding: 0 16px;
    }
  }
  & .error-hint.Mui-error {
    margin-bottom: 0;
  }
  & .MuiMenuItem-root {
    color: #000000;
  }
`;

export const ScrollableFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 60vh;
  @media (min-height: 960px) {
    max-height: 60vh;
  }
  @media (min-height: 1200px) {
    max-height: 65vh;
  }
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  & label {
    margin-bottom: 5px;
  }
`;

export const StyledSnackbarAlert = styled(Snackbar)`
  &.MuiSnackbar-root {
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    background: #ffe9c4;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px 120px;
    box-sizing: border-box;
  }
  & .MuiTypography-body1 {
    color: #000000;
    text-align: center;
  }
  @media (max-width: 820px) {
    &.MuiSnackbar-root {
      padding: 20px 10px;
    }
  }
`;

export const ErrorEmailsWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  align-items: center;
  & img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  & .error-hint,
  .remove-error-emails {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
  & .remove-error-emails {
    margin-left: 5px;
    color: ${COLORS.blue};
    cursor: pointer;
  }
`;
