const CryptoJS = require('crypto-js');

const key = [
  171, 30, 160, 169, 209, 7, 16, 161, 225, 164, 21, 231, 117, 40, 111, 253, 39, 233, 20, 77, 199,
  132, 134, 39, 29, 198, 13, 142, 104, 8, 42, 1
];
const iv = [243, 41, 130, 197, 75, 147, 222, 65, 28, 3, 90, 40, 210, 209, 107, 73];

const stringKey = CryptoJS.lib.WordArray.create(new Uint8Array(key));
const stringIv = CryptoJS.lib.WordArray.create(new Uint8Array(iv));

export const decryptDataFromUri = (uri, isJSON) => {
  const plain = CryptoJS.AES.decrypt(uri, stringKey, { iv: stringIv });
  return isJSON ? JSON.parse(plain.toString(CryptoJS.enc.Utf8)) : plain.toString(CryptoJS.enc.Utf8);
};

export const encryptData = (data) => {
  const stringlyfiedData = JSON.stringify(data);
  return CryptoJS.AES.encrypt(stringlyfiedData, stringKey, { iv: stringIv }).toString();
};
