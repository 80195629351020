import { SET_MY_ACCOUNT_DATA, SET_COMPANY_LOGO_URL, CLEAR_STORAGE } from 'constants/types';

const initialState = {
  myAccountData: null,
  logoURL: null
};

const myAccountReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_MY_ACCOUNT_DATA:
      return {
        ...state,
        myAccountData: payload
      };
    case SET_COMPANY_LOGO_URL:
      return {
        ...state,
        logoURL: payload
      };
    case CLEAR_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default myAccountReducer;
