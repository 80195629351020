import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from 'components/Buttons/CustomButton';
import { LANGUAGES_LIST, LOCALIZATIONS_FLAGS } from 'constants/common';
import {
  ChooseLanguageRow,
  Flag,
  LocalizationSelectWrapper,
  StyledMenu,
  StyledPopover
} from 'GeneralLayout/styledComponents';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { MenuItemValueWrapper } from 'GeneralLayout/styledComponents';
import DoneIcon from 'img/done-icon.svg';
import { StyledMenuItem } from 'GeneralLayout/styledComponents';
import { useUpdateMyAccount } from 'hooks/useUpdateMyAccountData';

export const LocalizationSelect = ({ userLanguage }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState(null);
  const { updateAccount } = useUpdateMyAccount();
  const { t, i18n } = useTranslation();

  const parentRef = useRef(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedLanguage(userLanguage);
  }, [userLanguage]);

  const handleClick = () => {
    setAnchorEl(parentRef.current);
  };
  const chooseLanguage = (id) => {
    setSelectedLanguage(id);
    i18n.changeLanguage(id);
    updateAccount({ language: id });
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <LocalizationSelectWrapper>
      <StyledPopover
        id="menu-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <StyledMenu autoFocusItem={open} open={open} onClose={handleClose}>
              {LANGUAGES_LIST.map(({ id }) => (
                <StyledMenuItem
                  onClick={() => chooseLanguage(id)}
                  disableRipple={true}
                  selected={selectedLanguage === id}
                  key={id}
                >
                  <MenuItemValueWrapper>
                    <Flag file={LOCALIZATIONS_FLAGS[id]} />
                    {t(
                      `languagesNames.${id}`,
                      `languagesNames.${localStorage.getItem('i18nextLng')}`
                    )}
                  </MenuItemValueWrapper>
                  {selectedLanguage === id && <img src={DoneIcon} alt="Done" />}
                </StyledMenuItem>
              ))}
            </StyledMenu>
          </ClickAwayListener>
        </Paper>
      </StyledPopover>
      <ChooseLanguageRow id={'language-row'} ref={parentRef}>
        {selectedLanguage && <Flag file={LOCALIZATIONS_FLAGS[selectedLanguage]} />}
        <Typography variant="body2">
          {t(
            `languagesNames.${selectedLanguage}`,
            `languagesNames.${localStorage.getItem('i18nextLng')}`
          )}
        </Typography>
        <Divider orientation="vertical" />
        <CustomButton
          id="select-language-button"
          aria-controls="language-menu"
          name={t('buttonsNames.changeLanguage')}
          onClick={handleClick}
          variant="text"
        />
      </ChooseLanguageRow>
    </LocalizationSelectWrapper>
  );
};
