import {
  IS_DONT_SHOW_BATHES_EMAILS_ACTION_CONFIRMATION_POPUP,
  SET_ASSEMBLY_STATUS_FILTER_VALUE,
  SET_LOADING
} from 'constants/types';

const commonReducer = (
  state = {
    assemblyStatusFilterValue: 0,
    isDontShowBatchesEmailsActionConfirmationPopup: false,
    loading: false
  },
  { type, payload }
) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload
      };
    case SET_ASSEMBLY_STATUS_FILTER_VALUE:
      return {
        ...state,
        assemblyStatusFilterValue: payload
      };
    case IS_DONT_SHOW_BATHES_EMAILS_ACTION_CONFIRMATION_POPUP:
      return {
        ...state,
        isDontShowBatchesEmailsActionConfirmationPopup: payload
      };
    default:
      return state;
  }
};

export default commonReducer;
