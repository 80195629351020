import axios from 'axios';
import i18next from 'i18next';
import { store } from 'store';
import history from 'utils/history';
import { USER_LOGOUT, PARTICIPANT_LOGOUT } from 'constants/types';
import { STORAGES_KEYS_VALUES, TOKEN_KEY } from 'constants/common';


export const API_BASE_ADDRESS = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: API_BASE_ADDRESS,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  }
});

const getToken = (isParticipantSide) => {
  if (isParticipantSide) {
    return sessionStorage.getItem(TOKEN_KEY);
  } else {
    return localStorage.getItem(TOKEN_KEY);
  }
};

axiosInstance.interceptors.request.use(
  async (data) => {
    const isParticipant = data?.data?.isParticipantSide || data?.params?.isParticipantSide;

    if (data?.data?.isParticipantSide) {
      delete data.data.isParticipantSide;
    }

    const token = getToken(isParticipant);
    if (token) {
      data.headers['Authorization'] = `Bearer ${token}`;
    }
    if (data.url.includes('api/File')) {
      data.headers['Content-Type'] = 'multipart/form-data; boundary=-------------573cf973d5228';
    }
    return data;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {

    const isFileDownload = response.config.url?.includes('Download');

    const errorsList = ['INCORRECT_AUTH_TOKEN', 'ACCESS_DENIED'];

    if (!response?.data.success && errorsList.includes(response?.data.errorCode)) {

      if (response.config.params.isParticipantSide) {
        store.dispatch({ type: PARTICIPANT_LOGOUT });
        const assemblyId = sessionStorage.getItem(STORAGES_KEYS_VALUES.assemblyId);
        history.push({
          pathname: `/access/${assemblyId}`,
          state: {
            authStorageError: i18next.t([
              `requestsErrors.${response?.data.errorCode}`,
              'requestsErrors.ANOTHER_ERROR'
            ])
          }
        });
      } else {
        store.dispatch({ type: USER_LOGOUT });
        history.push(`/`);
      }
      return response;
    }
    if (!response?.data?.success && !isFileDownload) {
      return Promise.reject(response);
    }
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default {
  get: (url, params, type) =>
    axiosInstance.get(url, {
      params,
      ...(type ? { responseType: type } : {})
    }),
  post: (url, data, config) => axiosInstance.post(url, data, config),
  put: (url, data, config) => axiosInstance.put(url, data, config),
  delete: (url, params) => axiosInstance.delete(url, { data: params })
};
