import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

export const PasswordVisibilityIcon = ({ handleClickShowPassword, isShowPassword, tabIndex }) => (
  <InputAdornment position="end" tabIndex={tabIndex}>
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      edge="end"
      size="small"
      disableRipple={true}
    >
      {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>
  </InputAdornment>
);
