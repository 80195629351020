import { format, getDate, getMonth, getYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { TIME_ZONES_LIST } from 'constants/assemblies';
import { DATE_FNS_LOCALES_IDS } from 'constants/common';

export const getTimeZoneBiasByKey = (key) =>
  TIME_ZONES_LIST.find((item) => item.value === key).timeZoneBias;
export const getValueByTimeZoneBias = (value) =>
  TIME_ZONES_LIST.find((item) => item.timeZoneBias === value).value;
export const getTimeZoneByBiasValue = (value) =>
  TIME_ZONES_LIST.find((item) => item.timeZoneBias === value).label;

export const getTimeZoneOffsetValueForSelect = () => {
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  return getValueByTimeZoneBias((-timezoneOffset).toString());
};

export const digitValue = (value) => {
  if (value < 10) {
    return `0${value}`;
  } else {
    return value;
  }
};

export const getDataToSaveAfterAssemblyDateChange = (
  storedNewAssemblyData,
  dateString,
  assemblyDateAndTimeData,
  withoutDate
) => {
  if (storedNewAssemblyData?.plannedTime?.endTimeUtc) {
    const startTime = assemblyDateAndTimeData?.start.replace(/\./, ':');
    const endTime = assemblyDateAndTimeData?.end.replace(/\./, ':');
    const timezone = assemblyDateAndTimeData?.timezone;

    const startDateZonedString = `${dateString}T${startTime}:00${timezone}`;
    const endDateZonedString = `${dateString}T${endTime}:00${timezone}`;

    const updateStringDate = (date) => {
      const cutString = date.split('T')[1];
      return `${dateString}T${cutString}`;
    };

    const startTimeUTC = withoutDate
      ? updateStringDate(new Date(startDateZonedString).toISOString())
      : new Date(startDateZonedString).toISOString();

    const endTimeUTC = withoutDate
      ? updateStringDate(new Date(endDateZonedString).toISOString())
      : new Date(endDateZonedString).toISOString();

    const data = {
      ...(storedNewAssemblyData || {}),
      plannedTime: {
        startTimeUtc: startTimeUTC,
        endTimeUtc: endTimeUTC
      },
      timeZoneBias: storedNewAssemblyData.timeZoneBias
    };
    return data;
  } else {
    const date = new Date(dateString);
    const isoDate = date.toISOString();
    const data = {
      ...(storedNewAssemblyData || {}),
      plannedTime: {
        startTimeUtc: isoDate
      }
    };

    return data;
  }
};

export const getDateString = (data) => {
  if (data) {
    const convertDateValue = (value) => {
      if (value < 10) {
        return `0${value}`;
      } else return value;
    };

    const timeZone = getTimeZoneByBiasValue(data?.timeZoneBias.toString());
    const timeZoneValue = getValueByTimeZoneBias(data?.timeZoneBias.toString());
    const convertedToZonedStartTime = utcToZonedTime(
      data?.plannedTime?.startTimeUtc,
      timeZoneValue
    );
    const convertedToZonedEndTime = utcToZonedTime(data?.plannedTime?.endTimeUtc, timeZoneValue);

    const startTime = format(convertedToZonedStartTime, 'HH.mm');
    const endTime = format(convertedToZonedEndTime, 'HH.mm');
    const day = convertDateValue(getDate(convertedToZonedStartTime));
    const month = convertDateValue(getMonth(convertedToZonedStartTime) + 1);
    const year = getYear(convertedToZonedStartTime);

    const dateStringLocalized = format(convertedToZonedStartTime, 'EEEE, dd MMMM yyyy', {
      locale: DATE_FNS_LOCALES_IDS[localStorage.getItem('i18nextLng') || 'en']
    });

    const dateString = format(convertedToZonedStartTime, 'EEEE, dd MMMM yyyy');

    return {
      dateStringLocalized: `${dateStringLocalized}, ${startTime} - ${endTime} ${timeZone}`,
      dateString: `${dateString}, ${startTime} - ${endTime} ${timeZone}`,
      timeZoneValue,
      timeZone,
      startTime,
      endTime,
      day,
      month,
      year
    };
  }
};

export const getTimeValues = (start, end) => {
  return {
    isWrongValues: [
      start.split('.')[0],
      start.split('.')[1],
      end.split('.')[0],
      end.split('.')[1]
    ].some((item) => item.length < 2),
    startHours: start.split('.')[0],
    startMinutes: start.split('.')[1],
    endHours: end.split('.')[0],
    endMinutes: end.split('.')[1]
  };
};

export const parseDateForAssemblyList = (startDate, endDate, timeZoneValue, timeZone) => {
  const isWithoutDate = !startDate || startDate.includes('0001-01-01');

  if (isWithoutDate && !endDate) {
    return '';
  }
  if (!isWithoutDate && !endDate) {
    return format(utcToZonedTime(startDate, timeZoneValue), 'EEEE, d LLL yyyy', {
      locale: DATE_FNS_LOCALES_IDS[localStorage.getItem('i18nextLng') || 'en']
    });
  }
  if (isWithoutDate && endDate) {
    return `${format(utcToZonedTime(startDate, timeZoneValue), 'HH.mm')} ${timeZone}`;
  }
  return `${format(utcToZonedTime(startDate, timeZoneValue), 'EEEE, d LLL yyyy, HH.mm', {
    locale: DATE_FNS_LOCALES_IDS[localStorage.getItem('i18nextLng') || 'en']
  })} ${timeZone}`;
};

export const existingDateValue = (day, month, year) => {
  const inputtedDate = new Date(year, month - 1, day);
  if (
    inputtedDate.getFullYear() === +year
    && inputtedDate.getMonth() === +month - 1
    && inputtedDate.getDate() === +day
  ) {
    return true;
  }
  return false;
};
