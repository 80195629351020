export const MY_ACCOUNT_DETAILS_FIELDS_NAMES = [
  { key: 'userName', initialValue: '' },
  { key: 'email', initialValue: '' }
];

export const MY_ACCOUNT_BRANDING_FIELDS_NAMES = [
  { key: 'companyName', enableEmptyData: true, initialValue: '' },
  { key: 'companyAddress', enableEmptyData: true, initialValue: '' },
  { key: 'businessId', enableEmptyData: true, initialValue: '' },
  { key: 'companyLogo', enableEmptyData: true, initialValue: '' },
  { key: 'backgroundColour', enableEmptyData: false, initialValue: '#054062', upperCase: true },
  { key: 'textColour', enableEmptyData: false, initialValue: '#FFFFFF', upperCase: true }
];

export const MY_ACCOUNT_FIELDS_PATTERNS = {
  businessId: /([^A-Za-z0-9-\s])/,
  userName: /([^A-Za-z-'\s])/,
  backgroundColour: /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
  textColour: /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
  email:
    // eslint-disable-next-line max-len,no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

export const MY_ACCOUNT_VOTING_MINUTES_VALUES = [
  { name: 'one', value: '1' },
  { name: 'two', value: '2' },
  { name: 'three', value: '3' },
  { name: 'five', value: '5' }
];

export const DEFAULT_VOTING_TIME = '3';
