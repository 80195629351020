import styled from 'styled-components';
import { COLORS } from 'constants/common';

export const UploadLogoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & .MuiButton-root.MuiButton-textSecondary {
    margin-left: 0;
  }
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  border: 1px dashed ${COLORS.borderLightGray};
  box-sizing: border-box;
  width: 120px;
  height: 56px;
  margin-bottom: 8px;
`;

export const StyledDropZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url('${props.url}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
