import React from 'react';
import { useTranslation } from 'react-i18next';
import BlackLogoShort from 'img/black-logo-short.svg';
import LockIcon from 'img/lock-icon.svg';
import {
  ParticipantViewRightsInfoLineWrapper,
  RightsIcons
} from 'ParticipantView/styledComponents';
import { BlockWithIcon } from 'ParticipantView/components/BlockWithIcon';

export const RightsInfoLine = () => {
  const { t } = useTranslation();
  return (
    <ParticipantViewRightsInfoLineWrapper>
      <RightsIcons>
        <BlockWithIcon
          icon={LockIcon}
          label={t('labels.secureSSLEncryption')}
          className="ssl-encryption-label"
        />
        <BlockWithIcon
          icon={BlackLogoShort}
          label={t('labels.poweredBy')}
          className="powered-by-label"
        />
      </RightsIcons>
    </ParticipantViewRightsInfoLineWrapper>
  );
};
