import Fade from '@material-ui/core/Fade/Fade';
import { PageSpinner } from 'components/Spinner/Spinner';
import { ASSEMBLY_STATUSES_NAMES } from 'constants/assemblies';
import { useUpdateParticipantViewBrandingData } from 'hooks/useUpdateParticipantViewBrandingData';
import LockIcon from 'img/lock-icon.svg';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  setEndVotingData,
  setIsParticipantVoted,
  setSentAdminMessage
} from 'Redux/actions/participant';
import {
  activeAgendaItemIdSelector,
  adminMessageSelector,
  endVotingDataSelector,
  isLoadingSelector,
  participantAssemblyDataSelector,
  participantViewBrandingDataSelector
} from 'Redux/selectors/participant';
import { createStructuredSelector } from 'reselect';
import history from 'utils/history';
import { AgendaTitle } from './components/AgendaTitle';
import { BlockWithIcon } from './components/BlockWithIcon';
import { EndVotingMessage } from './components/EndVotingMessage';
import { MessagePopUp } from './components/MessagePopUp';
import { ParticipantViewHeader } from '../components/ParticipantSideViewComponents/ParticipantViewHeader';
import { RightsInfoLine } from '../components/ParticipantSideViewComponents/RightsInfoLine';
import {
  ParticipantViewContentWrapper,
  ParticipantViewWrapper,
  RightsInfoBottomLine
} from './styledComponents';

const ParticipantViewContainer = ({
  actionSetEndVotingData,
  actionSetIsParticipantVoted,
  actionSetSentAdminMessage,
  activeAgendaItemId,
  adminMessage,
  assemblyId,
  children,
  endVotingData,
  isLoading,
  participantAssemblyData,
  participantViewBrandingData,
  props,
  t,
  withAgendaTitle
}) => {
  const { updateBrandingData } = useUpdateParticipantViewBrandingData();

  const MOBILE_MAX_SIZE = 820;

  const timer = useRef({});

  useEffect(() => {
    if (
      participantAssemblyData
      && [ASSEMBLY_STATUSES_NAMES.Planned, ASSEMBLY_STATUSES_NAMES.Draft].includes(
        participantAssemblyData?.status
      )
    ) {
      updateBrandingData(assemblyId);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    if (endVotingData) {
      timer.current = setTimeout(() => {
        if (props.match.params.voteId) {
          actionSetIsParticipantVoted(false);
          sessionStorage.removeItem('PARTICIPANT_BALLOT_TIMER_START');
          localStorage.removeItem('CHOSEN_VOTE_VALUE');
          history.push(`/assembly/${participantAssemblyData.id}`);
        }
        actionSetEndVotingData(null);
      }, 7000);
    }
  }, [endVotingData]);

  useEffect(() => {
    const element = document.getElementById(activeAgendaItemId);
    if (element) {
      const parentElement =
        window.screen.width <= MOBILE_MAX_SIZE
          ? document.getElementById('participant-view-content-wrapper')
          : element.parentNode;
      parentElement.scrollTop = element.offsetTop;
    }
  }, [activeAgendaItemId]);

  return (
    <ParticipantViewWrapper backgroundColour={participantViewBrandingData?.backgroundColour}>
      {isLoading && (
        <Fade in={isLoading}>
          <PageSpinner />
        </Fade>
      )}
      <RightsInfoLine />
      {
        <ParticipantViewHeader
          className="full-size-header"
          logo={participantViewBrandingData?.logoURL}
          companyName={participantViewBrandingData?.companyName}
          companyAddress={participantViewBrandingData?.companyAddress}
          businessId={participantViewBrandingData?.businessId}
          textColour={participantViewBrandingData?.textColour}
          backgroundColour={participantViewBrandingData?.backgroundColour}
        />
      }
      <ParticipantViewContentWrapper id="participant-view-content-wrapper">
        {!!endVotingData && (
          <EndVotingMessage
            title={t('dialogsAndPopups.yourHostHasBeenEndedBallot.title')}
            message={t('dialogsAndPopups.yourHostHasBeenEndedBallot.content', {
              hostName: endVotingData.hostName || '',
              ballotName: endVotingData.ballotName,
              agendaItemName: endVotingData.agendaItemName
            })}
          />
        )}
        {adminMessage && (
          <MessagePopUp
            isOpen={adminMessage}
            handleClose={() => actionSetSentAdminMessage(null)}
            message={adminMessage}
          />
        )}
        <ParticipantViewHeader
          className="mobile-size-header"
          logo={participantViewBrandingData?.logoURL}
          companyName={participantViewBrandingData?.companyName}
          companyAddress={participantViewBrandingData?.companyAddress}
          businessId={participantViewBrandingData?.businessId}
          textColour={participantViewBrandingData?.textColour}
          backgroundColour={participantViewBrandingData?.backgroundColour}
        />
        {withAgendaTitle && <AgendaTitle participantAssemblyData={participantAssemblyData} />}
        {children}
      </ParticipantViewContentWrapper>
      <RightsInfoBottomLine>
        <BlockWithIcon icon={LockIcon} label={t('labels.secureSSLEncryption')} />
      </RightsInfoBottomLine>
    </ParticipantViewWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  adminMessage: adminMessageSelector(),
  participantAssemblyData: participantAssemblyDataSelector(),
  isLoading: isLoadingSelector(),
  participantViewBrandingData: participantViewBrandingDataSelector(),
  endVotingData: endVotingDataSelector(),
  activeAgendaItemId: activeAgendaItemIdSelector()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionSetSentAdminMessage: setSentAdminMessage,
      actionSetIsParticipantVoted: setIsParticipantVoted,
      actionSetEndVotingData: setEndVotingData
    },
    dispatch
  );

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ParticipantViewContainer);
