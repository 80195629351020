import { useDispatch, useSelector } from 'react-redux';
import { setMyAccountData, updateAccountData } from 'Redux/actions/myAccount';
import { setLoading } from 'Redux/actions/common';

export const useUpdateMyAccount = () => {
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.myAccountStore.myAccountData);

  const updateAccount = async (newData) => {
    dispatch(setLoading(true));
    const updatedData = { ...accountData, ...newData };
    const response = await dispatch(updateAccountData({ account: updatedData }));
    if (response?.data && !response?.data?.success) {
      dispatch(setLoading(false));
      return response.data;
    }
    dispatch(setMyAccountData({ ...updatedData }));
    dispatch(setLoading(false));
  };

  return { updateAccount };
};
