import { ADD_EDIT_ASSEMBLY_FIELDS_NAMES } from 'constants/assemblies';
import { UP_AND_DOWN_KEY_NAMES, ABSTAIN_OF_VOTING_OPTION_NAMES } from 'constants/common';
import i18next from 'i18next';

export const isValidEmail = (email) => {
  const strongEmailValidation =
    // eslint-disable-next-line max-len,no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return strongEmailValidation.test(email);
};

// eslint-disable-next-line no-useless-escape
const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
export const passwordStrongValidation = (password) => {
  return strongRegex.test(password);
};

export const clientSidePasswordValidation = (newPassword, confirmPassword) => {
  switch (true) {
    case !newPassword.length:
      return { newPassword: i18next.t('clientSideValidationErrors.noEmptyFieldPass') };
    case !passwordStrongValidation(newPassword):
      return { newPassword: i18next.t('clientSideValidationErrors.passwordNotStrong') };
    case confirmPassword !== newPassword:
      return {
        confirmPassword: i18next.t('clientSideValidationErrors.differentPasswords')
      };
    case newPassword.length > 200:
      return { newPassword: i18next.t('clientSideValidationErrors.tooLongPassword') };
    default:
      return null;
  }
};

export const checkSideBarType = (role, path) => {
  if (role && path.includes('/dashboard/my-account')) {
    return 'medium';
  }
  if (role && path.includes('/dashboard/')) {
    return 'small';
  }
  if (!role) {
    return 'full';
  }
};

export const checkNoSideBarLayout = (role, path) => {
  if (
    path.includes('/live-assembly/')
    || path.includes('/assembly/')
    || path.includes('/access')
    || path.includes('/close-assembly-session')
    || path.includes('download/attachment/invitation')
    || path.includes('/assembly-preview/')
    || path.includes('preview/')
  ) {
    return true;
  }
};

export const checkStepsNavigationByArrowsDisabled = (fieldName) => {
  return !!fieldName && Object.values(ADD_EDIT_ASSEMBLY_FIELDS_NAMES).includes(fieldName);
};
export const checkUpDownArrowPressed = (key) => {
  return Object.values(UP_AND_DOWN_KEY_NAMES).includes(key);
};
export const failedEmailsList = (emailsList) => emailsList.filter(({ invalid }) => invalid).length;
export const duplicatedEmailsList = (emailsList) =>
  emailsList.filter(({ duplicated }) => duplicated).length;

export const isDuplicatedEmail = (invitedList, newEmail) => invitedList.includes(newEmail);

export const removeDuplicatedEmails = (data, callback) =>
  Object.keys(
    data?.reduce((acc, { email }) => {
      acc[email] = true;

      return acc;
    }, {})
  ).map((key) => callback(key));

export const isDuplicatedLocation = (locations, newLocationName) =>
  locations.includes(newLocationName);

export const sortSharesArray = (array) => array.sort((a, b) => a.email.localeCompare(b.email));

export const getTranslatedOption = (option, t) => {
  if (ABSTAIN_OF_VOTING_OPTION_NAMES.includes(option)) {
    return t([`switcherLabels.${option}`, 'switcherLabels.abstain']);
  } else {
    return option;
  }
};
