import Radio from '@material-ui/core/Radio/Radio';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { MY_ACCOUNT_VOTING_MINUTES_VALUES } from 'constants/myAccount';
import { MyAccountBlockWrapper, MyAccountDataItemRow } from 'MyAccountView/styledComponents';
import { InfoBlock, RadioButtonsWrapper } from './styledComponents';

export const BallotVotingTime = ({ selectedVotingTime, changeValue, t }) => (
  <MyAccountBlockWrapper>
    <MyAccountDataItemRow>
      <Typography variant="body1">{t('titles.ballotVotingTime')}</Typography>
      <RadioButtonsWrapper>
        <RadioGroup
          row
          onChange={(e) => changeValue(e.target.value)}
          value={selectedVotingTime || ''}
        >
          {MY_ACCOUNT_VOTING_MINUTES_VALUES.map((item) => (
            <Radio
              value={item.value}
              key={item.name}
              checkedIcon={<span className={'icon checkedIcon'}>{item.value}</span>}
              icon={<span className={'icon radio-btn'}>{item.value}</span>}
            />
          ))}
        </RadioGroup>
      </RadioButtonsWrapper>
    </MyAccountDataItemRow>
    <InfoBlock>
      <Typography variant="body2">{t('hints.numberOfMinutesForVotingHelper')}</Typography>
    </InfoBlock>
  </MyAccountBlockWrapper>
);
