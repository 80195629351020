import api from '../api';

export const getAssemblyData = (data) => api.get('/api/assembly/Get', data).then((res) => res.data);
export const getBrandingData = (data) =>
  api.get('/api/Tools/GetAssemblyBranding', data).then((res) => res.data);
export const getAgendaItemData = (data) =>
  api.get('/api/AgendaItem/Get', data).then((res) => res.data);
export const getJoinedParticipants = (data) =>
  api.get('/api/Assembly/GetActiveParticipants', data).then((res) => res.data);
export const sendMessageToParticipants = (data) =>
  api.post('/api/Assembly/SendMessage', data).then((res) => res.data);
