import ButtonGroup from '@material-ui/core/ButtonGroup';
import styled from 'styled-components';
import { NEW_ASSEMBLY_TEXT_FIELD_WIDTH } from 'constants/assemblies';
import MenuItem from '@material-ui/core/MenuItem';
import { COLORS } from 'constants/common';
import Divider from '@material-ui/core/Divider';
import { InputFieldWrapper } from 'components/styledComponents';

export const RightSideBarActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  margin-bottom: 50px;
  & .delete-button {
    width: inherit;
    color: ${COLORS.error};
  }
  @media (max-height: 960px) {
    margin-bottom: 80px;
  }
`;

export const ProgressBarItemWrapper = styled.div`
  cursor: pointer;
  & .MuiTimelineItem-root {
    width: 100%;
  }
  & .MuiTimelineSeparator-root {
    margin-left: -6px;
    width: 12px;
    height: 12px;
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  }
  & .MuiTimelineContent-root {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 6px 32px;
  }
`;

export const ItemNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  & .MuiTypography-body1 {
    color: ${COLORS.darkBlue};
    font-weight: ${(props) => (props.active ? 700 : 400)};
  }
  & .MuiTypography-body2 {
    color: #98a0a6;
    font-weight: 400;
  }
`;

export const StepFormWrapper = styled.div`
  display: flex;
  max-width: 1100px;
  flex-direction: ${(props) => (props.isHorizontalOrientation ? 'row' : 'column')};
  margin-left: 40px;
  margin-bottom: 20px;
  & form {
    width: ${(props) => (props.isSmallForm ? 'fit-content' : '100%')};
  }
  & .MuiButton-contained:hover {
    box-shadow: none;
  }
  & .second-buttons-group-button {
    cursor: default;
  }
  & .MuiTypography-h3 {
    line-height: 32px;
    color: #000000;
    margin-left: 16px;
    margin-bottom: 15px;
    white-space: pre;
  }
  & .MuiTypography-h3.titles-without-margin {
    margin-left: 0;
    margin-bottom: 0;
  }
  & .error-hint {
    display: flex;
    margin-bottom: 16px;
  }
`;

export const StepHeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & .add-agenda-title-name {
    display: flex;
    width: calc(100% - 130px);
    span {
      white-space: normal;
    }
  }
`;

export const LocationInputFieldWrapper = styled(InputFieldWrapper)`
  width: 100%;
  max-height: 50vh;
  height: auto;
  box-sizing: border-box;
  margin-bottom: 0;
  @media (max-height: 770px), screen and (orientation: portrait) {
    max-height: 42vh;
  }
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
  & .MuiInputBase-root:first-child {
    margin-top: 16px;
  }
  & .error-location .MuiInputBase-root {
    border: 1px solid ${COLORS.error};
  }
`;

export const StepHeaderWrapper = styled.div`
  display: flex;
  margin-top: 60px;
  margin-bottom: 64px;
  align-items: center;
  justify-content: space-between;
  & .MuiIconButton-root {
    visibility: ${(props) => props.hiddenBackButton && 'hidden'};
  }
  @media (max-height: 960px) {
    margin-bottom: 100px;
  }
  & .MuiTypography-body2 {
    margin-left: 57px;
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 78%;
  & .MuiTypography-h2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  & .MuiTypography-body2 {
    margin-bottom: 4px;
  }
  @media (max-width: 1280px) {
    max-width: 72%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  & .MuiIconButton-root {
    font-size: 24px;
    padding: 0;
  }
  & .MuiTypography-h2 {
    margin-left: 30px;
  }
`;

export const AssemblyStatus = styled.div`
  display: flex;
  margin-right: 10px;
  & .MuiTypography-body1 {
    line-height: 40px;
    color: ${(props) => (props.isDone ? COLORS.darkGreen : COLORS.orange)};
    letter-spacing: 1px;
    font-weight: bold;
    margin-right: 8px;
  }
`;

export const FormFieldsWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  & > .MuiDivider-root {
    width: 12px;
    height: 2px;
    background: ${COLORS.gray};
    margin: auto 16px;
  }
  & > .MuiDivider-vertical {
    width: 2px;
    height: 40px;
    background: ${COLORS.gray};
    transform: rotate(12deg);
    margin: auto 16px;
  }
  & .day-field,
  .month-field,
  .year-field,
  .startTime-field,
  .endTime-field {
    & .MuiInputBase-root {
      letter-spacing: 6px;
    }
  }
  & .day-field,
  .month-field {
    width: ${NEW_ASSEMBLY_TEXT_FIELD_WIDTH.day};
  }
  & .year-field {
    width: ${NEW_ASSEMBLY_TEXT_FIELD_WIDTH.year};
  }
  & .startTime-field,
  .endTime-field {
    width: ${NEW_ASSEMBLY_TEXT_FIELD_WIDTH.time};
  }
  & .endTime-field {
    margin-right: 32px;
  }
  & .timezone-field {
    width: ${NEW_ASSEMBLY_TEXT_FIELD_WIDTH.timeZone};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: #000000;
  }
`;

export const LocationsListActionsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  box-sizing: border-box;
  & .MuiButton-textSecondary.add-more-location {
    line-height: 24px;
    padding: 6px 0;
  }
`;

export const ParticipantsEmailsInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: auto;
  min-height: 168px;
  max-height: 50vh;
  @media (max-height: 1200px) {
    max-height: 40vh;
  }
  @media (max-height: 770px) {
    max-height: 30vh;
  }
  @media (max-height: 600px) {
    max-height: 24vh;
  }
  overflow: auto;
  padding: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.borderLightGray};
  box-sizing: border-box;
  border-radius: 4px;
  & img {
    margin-right: 10px;
  }
  & .MuiIconButton-root {
    margin-left: 5px;
  }
  & .emails-input {
    display: flex;
    width: inherit;
    min-width: 300px;
    height: 32px;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: none;
    color: #000000;
    -webkit-appearance: none;
    &::placeholder {
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: ${COLORS.textLightGray};
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
`;

export const EmailChip = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 32px;
  align-items: center;
  padding: 4px 8px;
  margin: 0 8px 8px 0;
  background: ${(props) =>
    props.invalid ? 'rgba(255, 255, 255, 0.3)' : 'rgba(187, 216, 235, 0.3)'};
  border-radius: 4px;
  color: #000000;
  font-weight: 600;
  ${(props) => props.invalid && `border: 1px dashed ${COLORS.error}`}
`;

export const SetupAgendaBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  & .switcher-group {
    display: flex;
    flex-direction: column;
    min-height: 60px;
    justify-content: space-between;
  }
  & .error-hint {
    margin: 10px 0 10px 16px;
  }
  & .add-agenda-title-name.MuiTypography-h3 {
    position: sticky;
    top: 0;
    z-index: 1000;
    box-sizing: border-box;
    margin-left: 0;
    background-color: inherit;
    padding-left: 16px;
  }
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    width: 1px;
    margin: 0 24px;
    background-color: ${COLORS.textLightGray};
  }
`;

export const FinishAgendaSetupActions = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 64px;
  & a {
    text-decoration: none;
  }
  & .finish-session-buttons {
    width: 290px;
    height: 48px;
  }
  & .add-new-agenda-item-buttons-group {
    width: 290px;
  }
  & .preview-page-button {
    width: auto;
    min-width: 272px;
    background-color: #ffffff;
    margin-left: 16px;
    &:hover {
      background-color: #ffffff;
    }
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: 45px;
  & .MuiButton-outlined {
    width: 36px;
    height: 32px;
    box-sizing: border-box;
    padding: 0;
    background-color: #ffffff;
    border-color: ${COLORS.borderLightGray};
    & svg {
      width: 18px;
    }
  }
  & .MuiButton-outlined.Mui-disabled {
    border-color: ${COLORS.borderLightGray};
  }
  & .MuiButton-startIcon {
    margin: 0;
  }
  & .arrow-up svg {
    transform: rotate(-90deg);
  }
  & .arrow-down svg {
    transform: rotate(90deg);
  }
`;
