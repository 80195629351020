import Typography from '@material-ui/core/Typography';
import React from 'react';
import { EndVotingMessageWrapper } from '../styledComponents';

export const EndVotingMessage = ({ title, message }) => (
  <EndVotingMessageWrapper>
    <Typography variant="h4">{title}</Typography>
    <Typography variant="body1">{message}</Typography>
  </EndVotingMessageWrapper>
);
