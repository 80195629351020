import Dialog from '@material-ui/core/Dialog/Dialog';
import styled from 'styled-components';
import {
  COLORS,
  MOBILE_PARTICIPANT_INFO_BOTTOM_LINE_HEIGHT,
  MOBILE_PARTICIPANT_VOTING_TIMER_BALLOT_TIME_LINE
} from 'constants/common';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const ParticipantViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColour || COLORS.darkGreen};
  @media (max-width: 820px) {
    height: calc(100% - 60px);
    background-color: ${(props) => props.backgroundColour || '#ffffff'};
  }
`;

export const ParticipantViewRightsInfoLineWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  justify-content: center;
  padding: 8px 0;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    padding: 8px 160px;
  }
  @media (max-width: 820px) {
    border-bottom: 1px solid ${COLORS.hintsLightGray};
    border-top: ${(props) => (props.bottomLine
  ? `1px solid ${COLORS.hintsLightGray}`
  : 'none')};
    padding: ${(props) => (props.bottomLine
  ? '8px 0 30px 0'
  : '6px 0')};
    height: ${(props) => (props.bottomLine
  ? '60px'
  : '32px')};
  }
`;

export const RightsInfoBottomLine = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  justify-content: center;
  height: ${MOBILE_PARTICIPANT_INFO_BOTTOM_LINE_HEIGHT}px;
  padding: 8px 0;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    padding: 8px 160px;
  }
  @media (max-width: 820px) {
    display: flex;
    border-top: 1px solid ${COLORS.hintsLightGray};
    padding: 8px 0 30px 0;
  }
`;

export const RightsIcons = styled.div`
  display: flex;
  width: 100%;
  max-width: 1024px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;

  @media (max-width: 820px) {
    justify-content: center;
  }
`;

export const BlockWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & img {
    height: 20px;
    margin-right: 8px;
  }
  & .MuiTypography-body2 {
    color: #000000;
  }
  @media (max-width: 820px) {
    &.ssl-encryption-label {
      display: none;
    }
  }
`;

export const ParticipantViewHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 0;
  z-index: 1;
  background-color: ${(props) => props.backgroundColour || COLORS.darkGreen};
  &.mobile-size-header {
    display: none;
  }
  & .collapse-icon-button {
    display: none;
  }
  @media (max-width: 1024px) {
    padding: 12px 160px;
  }
  @media (max-width: 820px) {
    &.full-size-header {
      display: none;
    }
    &.mobile-size-header {
      display: flex;
      height: auto;
      margin-bottom: 24px;
    }
    align-items: normal;
    padding: 12px 0;
  }
`;

export const CompanyInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  z-index: 100;
  width: 100%;
  height: auto;
  min-height: 30px;
  max-width: 1024px;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: hidden;
  & .MuiTypography-h2,
  .MuiTypography-body2 {
    color: ${(props) => props.textColour};
  }
  & .MuiDivider-root {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: ${(props) => props.textColour};
  }
  & .MuiIconButton-root {
    height: 56px;
  }
  & .MuiSvgIcon-root {
    fill: ${(props) => props.textColour};
    width: 20px;
    height: 20px;
  }
  @media (max-width: 820px) {
    height: 100%;
    align-items: start;
    padding: 0 24px 0 28px;
    & .collapse-icon-button {
      display: flex;
      transform: ${(props) => (props.openCompanyInfo
  ? 'rotate(90deg) '
  : 'rotate(0) ')};
      transition: transform 0.15s ease-in-out;
    }
  }
`;

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 820px) {
    flex-direction: column;
    align-items: start;
    margin: auto 0;
    & .MuiTypography-h2.company-name-item {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }
`;

export const MainInformationBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const LogoComponent = styled.div`
  display: flex;
  margin-right: 40px;
  width: 120px;
  height: 56px;
  background-image: ${(props) => `url('${props.url}')`};
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const CompanyInformation = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 820px) {
    justify-content: center;
    padding: 12px 0;
  }
`;

export const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ParticipantViewContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  min-width: 640px;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  padding: 64px 120px 0 120px;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (max-width: 992px) {
    max-width: none;
    min-width: 375px;
    border-radius: 0;
    padding: 5%;
  }
  @media (max-width: 820px) {
    height: 100%;
    overflow: auto;
    padding: 0;
    & > .MuiTypography-h2 {
      font-size: 24px;
      line-height: 32px;
      margin-top: 16px;
    }
  }
`;

export const AgendaTitleWrapper = styled.div`
  padding-left: 64px;
  & > .MuiTypography-body2 {
    color: #000000;
  }
  & > .MuiTypography-h2 {
    line-height: 48px;
  }
  @media (max-width: 820px) {
    padding: 0 24px 0 28px;
    & > .MuiTypography-h2 {
      font-size: 24px;
      line-height: 32px;
      margin-top: 16px;
    }
  }
`;

export const EndVotingMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 24px 12px 32px 12px;
  box-sizing: border-box;
  background-color: ${COLORS.lightYellow};
  border-bottom: 1px solid ${COLORS.borderLightYellow};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  & .MuiTypography-body1 {
    color: #000000;
  }
  @media (max-width: 820px) {
    position: fixed;
    & .MuiTypography-body1 {
      text-align: center;
    }
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  & .MuiTypography-body2 {
    color: #000000;
    font-weight: normal;
  }
  @media (max-width: 820px) {
    padding: 24px;
  }
`;

export const ParticipantViewContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 24px;
  overflow: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (max-width: 820px) {
    flex: 1;
    height: auto;
    overflow: visible;
    padding: 0 12px;
  }
`;

export const ParticipantBallotViewContent = styled(ParticipantViewContent)`
  margin-top: 40px;
  padding: 0 5px;
  box-sizing: border-box;
  & .ballot-type-label {
    width: fit-content;
    flex: 0 0 auto;
    background-color: ${(props) => (props.isAnonymousVote
  ? COLORS.darkBlue
  : COLORS.orange)};
    & .MuiTypography-body2 {
      letter-spacing: normal;
    }
  }
  & .MuiTypography-h2 {
    line-height: 48px;
  }
  @media (max-width: 820px) {
    margin-top: 24px;
    padding: 0 24px;
    & .MuiFormGroup-root {
      flex: 0 0 auto;
      padding-bottom: ${MOBILE_PARTICIPANT_INFO_BOTTOM_LINE_HEIGHT
+ MOBILE_PARTICIPANT_VOTING_TIMER_BALLOT_TIME_LINE}px;
    }
    & > .MuiTypography-h2 {
      font-size: 24px;
      line-height: 32px;
      margin-top: 8px;
      font-weight: 700;
    }
  }
`;

export const StyledVoteControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    min-height: 66px;
    box-shadow: ${(props) => (props.isActive
  ? `0px 0px 4px 2px rgba(49, 97, 169, 0.2)`
  : 'none')};
    background: ${(props) => (props.isActive
  ? COLORS.lightBlue
  : '#FFFFFF')};
    margin-right: 0;
    margin-left: 0;
    border: 1px solid ${COLORS.blue};
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 16px;
    &.MuiFormControlLabel-root .MuiTypography-body1 {
      font-weight: ${(props) => (props.isActive
  ? 600
  : 400)};
      padding: 10px 0;
    }
    & .MuiIconButton-colorSecondary:hover {
      background-color: transparent;
    }
    & .icon {
      width: 32px;
      height: 32px;
      box-shadow: inset 0 0 0 1px ${COLORS.blue}, inset 0 -1px 0 rgba(16, 22, 26, 0.1);
      background: transparent;
    }
    .radio-btn {
      border-radius: 50%;
    }
    .checkbox-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #ffffff;
    }
    & .checkedIcon {
      display: flex;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      box-shadow: inset 0 0 0 2px ${COLORS.blue}, inset 0 -1px 0 rgba(16, 22, 26, 0.1);
      background: #ffffff;
    }

    & .checkedIcon::before {
      display: block;
      margin: auto;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: ${COLORS.blue};
      content: '';
    }
    & .MuiRadio-colorSecondary.Mui-checked {
      color: ${COLORS.blue};
    }
    & .MuiRadio-root,
    .MuiCheckbox-root {
      outline: 1px;
      padding: 16px;
      color: ${COLORS.blue};
    }
    & .MuiSvgIcon-root {
      width: 32px;
      height: 32px;
    }
    & .MuiTypography-body1 {
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }
    &.MuiFormControlLabel-root.Mui-disabled {
      cursor: default;
      & .MuiTypography-body1 {
        color: ${COLORS.textLightGray};
      }
      border: 1px solid ${COLORS.borderLightGray};
      & .MuiRadio-root,
      .MuiCheckbox-root {
        color: ${COLORS.borderLightGray};
      }
      & .icon {
        box-shadow: inset 0 0 0 1px ${COLORS.borderLightGray}, inset 0 -1px 0 rgba(16, 22, 26, 0.1);
      }
    }
  }
`;

export const CastHintWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin: 24px 0 16px 0;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${(props) =>
  props.isAnonymousVote
    ? COLORS.lightGray
    : 'rgba(255, 224, 170, 0.7)'};
  border: 1px solid
    ${(props) => (props.isAnonymousVote
  ? COLORS.borderLightGray
  : 'rgba(234, 115, 23, 0.5)')};
  & .MuiTypography-body1 {
    letter-spacing: normal;
  }
  & .MuiTypography-body2 {
    letter-spacing: normal;
  }
`;

export const ParticipantBallotViewFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  max-width: 1024px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -1px;
  background: ${COLORS.lightGray};
  border-radius: 4px 4px 0 0;
  border-top: 1px solid #e0e2e4;
  box-sizing: border-box;
  height: ${MOBILE_PARTICIPANT_VOTING_TIMER_BALLOT_TIME_LINE}px;
  & .second-buttons-group-button {
    cursor: default;
    width: 91px;
  }
  & .MuiButton-contained.Mui-disabled {
    background-color: ${COLORS.lightGray};
    border: 1px solid #e0e2e4;
    color: ${COLORS.textLightGray};
  }
  @media (max-width: 820px) {
    bottom: 60px;
  }
`;

export const CastButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & .MuiTypography-body1 {
    font-weight: bold;
    letter-spacing: normal;
  }
  & .MuiButtonGroup-contained {
    box-shadow: none;
  }
  & .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
  .MuiButtonBase-root {
    &:hover {
      box-shadow: none;
    }
  }
  & .second-buttons-group-button.MuiButtonBase-root {
    padding: 12px;
  }
  @media (max-width: 820px) {
    justify-content: start;
    padding: 0 24px;
    width: 100%;
    & .MuiButtonGroup-contained {
      width: 100%;
    }
    & .timer-button {
      width: 30%;
    }
    & .submitButton {
      width: 100%;
    }
  }
`;

export const ParticipantsEndSessionViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  & .MuiTypography-h2 {
    margin-bottom: 24px;
  }
  & .MuiTypography-body1 {
    color: #000000;
    font-size: 20px;
    margin-bottom: 64px;
  }
  & .MuiButton-containedSecondary {
    margin-bottom: 24px;
  }
  @media (max-width: 820px) {
    width: 100%;
    padding: 0 24px;
    & > .MuiTypography-h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const AnonymousVotingWrapper = styled.div`
  display: flex;
  width: max-content;
  height: 100%;
  box-sizing: border-box;
  margin: 80px auto 0 auto;
  align-items: center;
  background-color: #ffffff;
  flex-direction: column;
  & .MuiTypography-h2 {
    line-height: 48px;
    & strong {
      font-weight: 600;
    }
  }
  & .MuiTypography-h3 {
    margin-top: 24px;
    color: #000000;
    line-height: 32px;
  }
  & .timer-counter {
    margin: 24px 0 64px 0;
  }
  & .timer-counter,
  .timer-counter p {
    font-size: 32px;
    color: #000000;
    line-height: 32px;
    letter-spacing: 6px;
  }
  @media (max-width: 820px) {
    width: 100%;
    padding: 0 24px;
    & .MuiTypography-h2 {
      font-size: 24px;
      line-height: 32px;
    }
    & .MuiTypography-h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const AwaitingRestartBallotWrapper = styled(AnonymousVotingWrapper)`
  & .MuiTypography-body1 {
    max-width: 500px;
    text-align: center;
    color: #000000;
    line-height: 24px;
    margin: 24px 0 0 0;
  }
  & .MuiTypography-h3 {
    margin-top: 48px;
  }
  & strong {
    font-weight: 600;
  }
`;

export const MessageDialogPopup = styled(Dialog)`
  & .MuiDialog-paper {
    width: 912px;
    padding: 96px 200px 120px 200px;
    justify-content: center;
    align-items: center;
    @media (max-width: 1280px) {
      width: 100%;
      padding: 14%;
    }
    @media (max-width: 1024px) {
      width: 100%;
      padding: 10%;
    }
  }
  & .MuiDialogTitle-root {
    margin: 0 auto;
  }
  & .MuiDialogContent-root {
    justify-content: center;
    padding: 0;
    margin-top: 40px;
    & .MuiTypography-h3 {
      text-align: center;
      line-height: 32px;
      color: #000000;
    }
  }
`;

export const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
