import api from '../api';

export const getAssembliesCollection = (data) =>
  api.get('/api/Assembly/MyList', data).then((res) => res.data);
export const updateAssembly = (data) =>
  api.post('/api/Assembly/Update', data).then((res) => res.data);
export const duplicateAssembly = (data) =>
  api.post('/api/Tools/DuplicateAssemblyWithDependencies', data).then((res) => res.data);
export const addAgendaItem = (data) =>
  api.post('/api/AgendaItem/Add', data).then((res) => res.data);
export const updateAgendaItem = (data) =>
  api.post('/api/AgendaItem/Update', data).then((res) => res.data);
export const sendInvitation = (data) =>
  api.post('/api/Mail/SendAssemblyInvitation', data).then((res) => res.data);
export const resendInvitationsToAllParticipants = (data) =>
  api.post('api/Mail/ResendInvitationToAllParticipants', data).then((res) => res.data);
export const sendCustomEmail = (data) =>
  api.post('/api/Mail/SendCustomAssemblyEmail', data).then((res) => res.data);
export const sendTestAssemblyInvitation = (data) =>
  api.post('/api/Mail/SendTestAssemblyInvitation', data).then((res) => res.data);
export const reSendInvitation = (data) =>
  api.post('/api/Mail/ResendRestoredAssemblyInvitation', data).then((res) => res.data);
export const deleteParticipantFromBatch = (data) =>
  api.post('/api/Tools/DeleteParticipantFromAssembly', data).then((res) => res.data);
export const deleteAgendaItem = (data) =>
  api.post('/api/AgendaItem/Delete', data).then((res) => res.data);
export const startAssemblySession = (data) =>
  api.post('/api/Assembly/Session/Start', data).then((res) => res.data);
export const finishAssemblySession = (data) =>
  api.post('/api/Assembly/Session/Finish', data).then((res) => res.data);
export const setActiveAgendaItem = (data) =>
  api.post('/api/Assembly/Session/MoveToItem', data).then((res) => res.data);
export const sendInvitationsWithMakingBatch = (data) =>
  api.post('/api/Tools/SentInvitationsCreateBatchAsync', data).then((res) => res.data);
export const changeAgendaStatus = (data) =>
  api.post('/api/AgendaItem/ChangeDiscussionStatus', data).then((res) => res.data);
export const addBatch = (data) => api.post('/api/Batch/Add', data).then((res) => res.data);
export const getBatch = (data) => api.get('/api/Batch/Get', data).then((res) => res.data);
export const getBatchesList = (data) => api.get('/api/Batch/GetList', data).then((res) => res.data);
export const deleteBatch = (data) => api.post('/api/Batch/Delete', data).then((res) => res.data);
export const sendMeetingMinutesEmail = (data) =>
  api.post('/api/Mail/SendAssemblyMinutes', data).then((res) => res.data);
export const getVersionedAssembly = (data) => api.get('/api/Assembly/GetAssemblyVersion', data)
  .then((res) => res.data);
export const getVersionedAgendaItem = (data) => api.get('/api/AgendaItem/GetAgendaItemVersion', data)
  .then((res) => res.data);
export const getVersionedBallot = (data) => api.get('/api/Vote/GetVoteVersion', data)
  .then((res) => res.data);
