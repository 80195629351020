import Typography from '@material-ui/core/Typography';
import React from 'react';
import { StyledSnackbarAlert } from '../styledComponents';

export const SnackBarAlert = ({ openAlert, closeAlert, message, duration }) => (
  <StyledSnackbarAlert
    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    open={openAlert}
    autoHideDuration={duration || 4000}
    onClose={closeAlert}
  >
    <Typography variant="body1">{message}</Typography>
  </StyledSnackbarAlert>
);
