import {
  SET_ASSEMBLIES_COLLECTION,
  CLEAR_STORAGE,
  CLEAR_PLANNED_ASSEMBLY_STORAGE,
  SET_JOINED_PARTICIPANTS_LIST,
  SET_NEW_BATCHES_REQUEST_ERROR
} from 'constants/types';

const initialState = {
  assemblies: [],
  joinedParticipants: [],
  newBatchRequestError: null
};

const assembliesReducer = (
  state = {
    ...initialState
  },
  { type, payload }
) => {
  switch (type) {
    case SET_ASSEMBLIES_COLLECTION:
      return {
        ...state,
        assemblies: payload ? [...payload.liveAssemblies, ...payload.assemblies] : []
      };
    case SET_JOINED_PARTICIPANTS_LIST:
      return {
        ...state,
        joinedParticipants: payload
      };
    case SET_NEW_BATCHES_REQUEST_ERROR:
      return {
        ...state,
        newBatchRequestError: payload
      };
    case CLEAR_STORAGE:
    case CLEAR_PLANNED_ASSEMBLY_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default assembliesReducer;
