import React from 'react';
import { CustomButton } from 'components/Buttons/CustomButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import EnterIcon from 'img/return-icon.svg';
import DisabledEnterIcon from 'img/return-icon-disabled.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ButtonGroupWrapper } from './styledComponents';

export const FormsButtonsGroup = ({ onSubmit, name, disabled, startIcon, ...rest }) => (
  <ButtonGroupWrapper {...rest}>
    <ButtonGroup
      variant="contained"
      color="secondary"
      aria-label="split button"
      disabled={disabled}
    >
      <CustomButton
        fullWidth
        type="submit"
        className="submitButton"
        colorType="secondary"
        name={name}
        onClick={onSubmit}
        size="large"
        startIcon={<SvgIcon component={startIcon || CheckIcon} viewBox="0 0 24 24" />}
        variant="contained"
      />
      <CustomButton
        fullWidth
        tabIndex="-1"
        className="second-buttons-group-button"
        colorType="secondary"
        name={<img src={disabled ? DisabledEnterIcon : EnterIcon} alt="Enter" />}
        onClick={(e) => e.preventDefault()}
      />
    </ButtonGroup>
  </ButtonGroupWrapper>
);
