import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { MessageDialogPopup } from '../styledComponents';

export const MessagePopUp = ({ isOpen, handleClose, message }) => {
  const { t } = useTranslation();
  return (
    <MessageDialogPopup onClose={handleClose} open={isOpen} fullWidth={true} maxWidth="lg">
      <DialogTitle>
        <Typography variant="body2">{t('titles.messageForAllParticipants')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h3">{message}</Typography>
      </DialogContent>
    </MessageDialogPopup>
  );
};
