import { useDispatch, useSelector } from 'react-redux';
import { getCompanyBrandingData } from 'Redux/actions/common';
import {
  setParticipantBrandingData,
  downloadBrandingLogoByParticipant
} from 'Redux/actions/participant';
import { blobToBase64 } from 'utils/files';

export const useUpdateParticipantViewBrandingData = () => {
  const dispatch = useDispatch();
  const storedBrandingData = useSelector(
    (state) => state.participantStore.participantViewBrandingData
  );

  const updateBrandingData = async (assemblyId) => {
    dispatch(getCompanyBrandingData({ assemblyId: assemblyId })).then((brandingDataResponse) => {
      if (
        !!brandingDataResponse.brand.logoId
        && brandingDataResponse.brand.logoId !== storedBrandingData?.logoId
      ) {
        dispatch(
          downloadBrandingLogoByParticipant({
            fileId: brandingDataResponse.brand.logoId,
            assemblyId: assemblyId
          })
        ).then((fileResponse) => {
          blobToBase64(fileResponse).then((response) => {
            const brandingData = {
              ...brandingDataResponse.brand,
              logoURL: response
            };
            dispatch(setParticipantBrandingData(brandingData));
          });
        });
      } else {
        const brandingData = {
          ...storedBrandingData,
          ...brandingDataResponse.brand,
          ...(!brandingDataResponse.brand.logoId ? { logoURL: null } : {})
        };
        dispatch(setParticipantBrandingData(brandingData));
      }
    });
  };

  return { updateBrandingData };
};
